import androidx.compose.foundation.background
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.CornerSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.*
import androidx.compose.material.icons.filled.*
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.*
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.DpOffset
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.TextUnitType
import androidx.compose.ui.unit.dp
import androidx.navigation.NavHostController
import androidx.navigation.compose.rememberNavController
import ru.novasoft.roads.compose_client.core.designsystem.theme.menu
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.menuStartPadding
import ru.novasoft.roads.compose_client.core.ui.DefaultToolTip

private const val COLLAPSED_WIDTH = 48
private const val DROPDOWN_MENU_WIDTH = COLLAPSED_WIDTH * 4
private const val EXTENDED_WIDTH = COLLAPSED_WIDTH * 5
const val MENU_ITEM_HEIGHT = 40
const val MENU_SUB_ITEM_HEIGHT = 30
private val selectedSubItemIcon = Icons.Default.Circle

class SPAMenuPainterUtils {
    val iconWrapper: @Composable (description: String?, expanded: MutableState<Boolean>, modifier: Modifier, imageVector: @Composable (MutableState<Boolean>) -> ImageVector?) -> Unit =
        { description: String?, expanded: MutableState<Boolean>, modifier: Modifier, imageVector: @Composable (MutableState<Boolean>) -> ImageVector? ->
            wrapIcon(description, expanded, modifier, imageVector)
        }

    val openContractIconGenerator: @Composable (contractName: String, selectedState: MutableState<Boolean>, modifier: Modifier) -> Unit =
        { contractName: String, selectedState: MutableState<Boolean>, modifier: Modifier ->
            getOpenedContractIcon(contractName, selectedState, modifier)
        }

    val getOpenedContractRightContent: @Composable (selectedState: MutableState<Boolean>, removeContract: () -> Unit) -> Unit =
        { selectedState: MutableState<Boolean>, removeContract: () -> Unit ->
            getContractCloseContent(selectedState, removeContract)
        }
}

@Composable
fun RoadsSPA(
    rootNavController: NavHostController,
    onLogout: () -> Unit
) {
    val model = RoadsSPAViewModel(
        rootNavController,
        onLogout,
        SPAMenuPainterUtils(),
        rememberNavController(),
        rememberCoroutineScope()
    )
    RoadsSPAView(model)
}


/**
 * Вью основного одно страничного приложения
 */
@Composable
private fun RoadsSPAView(
    roadsSPAViewModel: RoadsSPAViewModel
) {
    val widthState = mutableStateOf(COLLAPSED_WIDTH.dp)

    PermanentNavigationDrawer(
        drawerContent = {
            PermanentDrawerSheet(
                modifier = Modifier.width(widthState.value),
                drawerShape = RectangleShape,
                drawerContainerColor = MaterialTheme.colorScheme.primaryContainer,
                drawerContentColor = MaterialTheme.colorScheme.onPrimaryContainer,
            ) { createMenu(roadsSPAViewModel) }

        },
        content = {
            roadsSPAViewModel.generateNavHost()
        }
    )

    LaunchedEffect(Unit) {
        roadsSPAViewModel.events.collect { event ->
            if (event !is SpaMenuAppEvent) return@collect

            when (event) {
                is SpaMenuAppEvent.UpdateMenuWidth -> {
                    widthState.value = if (event.setExtendedWidth) EXTENDED_WIDTH.dp else COLLAPSED_WIDTH.dp
                }
            }
        }
    }
}


/** Создание (составление и перерисовка) меню */
@Composable
private fun createMenu(
    roadsSPAViewModel: RoadsSPAViewModel
) {
    val expandedState: MutableState<Boolean> = roadsSPAViewModel.expandedState
    val selectedState: MutableState<MenuItem> = roadsSPAViewModel.selectedState

    //Генерируем список элементов с учетом текущего состояния свернуто/развернуто
    // (если развернуто - надо добавить все элементы, если свернуто - только базовые, без дочерних)
    MenuItemsList(
        roadsSPAViewModel.topMenuContent,
        roadsSPAViewModel.botItems,
        expandedState
    ) { item, level ->
        val modifierIcon = if (level == 0) Modifier else Modifier.offset(25.dp)
        val modifierLabel = if (level == 0) Modifier else Modifier.offset(15.dp)
        //Генерация вида элемента на основе данного MenuItem и его уровня в иерархии
        // (0 для базовых, потом +1 для каждого следующего уровня в иерархии)
        CustomNavigationDrawerItem(
            roadsSPAViewModel = roadsSPAViewModel,
            icon = {
                DefaultToolTip(text = item.label, condition = item.label.isNotBlank()) {
                    val iconSize = if (level == 0) 20 else 7
                    val iconModifier = Modifier.size(iconSize.dp)
                    //Если верхний уровень иерархии, рисуем соответствующую MenuItem картинку,
                    // иначе - картинку в зависимости от статуса выбора
                    // (по сути, точка для выбранного (или если его дети выбраны) и пусто для не выбранного)

                    //Размер коробки с картинкой фиксированный, самой картинки - меняется в зависимости от уровня
                    Box(modifier = modifierIcon.size(20f.dp), contentAlignment = Alignment.Center) {
                        if (level == 0) item.imageContent.invoke(expandedState, iconModifier)
                        else {
                            (wrapIcon(
                                description = item.label,
                                expanded = expandedState,
                                modifier = iconModifier
                            ) {
                                getImageVectorForChild(
                                    expandedState,
                                    roadsSPAViewModel.isAnyChildSelected(item)
                                )
                            })
                        }
                    }
                }
            },
            label = if (expandedState.value) {
                {
                    //Текст получаем нужного стиля в зависимости от уровня и выбора
                    // (выделенный для основных разделов и выбранных (или если хотя бы ребенок выбран))
                    getMenuTextItem(
                        item.label,
                        level == 0 || roadsSPAViewModel.isAnyChildSelected(item),
                        modifier = modifierLabel
                    )
                }
            } else null,
            rightContent = item.rightContent,
            expandedMenuStatus = expandedState.value,
            selected = item == selectedState.value,
            onClick = {
                roadsSPAViewModel.onEvent(
                    SpaMenuUiEvent.MainMenuItemClicked(item)
                )
            },
            colors = NavigationDrawerItemDefaults.colors(
                selectedContainerColor = MaterialTheme.colorScheme.secondaryContainer,
                unselectedContainerColor = Color.Transparent,
                selectedIconColor = getColor(true),
                unselectedIconColor = getColor(false),
                selectedTextColor = getColor(true),
                unselectedTextColor = getColor(false),
            ),
            currentItem = item,
            addDivider = roadsSPAViewModel.isFirstContract(item),
            height = if (level == 0) MENU_ITEM_HEIGHT else MENU_SUB_ITEM_HEIGHT
        )
    }
}


/** Отрисовка элемента меню */
@Composable
fun CustomNavigationDrawerItem(
    roadsSPAViewModel: RoadsSPAViewModel,
    label: (@Composable () -> Unit)? = null,
    rightContent: (@Composable (MutableState<Boolean>) -> Unit)? = null,
    expandedMenuStatus: Boolean,
    selected: Boolean,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    icon: (@Composable () -> Unit),
    shape: Shape = RoundedCornerShape(CornerSize(10.dp)),
    colors: NavigationDrawerItemColors = NavigationDrawerItemDefaults.colors(),
    interactionSource: MutableInteractionSource = remember { MutableInteractionSource() },
    currentItem: MenuItem,
    addDivider: Boolean,
    height: Int
) {
    var expandedDropdownMenuStatus by remember { mutableStateOf(false) }

    /** Надо ли при отрисовке считать элемент выбранным:
     * - что он сам выбран, или при свернутом меню выбран какой-либо дочерний элемент его */
    val selectedStateForCollapsedMenu =
        selected || !expandedMenuStatus && roadsSPAViewModel.isAnyChildSelected(currentItem)

    Surface(
        selected = selectedStateForCollapsedMenu,
        onClick = {
            //При нажатии сменяем открытие/закрытие DropdownMenu и выполняем действие после выбора элемента
            expandedDropdownMenuStatus = !expandedDropdownMenuStatus
            onClick.invoke()
        },
        modifier = modifier
            .semantics { role = Role.Tab }
            .height(height.dp)
            .fillMaxWidth(),
        shape = shape,
        color = colors.containerColor(selectedStateForCollapsedMenu).value,
        interactionSource = interactionSource,
    ) {
        if (addDivider)
            HorizontalDivider()

        Row(
            Modifier.padding(horizontal = menuStartPadding),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            val iconColor = colors.iconColor(selectedStateForCollapsedMenu).value
            CompositionLocalProvider(LocalContentColor provides iconColor, content = icon)

            //Если меню развернуто - добавляем текст
            if (expandedMenuStatus) {
                if (label != null) Spacer(Modifier.width(12.dp))
                Box(Modifier.weight(1f)) {
                    val labelColor = colors.textColor(selectedStateForCollapsedMenu).value
                    CompositionLocalProvider(LocalContentColor provides labelColor, content = label ?: {})
                }

                if (rightContent != null)
                    rightContent(mutableStateOf(roadsSPAViewModel.isAnyChildSelected(currentItem)))

            } else //Если меню свернуто: добавляем показ DropdownMenu при нажатии, если есть дочерние элементы
                if (currentItem.children.isNotEmpty())
                    DropdownMenu(
                        expanded = expandedDropdownMenuStatus,
                        onDismissRequest = { expandedDropdownMenuStatus = false },
                        modifier = Modifier.width(DROPDOWN_MENU_WIDTH.dp),
                        //Сдвигаем, чтоб было справа сбоку (а не снизу)
                        offset = DpOffset(COLLAPSED_WIDTH.dp, -MENU_ITEM_HEIGHT.dp),
                        shape = RoundedCornerShape(CornerSize(10.dp)),
                        containerColor = MaterialTheme.colorScheme.primaryContainer,
                        shadowElevation = 5.dp,
                        tonalElevation = 0.dp,
                    ) {
                        val needToAddParent = currentItem.reopenOnClickIfChildSelected //Примечание: не используется
                        //Если базовый элемент можно выбирать отдельно и при наличии детей
                        // - добавим его в список элементов для отображения в DropdownMenu
                        val itemsForDropdownMenu =
                            if (needToAddParent)
                                mutableListOf(currentItem) + currentItem.children
                            else currentItem.children

                        //Для каждого дочернего элемента создаем элемент выбора для DropdownMenu
                        itemsForDropdownMenu.forEach { child ->
                            val childIsSelected = child != currentItem && roadsSPAViewModel.isAnyChildSelected(child)
                            val startOffset = if (!needToAddParent) -20 else -10
                            val endOffset = 10
                            val standardIconSize = 15
                            val itemRounding = 8.5

                            DropdownMenuItem(
                                colors = MenuItemColors(
                                    textColor = colors.textColor(childIsSelected).value,
                                    leadingIconColor = colors.iconColor(childIsSelected).value,
                                    trailingIconColor = colors.iconColor(childIsSelected).value,
                                    disabledTextColor = colors.textColor(false).value,
                                    disabledLeadingIconColor = colors.iconColor(false).value,
                                    disabledTrailingIconColor = colors.iconColor(false).value
                                ),
                                text = {
                                    getMenuTextItem(
                                        child.label,
                                        childIsSelected,
                                        Modifier
                                            .offset(x = startOffset.dp)
                                    )
                                },
                                //Картинка справа (стрелочка)
                                trailingIcon = {
                                    Icon(
                                        if (child == currentItem) Icons.Default.KeyboardArrowDown
                                        else Icons.AutoMirrored.Default.ArrowForward,
                                        null,
                                        modifier = Modifier.size(standardIconSize.dp).offset(x = endOffset.dp)
                                    )
                                },
                                //Картинка слева - точка выбора
                                leadingIcon = {
                                    val iconSize = if (child == currentItem) standardIconSize else 7
                                    //Если нет в списке родительского элемента, то ставим ширину маленькую,
                                    // иначе - у всех элементов широкая коробка с центрированием картинки в ней
                                    val boxSize = if (!needToAddParent) iconSize else standardIconSize
                                    val offset = if (needToAddParent) ((standardIconSize - iconSize) / 2) else 0

                                    Box(modifier = Modifier.width(boxSize.dp)) {
                                        val iconModifier = Modifier
                                            .size((iconSize + offset).dp)
                                            .padding(start = offset.dp)

                                        if (roadsSPAViewModel.isAnyChildSelected(child)) {
                                            if (child == currentItem)
                                                child.imageContent(mutableStateOf(true), iconModifier)
                                            else wrapIcon(
                                                null,
                                                mutableStateOf(expandedMenuStatus),
                                                iconModifier
                                            ) { selectedSubItemIcon }
                                        }
                                    }
                                },
                                onClick = {
                                    //При выборе: выбираем этот элемент, обновляем структуру, выполняем действие выбранного элемента
                                    expandedDropdownMenuStatus = false

                                    roadsSPAViewModel.onEvent(
                                        SpaMenuUiEvent.DropdownMenuItemClicked(currentItem, child)
                                    )
                                },
                                modifier = Modifier
                                    .height(30.dp)
                                    .clip(RoundedCornerShape(itemRounding.dp)),
                            )

                            //Добавление разделителя после базового элемента (если есть)
                            if (child == currentItem) HorizontalDivider(
                                thickness = (0.2f).dp,
                                color = colors.textColor(false).value
                            )
                        }
                    }
        }
    }
}

@Composable
private fun getColor(selected: Boolean) =
    if (selected) MaterialTheme.colorScheme.surfaceContainerLowest
    else MaterialTheme.colorScheme.surfaceContainerHighest.copy(alpha = 0.85f)


/** Генерация элемента текста на основе переданной строки и состояния выделения (надо ли выделять) */
@Composable
private fun getMenuTextItem(text: String, selected: Boolean, modifier: Modifier = Modifier) {
    val textStyle =
        MaterialTheme.typography.menu.copy(
            color = if (selected) getColor(true) else getColor(false)
        )
    Text(text, modifier = modifier, style = textStyle, maxLines = 1, overflow = TextOverflow.Ellipsis)
}


/** Картинка для дочернего элемента меню (по сути кружок выбора или пустая) */
@Composable
private fun getImageVectorForChild(expanded: MutableState<Boolean>, selected: Boolean): ImageVector? =
    if (selected) selectedSubItemIcon
    else null

@Composable
private fun wrapIcon(
    description: String?,
    expanded: MutableState<Boolean>,
    modifier: Modifier,
    imageVector: @Composable (MutableState<Boolean>) -> ImageVector?
) {
    val iv = imageVector(expanded)
    if (iv != null)
        Icon(
            iv,
            description,
            modifier = modifier
        )
}

@Composable
private fun getOpenedContractIcon(contractName: String, selectedState: MutableState<Boolean>, modifier: Modifier) {
    val letter = (if (contractName.isNotEmpty()) contractName[0] else 'C').toString()

    val textColor = MaterialTheme.colorScheme.primaryContainer
    val backgroundColor = getColor(selectedState.value)
    val shape: Shape = if (selectedState.value) CircleShape else RoundedCornerShape(CornerSize(3.dp))

    Text(
        text = letter,
        color = textColor,
        fontSize = TextUnit(15f, TextUnitType.Sp),
        textAlign = TextAlign.Center,
        fontWeight = FontWeight.ExtraLight,
        modifier = modifier.background(backgroundColor, shape).offset(y = (-5).dp)
    )
}


@Composable
private fun getContractCloseContent(
    selectedState: MutableState<Boolean>,
    removeContract: () -> Unit
) {
    Box(modifier = Modifier.offset(15.dp)) {
        DefaultToolTip(
            "Закрыть контракт"
        ) {
            IconButton({ removeContract() }) {
                Icon(
                    Icons.Default.Close,
                    "Закрыть контракт",
                    modifier = Modifier.size(10.dp),
                    tint = getColor(selectedState.value)
                )
            }
        }
    }
}