package ru.novasoft.roads.compose_client.core.ui.table.mappers

import androidx.compose.foundation.layout.BoxScope
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

interface TableColumn<T> {
    /**
     * Функция отрисовки заголовка таблицы
     * @return Composable функция заголовка таблицы
     */
    fun getHeaderComposable(): @Composable () -> Unit =
        { Text(this.getColumnName(), color = MaterialTheme.colorScheme.onPrimaryContainer) }

    /**
     * Функция отрисовки ячейки таблицы
     * @return Composable функция ячейки таблицы
     */
    fun getCellComposable(): @Composable BoxScope.(dto: T) -> Unit

    /**
     * Получение имени столбца
     * @return Имя столбца
     */
    fun getColumnName(): String

    /**
     * Получение имени группы, к которой принадлежит столбец.
     * Если оно не указано - столбец не состоит ни в одной группе
     * @return Имя группы столбца
     */
    fun getGroupName(): String? = null

    /**
     * Получение содержания столбца для создания автоматических фильтров
     * @return Содержание столбца
     */
    fun getColumnContent(): ((T) -> Any?)? = null

    /**
     * Получение типа фильтра для генерации фильтров.
     * По умолчанию тип фильтра равен [FilterType.NONE],
     * то есть фильтр не генерируется
     * @return Тип сгенерированного фильтра
     */
    fun getFilterType(): FilterType = FilterType.NONE

    /**
     * Получение ширины столбца
     * @return Ширина столбца
     */
    fun getWidth(): Dp = 200.dp
}

/**
 * Тип фильтра у столбца, нужный для их генерации
 * @property NONE Без типа. Генерации для столбца не будет
 * @property TEXT_FIELD Текстовое поле
 * @property CHECKBOX Кнопка, имеющая два состояния соответственно
 */
enum class FilterType {
    NONE,
    TEXT_FIELD,
    CHECKBOX
}