package ru.novasoft.roads.compose_client.core.ui.table

import androidx.compose.foundation.HorizontalScrollbar
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.rememberScrollbarAdapter
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.KeyboardArrowRight
import androidx.compose.material.icons.filled.KeyboardArrowDown
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.times
import novasoft.roads.util.FilterFactory
import ru.novasoft.roads.compose_client.core.ui.table.components.BarUnderTheTable
import ru.novasoft.roads.compose_client.core.ui.table.components.HeaderWithSubheaders
import ru.novasoft.roads.compose_client.core.ui.table.components.TopMenu
import ru.novasoft.roads.compose_client.core.ui.table.mappers.Filter
import ru.novasoft.roads.compose_client.core.ui.table.mappers.TableColumn
import ru.novasoft.roads.compose_client.core.ui.table.utils.*

/**
 * Представление рядов с данными(включает и подряды)
 */
data class TreeNode<T>(
    val content: T,
    val children: List<TreeNode<T>> = listOf()
)

/**
 * Тип фильтрации:
 * @property ALL Фильтр проходится по ВСЕМ элементам таблицы, в том числе и по детям.
 * @property ROOTS Фильтр проходится ТОЛЬКО по верхушкам.
 */

enum class TreeFilteringType {
    ALL,
    ROOTS
}

/**
 * Composable реализация древовидной таблицы
 * @param tableColumns Список всех столбцов и параметров для их отрисовки
 * @param rows Данные, отображаемые в таблице.
 * @param modifier Модификатор для таблицы
 * @param paginationProperties Опциональный аргумент для включения возможности пагинации таблицы.
 * @param filters Фильтры для элементов таблицы. Подробнее про фильтры тут: [Filter]
 * @param treeFilteringType Тип фильтрации древовидной таблицы
 * @param placeholder Composable функция, отображаемая в случае отсутствия данных
 */

@Composable
fun <T> TreeTableFactory(
    tableColumns: List<TableColumn<T>>,
    rows: MutableList<TreeNode<T>>,
    modifier: Modifier = Modifier,
    paginationProperties: PaginationProperties = PaginationProperties(),
    filters: List<Filter<T>> = listOf(),
    treeFilteringType: TreeFilteringType = TreeFilteringType.ALL,
    placeholder: @Composable () -> Unit = {
        Box(modifier = modifier.fillMaxSize()) {
            Text("Нет данных", modifier = Modifier.align(Alignment.Center))
        }
    }
) {
    val borderColor = MaterialTheme.colorScheme.background
    val builtFilters = buildFilters(tableColumns)
    val filterFunctions = remember { filters.map { it.getFilter() } }
    val combinedFilters = filters + builtFilters
    val scrollState = rememberLazyListState()

    val state = remember {
        mutableStateOf(
            TableState(
                labelVisibility = List(tableColumns.size) { true }, // По умолчанию все заголовки таблицы видны
                filterVisibility = List(filters.size + builtFilters.size) { false },
                labelWidths = tableColumns.map{it.getWidth()}
            )
        )
    }

    val headerData = remember {
        mapTableLabelsToTableData(tableColumns)
    }

    val pageAmount: Int
    val currentItems: List<TreeNode<T>>
    if (paginationProperties.isEnabled) {
        pageAmount = rows.size.ceilDiv(paginationProperties.perPage)
        currentItems =
            rows.chunked(paginationProperties.perPage).getOrElse(state.value.pageNumberState - 1) { emptyList() }
    } else {
        pageAmount = -1
        currentItems = rows
    }

    val builtFilterFunctions = builtFilters.map { it.getFilter() }
    val combinedFilterFunctions = filterFunctions + builtFilterFunctions
    val visibleFilters = combinedFilterFunctions.filterIndexed { index, _ -> state.value.filterVisibility[index] }
    val filterFactory = FilterFactory(
        *visibleFilters.toTypedArray()
    )
    val filteredRows : List<TreeNode<T>> = if(treeFilteringType == TreeFilteringType.ALL) {
        filterTreeNodes(currentItems, filterFactory.build())
    } else {
        currentItems.filter { filterFactory.build()(it.content) }
    }
    var sumOfVisibleWidth : Dp = 0.dp
    var sumOfWidth : Dp = 0.dp
    for(widthIndex in state.value.labelWidths.indices) {
        val width = state.value.labelWidths[widthIndex]
        sumOfWidth += width
        if(state.value.labelVisibility[widthIndex]) {
            sumOfVisibleWidth += width
        }
    }

    Column(
        modifier = Modifier.fillMaxSize()
    ) {
        Row(modifier = Modifier.width(sumOfWidth + tableColumns.filterIndexed{index, _ -> state.value.labelVisibility[index]}.size * 2.dp)) {
            TopMenu(combinedFilters, state, tableColumns, paginationProperties)
        }
        LazyRow(
            modifier = Modifier.fillMaxWidth().weight(1f),
            state = scrollState
        ) {
            item {
                Column(modifier = modifier.width(sumOfVisibleWidth + tableColumns.filterIndexed{index, _ -> state.value.labelVisibility[index]}.size * 2.dp)) {
                    HeaderWithSubheaders(state, tableColumns, headerData)
                    if (filteredRows.isNotEmpty()) {
                        RootTableRow(filteredRows, state, tableColumns, 1f, borderColor)
                    } else {
                        placeholder()
                    }

                }
            }
        }
        BarUnderTheTable(
            modifier = Modifier.width(sumOfWidth),
            state = state,
            paginationProperties = paginationProperties,
            pageAmount = pageAmount
        )

        HorizontalScrollbar(
            adapter = rememberScrollbarAdapter(scrollState),
            modifier = Modifier.fillMaxWidth()
        )
    }
}

@Composable
private fun <T> ColumnScope.RootTableRow(
    filteredRows: List<TreeNode<T>>,
    state: MutableState<TableState>,
    tableColumns: List<TableColumn<T>>,
    weight: Float,
    borderColor: Color
) {
    LazyColumn(modifier = Modifier.fillMaxWidth().weight(weight)) {
        items(filteredRows) { item ->
            val uniqueKey = item.content.hashCode().toString()
            val isExpanded = state.value.expandedStateMap[uniqueKey] ?: false
            TreeTableNode(
                rootNode = item,
                tableColumns = tableColumns,
                state = state,
                dp = 16.dp,
                isExpanded = isExpanded,
                borderColor = borderColor,
                onExpandChange = { expanded ->
                    state.value = state.value.copy(
                        expandedStateMap = state.value.expandedStateMap.toMutableMap().apply {
                            this[uniqueKey] = expanded
                        }
                    )
                },
            )
        }
    }
}

private fun <T> generateUniqueKey(node: TreeNode<T>): String {
    return node.content.hashCode().toString()
}


@Composable
private fun <T> TreeTableNode(
    rootNode: TreeNode<T>,
    tableColumns: List<TableColumn<T>>,
    state: MutableState<TableState>,
    dp: Dp,
    isExpanded: Boolean,
    onExpandChange: (Boolean) -> Unit,
    borderColor: Color
) {

    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(IntrinsicSize.Max)
            .background(color = MaterialTheme.colorScheme.surfaceVariant)
            .clickable { onExpandChange(!isExpanded) }
    ) {
        for (columnIndex in tableColumns.indices) {
            if (!state.value.labelVisibility[columnIndex]) {
                continue
            }
            val cellComposable = remember(tableColumns, columnIndex) {
                tableColumns[columnIndex].getCellComposable()
            }
            Box(
                modifier = Modifier
                    .width(state.value.labelWidths[columnIndex])
                    .fillMaxHeight()
            ) {
                Box(modifier = Modifier) {
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        if (columnIndex == 0 && rootNode.children.isNotEmpty()) {
                            Spacer(modifier = Modifier.width(dp - 24.dp))
                            Icon(
                                imageVector = if (isExpanded) Icons.Default.KeyboardArrowDown else Icons.AutoMirrored.Filled.KeyboardArrowRight,
                                contentDescription = null
                            )
                        } else if (columnIndex == 0) {
                            var spacerSize = dp - 16.dp
                            if(spacerSize <= 0.dp){
                                spacerSize = 16.dp
                            }
                            Spacer(modifier = Modifier.width(spacerSize))
                        }
                        Box(modifier = Modifier.fillMaxSize()) {
                            cellComposable(rootNode.content)
                        }

                    }
                }
            }
            if(columnIndex != state.value.labelVisibility.lastIndexOf(true)) {
                VerticalDivider(thickness = 2.dp, color = borderColor)
            } else {
                VerticalDivider(thickness = 2.dp, color = MaterialTheme.colorScheme.surfaceVariant)
            }
        }
    }

    if (isExpanded) {
        rootNode.children.forEach { child ->
            val childUniqueKey = generateUniqueKey(child)
            val childIsExpanded = state.value.expandedStateMap[childUniqueKey] ?: false
            TreeTableNode(
                rootNode = child,
                tableColumns = tableColumns,
                state = state,
                dp = dp + 16.dp,
                isExpanded = childIsExpanded,
                borderColor = borderColor,
                onExpandChange = { expanded ->
                    state.value = state.value.copy(
                        expandedStateMap = state.value.expandedStateMap.toMutableMap().apply {
                            this[childUniqueKey] = expanded
                        }
                    )
                }
            )
        }
    }
}