package novasoft.roads.util

import novasoft.roads.dto.svor.WorkAmountDto
import novasoft.roads.util.FormatUtils.formatBitDepth

fun getJoinedAmounts(separator: String, workAmounts: List<WorkAmountDto>, coef: Float = 1f) =
    getJoinedAmountsFromValue(separator, workAmounts.map { it.workAmount }, coef)

fun getJoinedAmountsFromValue(separator: String, amounts: List<Double>, coef: Float = 1f, digits: Int = 2) =
    amounts.joinToString(separator) { wa ->
        formatBitDepth(wa * coef, digits)
    }

fun getJoinedUnits(separator: String, workAmounts: List<WorkAmountDto>) =
    workAmounts.joinToString(separator) { wa ->
        wa.unit.abbreviation
    }

fun getMainUnitAbbreviation(workAmounts: List<WorkAmountDto>, mainUnit: Int): String =
    workAmounts.find { it.unit.id == mainUnit }?.unit?.abbreviation ?: ""