package ru.novasoft.roads.compose_client.core.ui.chart

import androidx.compose.foundation.Canvas
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.size
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

@Composable
fun DiagonalColorBox(
    color1: Color,
    color2: Color,
    size: Dp = 10.dp
) {
    Box(
        modifier = Modifier.size(size)
    ) {
        Canvas(modifier = Modifier.matchParentSize()) {
            drawRect(
                brush = Brush.linearGradient(
                    colorStops = arrayOf(Pair(0.5f, color1), Pair(0.5f, color2)),
                    start = Offset(0f, 0f),
                    end = Offset(size.toPx(), size.toPx()),
                )
            )
        }
    }
}