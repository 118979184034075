import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowLeft
import androidx.compose.material.icons.automirrored.filled.ArrowRight
import androidx.compose.material.icons.automirrored.filled.Help
import androidx.compose.material.icons.automirrored.filled.Logout
import androidx.compose.material.icons.filled.*
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.navigation.NavGraphBuilder
import androidx.navigation.NavHostController
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.composable
import androidx.navigation.navArgument
import common.RoadsUiEvent
import common.RoadsViewModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import ru.novasoft.roads.compose_client.core.model.routing.Menu
import ru.novasoft.roads.compose_client.core.model.routing.Menu.*
import ru.novasoft.roads.compose_client.core.model.routing.RootRouting
import src.novasoft.roads.compose_client.feature.menu.companies.CompaniesContractsView
import src.novasoft.roads.compose_client.feature.menu.companies.CustomerAssignmentView
import src.novasoft.roads.compose_client.feature.menu.companies.SubcontractorAssignmentView
import src.novasoft.roads.compose_client.feature.menu.contacts.ContactsView
import src.novasoft.roads.compose_client.feature.menu.contract_screen.*
import src.novasoft.roads.compose_client.feature.menu.contracts.ContractsView
import src.novasoft.roads.compose_client.feature.menu.folders.GeofencesView
import src.novasoft.roads.compose_client.feature.menu.folders.ResourcesView
import src.novasoft.roads.compose_client.feature.menu.folders.SubcontractorsIdSetterView
import src.novasoft.roads.compose_client.feature.menu.help.HelpView
import src.novasoft.roads.compose_client.feature.menu.team.DepartmentsView
import src.novasoft.roads.compose_client.feature.menu.team.ModulesView
import src.novasoft.roads.compose_client.feature.menu.team.RolesView
import src.novasoft.roads.compose_client.feature.menu.team.WorkersView

private const val contractIdKey = "contractId"
private const val contractNavRoot = "contractScreen"
private const val contractTabName = "tabName"
private const val contractTabNavRoute = "${contractNavRoot}/{${contractIdKey}}/{${contractTabName}}"

class RoadsSPAViewModel(
    rootNavController: NavHostController,
    onLogout: () -> Unit,
    private val painter: SPAMenuPainterUtils,
    private val menuNavController: NavHostController,
    private val scope: CoroutineScope
) : RoadsViewModel() {
    val expandedState = mutableStateOf(false)

    /** Базовые элементы верхней части меню (сами они неизменны, меняются их дети)  */
    private val topItems =
        Menu.entries.filter { it.isBaseItem() }.map { it.createMenuItem(menuNavController) }.toMutableList()

    /** Контракты, которые уже открывались и добавлены в меню */
    private val openedContracts: MutableList<Pair<Int, MenuItem>> = mutableListOf()

    /** Выбранный пункт меню (т.е. какая страница открыта)
     *
     * Примечание: в случае свернутого меню выделенным может быть родительский элемент */
    val selectedState = mutableStateOf(topItems[1])

    /** Актуальная отображаемая структура меню (с учетом развернутости) */
    var topMenuContent: MutableList<Pair<MenuItem, Int>> by mutableStateOf(mutableListOf())
        private set

    /** Нижние элементы меню (управляющие, без обновления структуры) */
    val botItems: List<MenuItem> = listOf(
        MenuItem(
            imageContent = { expanded1: MutableState<Boolean>, modifier: Modifier ->
                painter.iconWrapper(null, expanded1, modifier) { expanded2 ->
                    if (expanded2.value)
                        Icons.AutoMirrored.Filled.ArrowLeft
                    else
                        Icons.AutoMirrored.Filled.ArrowRight
                }
            },
            rightContent = null, "", false, null
        ) {
            scope.launch {
                expandedState.value = !expandedState.value

                viewModelScope.launch {
                    eventChannel.emit(SpaMenuAppEvent.UpdateMenuWidth(expandedState.value))
                }

                //Если свернули/развернули меню, надо пересчитать отображаемые элементы
                updateShowingElementsState()
            }
        },
        MenuItem(
            imageContent = { expanded, modifier ->
                painter.iconWrapper(null, expanded, modifier) { Icons.Default.ManageAccounts }
            },
            rightContent = null,
            "Администратор",
            false,
            null
        ) { /*onManageAccount()*/ },
        MenuItem(
            imageContent = { expanded, modifier ->
                painter.iconWrapper(null, expanded, modifier) { Icons.AutoMirrored.Filled.Logout }
            },
            rightContent = null,
            "Выйти",
            false,
            null
        ) {
            onLogout()
            rootNavController.navigate(RootRouting.Auth.name)
        },
    )

    /** Обновление списка отображаемых элементов (если есть изменения) */
    private fun updateShowingElementsState() {
        val newTopItemsList = topItems.rebuildShowing(expandedState.value)
        val newContractsList = openedContracts.map { it.second }.rebuildShowing(expandedState.value)

        val newState = (newTopItemsList + newContractsList).toMutableStateList()

        if (newState != topMenuContent) topMenuContent = newState
    }


    /**
     * Обновление структуры меню на основании выбранного элемента, актуальных дочерних элементов и возможных
     * - если есть подструктура у не выбранного сейчас элемента, то удаляем эти дочерние элементы
     * - если у выбранного сейчас элемента нет дочерних элементов, но они могут быть добавлены - добавляем
     * @return изменилась ли структура
     */
    private fun updateInnerStructure(): Boolean {
        val updTop = topItems.updateChildren(selectedState.value, menuNavController)
        val updContract = openedContracts.map { it.second }.updateChildren(selectedState.value, menuNavController)
        return updTop || updContract
    }

    /**
     * По переданному пути находится элемент меню. Если у него есть дети, выбираем первый из них
     * @return получилось ли выбрать элемент
     */
    private fun selectFirstChild(navigationPath: String): Boolean {
        //Ищем по соотв. полю в menuItem родительский элемент
        //Если он нашелся, у него есть дети, и первый из них доступен к выбору -
        // выбираем, обновляем структуру, выполняем его действие и возвращаем статус успеха
        var success = false
        val menuItem = topItems.findByPath(navigationPath)?.children
            ?: openedContracts.map { it.second }.findByPath(navigationPath)?.children

        if (!menuItem.isNullOrEmpty()) {
            val targetItem = menuItem.first()
            if (targetItem.selectable) {
                selectedState.value = targetItem
                updateInnerStructure()
                targetItem.action()
                success = true
            }
        }
        return success
    }

    /**
     * Пересобираем структуру верхней (=изменяемой) части меню
     * (элементы и подэлементы, которые должны отображаться сейчас с учетом свернутости/развернутости меню).
     *
     * Примечание: Из-за этого будет вызвана перерисовка меню
     */
    private fun recollectMenuItems() {
        scope.launch {
            updateShowingElementsState()
        }
    }

    init {
        recollectMenuItems()
    }

    /** Добавление подвкладок выбранного контракта (по переданному id контракта) */
    private fun addContractTabs(contractId: Int, contractName: String) {
        //Если еще не открывали этот контракт - надо добавить его в список открытых контрактов
        var addedNew = false
        if (openedContracts.none { it.first == contractId }) {
            addedNew = true
            openedContracts.add(
                contractId to OpenedContract.createMenuItem(
                    menuNavController,
                    contractId,
                    contractName
                )
            )
        }
        val selectedContract = openedContracts.find { it.first == contractId }!!

        //Выбираем его, добавляем его детей
        selectedState.value = selectedContract.second
        updateInnerStructure()
        //Выбираем первого ребенка, подвкладку
        selectFirstChild(selectedContract.second.navigationPath!!)

        //Если надо - перерисовываем (если развернуто меню или изменился список верхнеуровневых элементов)
        if (expandedState.value || addedNew) recollectMenuItems()
    }

    /** Для элемента, при выборе которого нужно выбирать его, удалять его детей и выполнять его действие */
    private fun reselectItemWithUnnecessaryChildren(parentItem: MenuItem) {
        //Примечание: сейчас неактуально

        //В развернутом режиме меню: если нажали на родительский элемент, у которого выбран дочерний элемент,
        //но указано, что это самостоятельная страница, и надо удалять детей и грузить саму страницы
        selectedState.value = parentItem

        //Дополнительно обновлять структуру не надо, поскольку раз были выбраны его дети,
        // то другие вкладки дети не содержат, и их не надо чистить,
        // плюс новых детей этой вкладке добавлять не надо, только убирать старых
        parentItem.children.clear()
        parentItem.action()

        if (expandedState.value)
            recollectMenuItems()
    }

    /** Проверка, является ли этот элемент первым открытым контрактом */
    fun isFirstContract(menuItem: MenuItem): Boolean =
        openedContracts.isNotEmpty() && openedContracts.first().second == menuItem

    /** Закрытие контракта и удаление соответствующего элемента из меню */
    private fun removeContract(contractId: Int) {
        val contractPair = openedContracts.find { it.first == contractId }
        if (contractPair != null) {
            //если открыт его дочерний элемент: выбрать страницу контрактов
            if (contractPair.second.anyChildSelected()) {
                val contractsItem = topItems.findByPath(Contracts.generateNavPath())!!
                selectedState.value = contractsItem
                contractsItem.action()
            }
            // удалить этот контракт из списка открытых контрактов, обновить структуру, пересобрать отображаемую структуру, перерисовать
            openedContracts.remove(contractPair)
            updateInnerStructure()
            recollectMenuItems()
        }
    }

    /** Контракт с указанным id нужно подкрашивать выделенным стилем */
    private fun highlightContract(contractId: Int): Boolean {
        val contractItem =
            openedContracts.find { getContractIdForOpenedContract(it.second.navigationPath) == contractId }?.second

        return contractItem != null &&
                (selectedState.value == contractItem ||
                        (!expandedState.value && contractItem.anyChildSelected()))
    }

    @Composable
    fun generateNavHost() {
        /**
         * Хост навигации для SPA
         */
        NavHost(
            navController = menuNavController,
            startDestination = Contracts.generateNavPath(),
            modifier = Modifier.fillMaxSize()
        ) {
            composable(CompaniesContracts.generateNavPath()) { CompaniesContractsView() }
            composable(CustomersAssignment.generateNavPath()) { CustomerAssignmentView() }
            composable(SubcontractorsAssignment.generateNavPath()) { SubcontractorAssignmentView() }
            composable(Contacts.generateNavPath()) { ContactsView() }
            composable(Contracts.generateNavPath()) {
                ContractsView(onContractClick = { contract ->
                    //Если выбрали контракт - добавляем подвкладки для него, выбираем первую, перерисовываем меню (если нужно)
                    addContractTabs(contract.contractId, contract.name)
                })
            }
            contractPageView()
            composable(Resources.generateNavPath()) { ResourcesView() }
            composable(Geofences.generateNavPath()) { GeofencesView() }
            composable(SubcontractorsIdSetter.generateNavPath()) { SubcontractorsIdSetterView() }
            composable(Help.generateNavPath()) { HelpView() }
            composable(Workers.generateNavPath()) { WorkersView() }
            composable(Departments.generateNavPath()) { DepartmentsView() }
            composable(Roles.generateNavPath()) { RolesView() }
            composable(Modules.generateNavPath()) { ModulesView() }
        }
    }

    private fun processMenuItemClick(item: MenuItem) {
        //Если уже выбран этот или дочерний элемент - не обрабатываем действие дополнительно.
        //Если не выбран: выбираем элемент, обновляем структуру,
        // перерисовываем меню (при необходимости) и выполняем действие нового выбранного элемента
        if (!item.anyChildSelected()) {

            if (item.selectable) {
                selectedState.value = item
                updateInnerStructure()
                recollectMenuItems()
            }
            item.action()
            //Отдельно случай, если указано, что при нажатии на элемент при наличии выбранных детей
            // нужно их удалять и выполнять действие выбранной странице
            // (на практике, для случая страницы контрактов, которая самостоятельная и без детей)
        } else if (item.reopenOnClickIfChildSelected && item.selectable && expandedState.value) {
            reselectItemWithUnnecessaryChildren(item) //Примечание: не используется
        }
    }

    private fun processDropdownMenuItemClicked(parentItem: MenuItem, childItem: MenuItem) {
        //Если это базовый элемент, который можно выбирать отдельно - обрабатываем соответствующим образом
        if (childItem == parentItem) reselectItemWithUnnecessaryChildren(childItem)
        else {
            if (childItem.children.isNotEmpty()) {
                //Примечание: если хотим отображать многоуровневое меню - открывать доп. dropdownMenu (add eventApp)
                println("\u001B[31m" +
                        "Многоуровневая структура меню сейчас не отображается при свернутом меню"
                        + "\u001B[0m"
                )
            }
            selectedState.value = childItem
            updateInnerStructure()
            childItem.action()
        }
    }

    private fun NavGraphBuilder.contractPageView() {
        composable(
            route = contractTabNavRoute,
            arguments = listOf(
                //ID выбранного контракта
                navArgument(contractIdKey) { type = androidx.navigation.NavType.IntType; nullable = false },
                //Название выбранной подвкладки контракта
                navArgument(contractTabName) { type = androidx.navigation.NavType.StringType; nullable = false }
            )
        ) { backStackEntry ->
            val contractId = backStackEntry.arguments?.getInt(contractIdKey)
                ?: throw IllegalStateException("Не удалось найти ключ ${contractIdKey} в бэктреке ${backStackEntry.destination.route}")

            val tabName = backStackEntry.arguments?.getString(contractTabName)
                ?: throw IllegalStateException("Не удалось найти вкладку ${contractTabName} в бэктреке ${backStackEntry.destination.route}")

            when (tabName) {
                Dashboard.generateNavPath() -> ContractPageView(contractId)
                Data.generateNavPath() -> DataView(contractId)
                Planning.generateNavPath() -> PlanningView(contractId)
                Control.generateNavPath() -> ControlView(contractId)
                Reports.generateNavPath() -> ReportsView(contractId)
                ContractSettings.generateNavPath() -> ContractSettingsView(contractId)
                else -> {}
            }
        }
    }


    /** Для данного элемента Menu создаем MenuItem (если надо, то учитываем выбранный контракт) */
    private fun Menu.createMenuItem(
        menuNavController: NavHostController,
        contractId: Int? = null,
        contractName: String? = null
    ) =
        when (this) {
            Companies -> MenuItem(
                imageContent = { expanded, modifier ->
                    painter.iconWrapper(null, expanded, modifier) { Icons.Default.AccountBalance }
                },
                rightContent = null,
                "Контрагенты",
                true,
                navigationPath = Companies.generateNavPath()
                // При выборе самого элемента выбирается первый его дочерний элемент, если получится
                // (к моменту выполнения действия структура должна уже обновиться на основе выбранного элемента (этого),
                // то есть дети должны уже добавиться)
            ) { selectFirstChild(Companies.generateNavPath()) }

            CompaniesContracts -> MenuItem(
                //Картинки стандартной нет - может быть или пусто, или точка выбора, поскольку дочерний элемент
                { _, _ -> },
                rightContent = null,
                "Компании",
                true,
                navigationPath = CompaniesContracts.generateNavPath(),
            ) { menuNavController.navigate(CompaniesContracts.generateNavPath()) }

            CustomersAssignment -> MenuItem(
                { _, _ -> },
                rightContent = null,
                "Заказчики",
                true,
                navigationPath = CustomersAssignment.generateNavPath(),
            ) { menuNavController.navigate(CustomersAssignment.generateNavPath()) }

            SubcontractorsAssignment -> MenuItem(
                { _, _ -> },
                rightContent = null,
                "Субподряд",
                true,
                navigationPath = SubcontractorsAssignment.generateNavPath()
            ) { menuNavController.navigate(SubcontractorsAssignment.generateNavPath()) }

            Contacts -> MenuItem(
                imageContent = { expanded, modifier ->
                    painter.iconWrapper(null, expanded, modifier) { Icons.Default.Contacts }
                },
                rightContent = null,
                "Контакты",
                true,
                navigationPath = Contacts.generateNavPath()
            ) { menuNavController.navigate(Contacts.generateNavPath()) }

            Contracts -> MenuItem(
                imageContent = { expanded, modifier ->
                    painter.iconWrapper(null, expanded, modifier) { Icons.Default.Dashboard }
                },
                rightContent = null,
                "Контракты",
                true,
                navigationPath = Contracts.generateNavPath(),
            ) { menuNavController.navigate(Contracts.generateNavPath()) }

            OpenedContract -> MenuItem(
                imageContent = { _, modifier ->
                    painter.openContractIconGenerator(
                        contractName!!,
                        mutableStateOf(highlightContract(contractId!!)),
                        modifier
                    )
                },
                rightContent = { selectedState ->
                    painter.getOpenedContractRightContent(selectedState) { removeContract(contractId!!) }
                },
                contractName!!,
                true,
                navigationPath = generateOpenedContractPath(contractId!!)
            ) { selectFirstChild(generateOpenedContractPath(contractId)) }

            Dashboard -> MenuItem(
                { _, _ -> },
                rightContent = null,
                "Дашборд",
                true,
                navigationPath = Dashboard.generateNavPath(contractId!!)
                //Перемещаемся на страницу согласно переданному contractId
            ) { menuNavController.navigate(Dashboard.generateNavPath(contractId)) }

            Data -> MenuItem(
                { _, _ -> },
                rightContent = null,
                "Данные",
                true,
                navigationPath = Data.generateNavPath(contractId!!)
            ) { menuNavController.navigate(Data.generateNavPath(contractId)) }

            Planning -> MenuItem(
                { _, _ -> },
                rightContent = null,
                "План",
                true,
                navigationPath = Planning.generateNavPath(contractId!!)
            ) { menuNavController.navigate(Planning.generateNavPath(contractId)) }

            Control -> MenuItem(
                { _, _ -> },
                rightContent = null,
                "Факт",
                true,
                navigationPath = Control.generateNavPath(contractId!!)
            ) { menuNavController.navigate(Control.generateNavPath(contractId)) }

            Reports -> MenuItem(
                { _, _ -> },
                rightContent = null,
                "Отчёты",
                true,
                navigationPath = Reports.generateNavPath(contractId!!)
            ) { menuNavController.navigate(Reports.generateNavPath(contractId)) }

            ContractSettings -> MenuItem(
                { _, _ -> },
                rightContent = null,
                "Настройки",
                true,
                navigationPath = ContractSettings.generateNavPath(contractId!!)
            ) { menuNavController.navigate(ContractSettings.generateNavPath(contractId)) }

            Folders -> MenuItem(
                imageContent = { expanded, modifier ->
                    painter.iconWrapper(null, expanded, modifier) { Icons.Default.Folder }
                },
                rightContent = null,
                "Справочники",
                true,
                navigationPath = Folders.generateNavPath()
            ) { selectFirstChild(Folders.generateNavPath()) }

            Resources -> MenuItem(
                { _, _ -> },
                rightContent = null,
                "Ресурсы",
                true,
                navigationPath = Resources.generateNavPath()
            ) { menuNavController.navigate(Resources.generateNavPath()) }

            Geofences -> MenuItem(
                { _, _ -> },
                rightContent = null,
                "Геозоны",
                true,
                navigationPath = Geofences.generateNavPath()
            ) { menuNavController.navigate(Geofences.generateNavPath()) }

            SubcontractorsIdSetter -> MenuItem(
                { _, _ -> },
                rightContent = null,
                "Субподряд",
                true,
                navigationPath = SubcontractorsIdSetter.generateNavPath()
            ) { menuNavController.navigate(SubcontractorsIdSetter.generateNavPath()) }

            Help -> MenuItem(
                imageContent = { expanded, modifier ->
                    painter.iconWrapper(null, expanded, modifier) { Icons.AutoMirrored.Default.Help }
                },
                rightContent = null,
                "Помощь",
                true,
                navigationPath = Help.generateNavPath()
            ) { menuNavController.navigate(Help.generateNavPath()) }

            Team -> MenuItem(
                imageContent = { expanded, modifier ->
                    painter.iconWrapper(null, expanded, modifier) { Icons.Default.People }
                },
                rightContent = null,
                "Команда",
                true,
                navigationPath = Team.generateNavPath()
            ) { selectFirstChild(Team.generateNavPath()) }

            Workers -> MenuItem(
                { _, _ -> },
                rightContent = null,
                "Сотрудники",
                true,
                navigationPath = Workers.generateNavPath()
            ) { menuNavController.navigate(Workers.generateNavPath()) }

            Departments -> MenuItem(
                { _, _ -> },
                rightContent = null,
                "Отделы",
                true,
                navigationPath = Departments.generateNavPath()
            ) { menuNavController.navigate(Departments.generateNavPath()) }

            Roles -> MenuItem(
                { _, _ -> },
                rightContent = null,
                "Роли",
                true,
                navigationPath = Roles.generateNavPath()
            ) { menuNavController.navigate(Roles.generateNavPath()) }

            Modules -> MenuItem(
                { _, _ -> },
                rightContent = null,
                "Модули",
                true,
                navigationPath = Modules.generateNavPath()
            ) { menuNavController.navigate(Modules.generateNavPath()) }
        }


    /** Для данного списка элементов строится список отображаемых элементов с их уровнями (в зависимости от статуса меню) */
    private fun List<MenuItem>.rebuildShowing(expanded: Boolean) =
        if (expanded)
            mutableListOf<Pair<MenuItem, Int>>().apply {
                this@rebuildShowing.forEach {
                    this.addNewItemWithChildren(
                        0,
                        it,
                        expanded
                    )
                }
            }
        else this.map { it to 0 }.toMutableList()


    /**
     * Обновляем структуру - если элемент уже не выбран (так же как и его дети), надо удалить его дочерние элементы.
     * Если выбран - пытаемся добавить ему новые дочерние элементы
     *
     * @return изменилась ли структура
     */
    private fun List<MenuItem>.updateChildren(selectedValue: MenuItem, menuNavController: NavHostController): Boolean {
        var changed = false
        // Обновляем подэлементы меню:
        // идем по всем элементам, если элемент выбран - добавить ему дочерние элементы;
        // если ни он, ни его потомки не выбраны - удалить этих потомков
        this.forEach { menuItem ->
            //Если элемент выбран, пытаемся добавить его дочерние элементы
            if (menuItem == selectedValue) {
                val newAdded = menuItem.updateChildrenForSelected(menuNavController)
                changed = changed || newAdded
            }
            //Если не выбран - проверяем, есть ли у него дочерние элементы, и надо ли их удалять (если не выбран ни один из дочерних)
            else if (menuItem.children.isNotEmpty() && !menuItem.anyChildSelected()) {
                menuItem.children.clear()
                changed = true
            }
        }
        return changed
    }

    /** По navigationPath определяем соответствующий элемент Menu.
     * Если определили - смотрим, какие для него предусмотрены подэлементы, создаем их и добавляем */
    private fun MenuItem.updateChildrenForSelected(menuNavController: NavHostController): Boolean {
        if (children.isNotEmpty()) return false

        //Случай 1 - если простая вкладка меню
        val currentMenu = Menu.entries.find { it.generateNavPath() == navigationPath }

        if (currentMenu != null && currentMenu.getChildren().isNotEmpty()) {
            children.addAll(currentMenu.getChildren().map { it.createMenuItem(menuNavController) })
            return true
        }
        //Случай 2: если открытый контракт (у него надо учитывать contractId)
        else {
            val contractId = getContractIdForOpenedContract(navigationPath)
            if (contractId != null) {
                children.addAll(OpenedContract.getChildren().map { it.createMenuItem(menuNavController, contractId) })
                return true
            }
        }

        return false
    }

    /** Определение, выбран ли сам элемент или какой-либо из его дочерних элементов */
    private fun MenuItem.anyChildSelected(): Boolean {
        return this == selectedState.value || this.children.any { c -> c.anyChildSelected() }
    }

    /** Рекурсивное разворачивание иерархии элементов (если надо показывать детей, то добавляем их после родительского элемента) */
    private fun MutableList<Pair<MenuItem, Int>>.addNewItemWithChildren(
        level: Int,
        item: MenuItem,
        showChildren: Boolean
    ) {
        this.add(item to level)
        if (!showChildren) return
        item.children.forEach { this.addNewItemWithChildren(level + 1, it, showChildren) }
    }

    /** Перевод данного Menu в строковый путь */
    private fun Menu.generateNavPath() = this.name

    /** Перевод данного Menu в строковый путь с учетом выбранного контракта */
    private fun Menu.generateNavPath(contractId: Int) =
        "${generateOpenedContractPath(contractId)}/${this.generateNavPath()}"

    private fun generateOpenedContractPath(contractId: Int) = "$contractNavRoot/$contractId"

    /** Проверяем, что если это путь до открытого контракта, то возвращаем его contractId */
    private fun getContractIdForOpenedContract(path: String?): Int? {
        if (path == null) return null
        val parts = path.split("/")
        return if (parts.size == 2 && parts[0] == contractNavRoot && parts[1].toIntOrNull() != null) parts[1].toInt()
        else null
    }

    /** Ищем среди данных элементов и их дочерних: элемент с указанным navigationPath */
    private fun List<MenuItem>.findByPath(navigationPath: String): MenuItem? {
        //Примечание: чтобы работала корректно, нужно, чтоб не было кольцевых зависимостей

        //Проверяем элементы этого уровня. Если не они - смотрим их детей (следующий уровень).
        //Если таких нет, считаем, что не нашли. Иначе - вызываем анализ уже для следующего уровня
        this.forEach { mu ->
            if (mu.navigationPath == navigationPath) return mu
        }
        val nextLevelChildren = this.map { it.children }.flatten()
        return if (nextLevelChildren.isEmpty()) null else nextLevelChildren.findByPath(navigationPath)
    }

    fun isAnyChildSelected(item: MenuItem): Boolean = item.anyChildSelected()

    override fun onEvent(uiEvent: RoadsUiEvent) {
        if (uiEvent !is SpaMenuUiEvent) return
        when (uiEvent){
            is SpaMenuUiEvent.MainMenuItemClicked -> processMenuItemClick(uiEvent.menuItem)
            is SpaMenuUiEvent.DropdownMenuItemClicked -> processDropdownMenuItemClicked(uiEvent.parent, uiEvent.child)
        }
    }
}