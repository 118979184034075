package novasoft.roads.dto.client_common

import kotlinx.serialization.Serializable

@Serializable
data class HelpTextForDto(
    val id: String,
    var parentId: String,
    var shortName: String,
    var text: String,
)