package src.novasoft.roads.compose_client.feature.menu.help

import common.RoadsUiEvent
import novasoft.roads.dto.client_common.HelpTextForDto

data class HelpState(
    val mainText: String = "",
    val helpTexts: List<HelpTextForDto> = listOf(),
    val pageTitle: String = "",
    val selectedNodeId: String = "",
    val path: String = "",
    val leftEnabled: Boolean = true,
    val rightEnabled: Boolean = true,
    val byteFile : ByteArray = byteArrayOf()
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as HelpState

        if (mainText != other.mainText) return false
        if (helpTexts != other.helpTexts) return false
        if (pageTitle != other.pageTitle) return false
        if (selectedNodeId != other.selectedNodeId) return false
        if (path != other.path) return false
        if (leftEnabled != other.leftEnabled) return false
        if (rightEnabled != other.rightEnabled) return false
        if (!byteFile.contentEquals(other.byteFile)) return false

        return true
    }

    override fun hashCode(): Int {
        var result = mainText.hashCode()
        result = 31 * result + helpTexts.hashCode()
        result = 31 * result + pageTitle.hashCode()
        result = 31 * result + selectedNodeId.hashCode()
        result = 31 * result + path.hashCode()
        result = 31 * result + leftEnabled.hashCode()
        result = 31 * result + rightEnabled.hashCode()
        result = 31 * result + byteFile.contentHashCode()
        return result
    }
}

enum class ArrowType { LEFT, RIGHT }

sealed class HelpUiEvent : RoadsUiEvent {
    data class LinkClicked(val tag : String) : HelpUiEvent()
    data class NodeClicked(val nodeName: String, val nodeId : String) : HelpUiEvent()
    data class ArrowClicked(val arrowType: ArrowType) : HelpUiEvent()
}