package ru.novasoft.roads.compose_client.core.ui.chart

import androidx.compose.animation.core.Spring
import androidx.compose.animation.core.spring
import androidx.compose.foundation.layout.*
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import ru.novasoft.roads.compose_client.core.designsystem.theme.doneBrightColor
import ru.novasoft.roads.compose_client.core.designsystem.theme.planBrightColor
import ru.novasoft.roads.compose_client.core.model.model.table.RRTableRow
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.XAxisPadding
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Spacing.defaultSpacing
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Spacing.innerDefaultSpaceBetween
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.maxBarWidth
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.format
import ru.novasoft.roads.compose_client.core.ui.chart.models.*

@Composable
fun PlanFactRunRateBarChart(
    row: RRTableRow,
    isMUnitSetToMU: Boolean,
    withTitle: Boolean = true,
    barProperties : BarProperties = BarProperties(
        cornerRadius = Bars.Data.Radius.None,
        thickness = maxBarWidth,
        spacing = 10.dp
    )
) {

    val data = row.getSeries(!isMUnitSetToMU).mapIndexedNotNull { index, it ->
        val barData = if (it.isNotEmpty()) it.first() else return@mapIndexedNotNull null
        Bars(
            label = barData.first,
            values = listOf(
                Bars.Data(
                    label = "",
                    value = barData.second.toDouble(),
                    color =
                        if (index == 0 || index == 1) SolidColor(MaterialTheme.colorScheme.planBrightColor)
                    else SolidColor(MaterialTheme.colorScheme.doneBrightColor)
                ),
            )
        )
    }

    Column(
        modifier = Modifier.fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally
    ){
        if (withTitle) {
            Text(
                modifier = Modifier.fillMaxWidth(),
                text = row.getWorkName(),
                style = MaterialTheme.typography.labelMedium,
                textAlign = TextAlign.Left,
                overflow = TextOverflow.Ellipsis,
                maxLines = 2,
            )
            Spacer(Modifier.height(defaultSpacing))
        }
        Row {
            Text(
                text = row.getUnit(!isMUnitSetToMU),
                textAlign = TextAlign.Center,
                style = MaterialTheme.typography.bodySmall,
                modifier = Modifier.align(Alignment.CenterVertically).rotate(-90f)
            )
            Spacer(Modifier.width(innerDefaultSpaceBetween))
            StackedBarChart(
                modifier = Modifier
                    .fillMaxSize()
                    .weight(1f),
                data = data,
                barProperties = barProperties,
                animationSpec = spring(
                    dampingRatio = Spring.DampingRatioMediumBouncy,
                    stiffness = Spring.StiffnessLow
                ),
                labelHelperProperties = LabelHelperProperties(enabled = false),
                labelProperties = LabelProperties(
                    textStyle = MaterialTheme.typography.bodySmall.copy(textAlign = TextAlign.Center),
                    padding = XAxisPadding,
                    enabled = true,
                    rotationDegreeOnSizeConflict = 0f
                ),
                indicatorProperties = HorizontalIndicatorProperties(
                    textStyle = MaterialTheme.typography.bodySmall,
                    count = 5,
                    padding = 5.dp,
                    position = IndicatorPosition.Horizontal.Start,
                    contentBuilder = { it.toInt().toString() }
                ),
                popupProperties = PopupProperties(
                    textStyle = TextStyle.Default.copy(
                        color = MaterialTheme.colorScheme.onPrimaryContainer,
                        fontSize = 12.sp
                    ),
                    contentBuilder = { indicator ->
                        indicator.format(1)
                    }
                ),
                maxLines = 2
            )
        }    }
}