package src.novasoft.roads.compose_client.feature.menu.contract_screen

import common.RoadsException
import common.RoadsUiEvent
import novasoft.roads.dto.plan.res_sheet_history.ResourcesSheetHistoryDto
import novasoft.roads.dto.svor.SvorEntryDto
import ru.novasoft.roads.compose_client.core.ui.widgets.ForecastStatusCardData
import src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs.ProgressDetailing


enum class DashboardTab(val tabName: String) {
    JOBS("Работы"),
    RESOURCES("Ресурсы"),
    FINANCES("Финансы")
}

data class ContractRunRateData(
    val actualSheet: ResourcesSheetHistoryDto,
    val svorEntries: Map<Long, SvorEntryDto>,
    val wtDoneParts: Map<Long, Int>
)

data class ContractForecastData(
    val title: String,
    val data: ForecastStatusCardData
)

sealed interface JobsUiEvent: RoadsUiEvent {
    data class RunRateDataReceived(val dataForCalcRR: ContractRunRateData): JobsUiEvent
    data class ForecastDataReceived(
        val detailing: ProgressDetailing? = null,
        val svorEntries: List<SvorEntryDto>,
        val svorWorth: Long
    ): JobsUiEvent
}

class IsNotJobsUiEvent: RoadsException, Exception()