import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.navigation.NavHostController
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.composable
import androidx.navigation.compose.rememberNavController
import com.russhwolf.settings.ExperimentalSettingsApi
import com.russhwolf.settings.Settings
import com.russhwolf.settings.serialization.decodeValueOrNull
import com.russhwolf.settings.serialization.removeValue
import kotlinx.datetime.Clock
import kotlinx.serialization.ExperimentalSerializationApi
import novasoft.roads.dto.company_team.person.PersonInfoDto
import org.kodein.di.compose.rememberDI
import org.kodein.di.compose.withDI
import org.kodein.di.instance
import ru.novasoft.roads.compose_client.core.data.IUserRepo
import ru.novasoft.roads.compose_client.core.data.di.sharedDataDI
import ru.novasoft.roads.compose_client.core.data.remote.IAppStateInfoRepository
import ru.novasoft.roads.compose_client.core.data.remote.ITokenRepository
import ru.novasoft.roads.compose_client.core.designsystem.theme.RoadsTheme
import ru.novasoft.roads.compose_client.core.model.routing.RootRouting
import src.novasoft.roads.compose_client.feature.auth.AuthView

/** Общая точка входа для других платформ. Прим.: не для Preview */
@ExperimentalSettingsApi
@ExperimentalSerializationApi
@Composable
fun RootViewTopLevel() {
    val rootNavController: NavHostController = rememberNavController()

    RoadsTheme {
        RootView(
            rootNavController = rootNavController
        )
    }
}

@ExperimentalSettingsApi
@ExperimentalSerializationApi
private fun checkTokens(settings : Settings, appStateRepo : IAppStateInfoRepository, tokenRepo : ITokenRepository, userRepo: IUserRepo, onSuccess : () -> Unit) {
    val userInfo = settings.decodeValueOrNull(PersonInfoDto.serializer(), "personInfo") ?: return
    val token = userInfo.jwtToken
    val expiration = userInfo.expire
    appStateRepo.setCompanyId(userInfo.companyId)
    userRepo.setUser(userInfo)
    tokenRepo.setToken(token)
    val now = Clock.System.now().toEpochMilliseconds()
    if(expiration - now < 0) return
    tokenRepo.setToken(token)
    onSuccess()
}
/** Функция во многом для реализации Preview */
@ExperimentalSettingsApi
@ExperimentalSerializationApi
@Composable
fun RootView(
    content: (@Composable () -> Unit)? = null,
    rootNavController: NavHostController
) {
    withDI(sharedDataDI()) {
        val appStateRepository: IAppStateInfoRepository by rememberDI { instance() }
        val tokenRepository: ITokenRepository by rememberDI { instance() }
        val userRepository: IUserRepo by rememberDI { instance() }
        val settings: Settings by rememberDI { instance() }
        Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
            content?.invoke() ?: NavHost(
                navController = rootNavController,
                startDestination = RootRouting.Auth.name,
                modifier = Modifier.fillMaxSize()
            ) {
                composable(RootRouting.Auth.name) { AuthView(onSuccessAuth = { rootNavController.navigate(RootRouting.MainApp.name) }) }
                composable(RootRouting.MainApp.name) { RoadsSPA(rootNavController) {
                    settings.removeValue(PersonInfoDto.serializer(), "personInfo")
                } }
            }
            checkTokens(settings, appStateRepository, tokenRepository, userRepository) { rootNavController.navigate(RootRouting.MainApp.name) }
        }
    }
}