package src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs

import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Check
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes

@Composable
internal fun SelectableList(
    headLine: @Composable () -> Unit,
    detailing: List<ProgressDetailing>?,
    selectedItem: MutableState<ProgressDetailing?>,
    clickAction: (Int) -> Unit,
) {
    if (detailing == null) return

    headLine()
    Spacer(modifier = Modifier.height(DefaultSizes.Spacing.innerDefaultSpaceBetween))
    LazyColumn(
        modifier = Modifier.heightIn(max = 200.dp)
            .border(
                2.dp,
                MaterialTheme.colorScheme.onSurface,
                RoundedCornerShape(DefaultSizes.CornerRadius.smallRadius)
            )
    ) {
        items(detailing.count()) { option ->
            ListItem(
                headlineContent = {
                    Text(detailing[option].title, style = MaterialTheme.typography.bodyLarge)
                },
                modifier = Modifier
                    .clickable { clickAction(option) },
                leadingContent = {
                    if (selectedItem.value == detailing[option]) Icon(Icons.Filled.Check, "")
                    else Spacer(Modifier)
                },
                colors = ListItemDefaults.colors(
                    containerColor =
                        if (selectedItem.value == detailing[option])
                            MaterialTheme.colorScheme.onSurface.copy(alpha = 0.2f)
                        else MaterialTheme.colorScheme.surfaceContainer
                )
            )
        }
    }
}