package ru.novasoft.roads.compose_client.core.ui.widgets

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.BarChart
import androidx.compose.material.icons.filled.TableRows
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.times
import novasoft.roads.util.FormatUtils.formatBitDepth
import ru.novasoft.roads.compose_client.core.model.model.table.RRTableRow
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.defaultPaddingCard
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Spacing.defaultExtraSpacing
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Spacing.defaultSpaceBetweenCards
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Spacing.defaultSpacing
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.cardSizeBase
import ru.novasoft.roads.compose_client.core.ui.bricks.DualSwitcher
import ru.novasoft.roads.compose_client.core.ui.chart.PlanFactRunRateBarChart
import ru.novasoft.roads.compose_client.core.ui.effects.LoadingEffect
import ru.novasoft.roads.compose_client.core.ui.table.TreeNode
import ru.novasoft.roads.compose_client.core.ui.table.TreeTableFactory
import ru.novasoft.roads.compose_client.core.ui.table.mappers.TableColumn
import ru.novasoft.roads.compose_client.core.ui.widgets.ConstStrings.SECOND_SWITCHER_A
import ru.novasoft.roads.compose_client.core.ui.widgets.ConstStrings.SECOND_SWITCHER_B

private const val CHARTS_PER_ROW = 4

@OptIn(ExperimentalLayoutApi::class)
@Composable
fun RunRateCardContent(
    modifier: Modifier = Modifier,
    rrData: TreeNode<RRTableRow>?
) {
    ElevatedCard(
        modifier = Modifier
            .size(width = 4 * cardSizeBase + defaultSpaceBetweenCards, height = 600.dp),
        colors = CardDefaults.cardColors(containerColor = MaterialTheme.colorScheme.onPrimary),
        elevation = CardDefaults.elevatedCardElevation()
    ) {
        if (rrData == null) {
            LoadingEffect()
            return@ElevatedCard
        }

        val isUISetToTable = remember { mutableStateOf(true) }
        val isMUnitSetToMU = remember { mutableStateOf(true) }
        val tableColumns = remember(isMUnitSetToMU.value) {
            mutableStateOf<List<TableColumn<RRTableRow>>>(getTableColumns(isMUnitSetToMU.value))
        }

        Column(
            modifier = modifier
                .fillMaxSize()
                .padding(defaultPaddingCard),
            verticalArrangement = Arrangement.spacedBy(5.dp, alignment = Alignment.Top)
        ) {
            Row(Modifier.fillMaxWidth()){
                Text(
                    text = "Динамика выполнения плана",
                    style = MaterialTheme.typography.headlineSmall,
                    textAlign = TextAlign.Left,
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis,
                    modifier = Modifier.fillMaxWidth().weight(1f)
                )

                Row(
                    horizontalArrangement = Arrangement.spacedBy(defaultSpacing),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    DualSwitcher(
                        isUISetToTable,
                        { Icon(Icons.Filled.BarChart, "График", tint = MaterialTheme.colorScheme.primary) },
                        { Icon(Icons.Filled.TableRows, "Таблица", tint = MaterialTheme.colorScheme.primary) }
                    )

                    DualSwitcher(
                        isMUnitSetToMU,
                        { Text(text = SECOND_SWITCHER_A, style = MaterialTheme.typography.labelLarge) },
                        { Text(text = SECOND_SWITCHER_B, style = MaterialTheme.typography.labelLarge) }
                    )
                }
            }

            Text(text = "", style = MaterialTheme.typography.bodySmall)


            if (isUISetToTable.value) {
                TreeTableFactory(
                    tableColumns.value,
                    rrData.children.toMutableList()
                )
            }
            else {
                BoxWithConstraints(modifier = Modifier.fillMaxSize()) {
                    val calculatedChartWidth = (maxWidth - (CHARTS_PER_ROW - 1) * defaultExtraSpacing) / CHARTS_PER_ROW
                    FlowRow(
                        modifier = Modifier
                            .fillMaxSize()
                            .verticalScroll(rememberScrollState()),
                        horizontalArrangement = Arrangement.SpaceBetween,
                        verticalArrangement = Arrangement.spacedBy(defaultExtraSpacing, Alignment.Top),
                    ) {
                        rrData.children.forEach {

                        Box(Modifier.size(calculatedChartWidth, 250.dp)) {
                            PlanFactRunRateBarChart(
                                it.content,
                                isMUnitSetToMU.value
                            )
                        }
                        }
                    }
                }
            }
        }
    }
}


private fun getTableColumns(isMUnitSetToMU: Boolean) = listOf(
    object : TableColumn<RRTableRow> {
        override fun getCellComposable(): @Composable BoxScope.(dto: RRTableRow) -> Unit = {
            Text(
                it.getWorkName(),
                style = MaterialTheme.typography.bodyMedium,
                overflow = TextOverflow.Ellipsis,
                maxLines = 1,
                textAlign = TextAlign.Start,
                modifier = Modifier.fillMaxWidth().background(Color(it.getColorStrategy()))
            )
        }
        override fun getColumnName() = "Вид работ"
        override fun getWidth(): Dp = 530.dp
    },
    object : TableColumn<RRTableRow> {
        override fun getCellComposable(): @Composable BoxScope.(dto: RRTableRow) -> Unit = {
            Text(
                formatBitDepth(it.getTotalPlan(!isMUnitSetToMU), 0),
                style = MaterialTheme.typography.bodyMedium,
                textAlign = TextAlign.End,
                modifier = Modifier.fillMaxWidth().background(Color(it.getColorStrategy()))
            )
        }
        override fun getColumnName() = "План, Всего"
        override fun getWidth(): Dp = 125.dp
    },
    object : TableColumn<RRTableRow> {
        override fun getCellComposable(): @Composable BoxScope.(dto: RRTableRow) -> Unit = {
            Text(
                formatBitDepth(it.getTDPlan(!isMUnitSetToMU), 0),
                style = MaterialTheme.typography.bodyMedium,
                textAlign = TextAlign.End,
                modifier = Modifier.fillMaxWidth().background(Color(it.getColorStrategy()))
            )
        }
        override fun getColumnName() = "План, TD"
        override fun getWidth(): Dp = 125.dp
    },
    object : TableColumn<RRTableRow> {
        override fun getCellComposable(): @Composable BoxScope.(dto: RRTableRow) -> Unit = {
            Text(
                it.getUnit(!isMUnitSetToMU),
                style = MaterialTheme.typography.bodyMedium,
                textAlign = TextAlign.Start,
                modifier = Modifier.fillMaxWidth().background(Color(it.getColorStrategy()))
            )
        }
        override fun getColumnName() = "ЕИ"
        override fun getWidth(): Dp = 60.dp
    },
    object : TableColumn<RRTableRow> {
        override fun getCellComposable(): @Composable BoxScope.(dto: RRTableRow) -> Unit = {
            Text(
                formatBitDepth(it.getFact(!isMUnitSetToMU), 0),
                style = MaterialTheme.typography.bodyMedium,
                textAlign = TextAlign.End,
                modifier = Modifier.fillMaxWidth().background(Color(it.getColorStrategy()))
            )
        }
        override fun getColumnName() = "Факт, %"
        override fun getWidth(): Dp = 70.dp
    },
    object : TableColumn<RRTableRow> {
        override fun getCellComposable(): @Composable BoxScope.(dto: RRTableRow) -> Unit = {
            Text(
                formatBitDepth(it.getRR(!isMUnitSetToMU), 0),
                style = MaterialTheme.typography.bodyMedium,
                textAlign = TextAlign.End,
                modifier = Modifier.fillMaxWidth().background(Color(it.getColorStrategy()))
            )
        }
        override fun getColumnName() = "RR, %"
        override fun getWidth(): Dp = 70.dp
    }
)
