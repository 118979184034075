package novasoft.roads.dto.meter_progress

import kotlinx.serialization.Serializable

@Serializable
data class ProgressWithDefaultDto(
    val planned: Long? = null,
    val done: Long? = null,
    val reported: Long? = null,
    val gplanned: Long? = null,
    val gplanPart: Double? = null,
    val donePart: Double? = null,
    val passedPart: Double? = null
)