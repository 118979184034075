package src.novasoft.roads.compose_client.feature.menu.contract_screen

import novasoft.roads.util.calculations.IResourceAmountCalculator
import novasoft.roads.util.calculations.ResourceAmountCalculator
import org.kodein.di.*
import src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs.jobs.JobsDashBoardViewModel

fun dashboardDIModule() = DI.Module("dashboardModule") {
    bind<IResourceAmountCalculator>() with singleton { ResourceAmountCalculator(instance()) }

    bind<JobsDashBoardViewModel>() with multiton {
        contractId: Int -> JobsDashBoardViewModel(instance(), instance(), instance(), contractId)
    }
    bind<ContractPageViewModel>() with multiton {
        contractId: Int -> ContractPageViewModel(instance(), instance(), instance(), contractId, instance())
    }
}