package ru.novasoft.roads.compose_client.core.model.mapping

import novasoft.roads.dto.meter_progress.ProgressDto
import novasoft.roads.dto.meter_progress.ProgressWithDefaultDto

/**
 * planned == null means empty
 */
fun ProgressWithDefaultDto.toProgressDto(): ProgressDto? =
    if (planned == null) null
    else ProgressDto(
        planned!!,
        done!!,
        reported!!,
        gplanned!!,
        gplanPart!!,
        donePart!!,
        passedPart!!
    )