package src.novasoft.roads.compose_client.feature.menu.contacts

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.*
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.ClipboardManager
import androidx.compose.ui.platform.LocalClipboardManager
import androidx.compose.ui.text.*
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.TextUnitType
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import ru.novasoft.roads.compose_client.core.ui.menu.MenuPageTemplate
import ru.novasoft.roads.compose_client.core.ui.menu.PageHeaderView

private const val WEB_LINK_AVTODOR = "https://novasoft-avtodor.ru" //Примечание: сайт не рабочий
private const val MAIL_LINK_AVTODOR = "mailto:info@novasoft-autodor.ru"
private const val MAIL_TEXT = "info@novasoft-autodor.ru"
private const val PHONE_TEXT = "+79139059065"
private const val NAME_TEXT = "Лятковский Алексей, Директор ООО 'Новасофт'"
private const val WEB_TEXT = "www.novasoft-avtodor.ru"
private const val ITEMS_WIDTH = 550

private enum class ContactType {
    MAIL, PHONE, WEB;

    fun getIcon() = when (this) {
        MAIL -> Icons.Filled.Mail
        PHONE -> Icons.Filled.Phone
        WEB -> Icons.Filled.Computer
    }

    fun getMainText() = when (this) {
        MAIL -> MAIL_TEXT
        PHONE -> PHONE_TEXT
        WEB -> WEB_TEXT
    }

    fun getMainLink() = when (this) {
        MAIL -> MAIL_LINK_AVTODOR
        PHONE -> null
        WEB -> WEB_LINK_AVTODOR
    }

    fun getDescription() = when (this) {
        MAIL -> "адрес почты"
        PHONE -> "номер телефона"
        WEB -> "адрес сайта"
    }

    fun getSecondaryText() = when (this) {
        PHONE -> NAME_TEXT
        else -> null
    }
}

@Composable
private fun createLinkText(text: String, link: String) {
    Text(
        buildAnnotatedString {
            append(text)
            addStyle(
                style = SpanStyle(
                    color = Color.Blue,
                    textDecoration = TextDecoration.Underline
                ),
                start = 0,
                end = text.length
            )
            addLink(
                LinkAnnotation.Url(link),
                0,
                text.length
            )
        }
    )
}

@Composable
private fun createCopyBtn(
    clipboardManager: ClipboardManager,
    textToCopy: String,
    description: String,
    showNotification: (String) -> Job
) =
    IconButton(onClick = {
        clipboardManager.setText(AnnotatedString(textToCopy))
        showNotification.invoke("Скопирован $description")
    }) {
        Icon(
            Icons.Filled.CopyAll,
            contentDescription = "Скопировать $description",
        )
    }

@Composable
private fun createListItem(type: ContactType, clipboardManager: ClipboardManager, showNotification: (String) -> Job) =
    ListItem(
        headlineContent = {
            val headerText = type.getMainText()
            val headerLink = type.getMainLink()

            if (headerLink != null) createLinkText(headerText, headerLink)
            else Text(headerText)
        },
        supportingContent = {
            val secondaryText = type.getSecondaryText()

            if (secondaryText != null) Text(secondaryText)
            else null
        },
        leadingContent = {
            Icon(
                type.getIcon(),
                contentDescription = null,
                modifier = Modifier.size(40.dp),
            )
        },
        trailingContent = {
            createCopyBtn(clipboardManager, type.getMainText(), type.getDescription(), showNotification)
        },
        colors = ListItemColors(
            containerColor = MaterialTheme.colorScheme.surfaceContainerLow,
            headlineColor = MaterialTheme.colorScheme.primary,
            leadingIconColor = MaterialTheme.colorScheme.primary,
            overlineColor = MaterialTheme.colorScheme.primary,
            supportingTextColor = MaterialTheme.colorScheme.primary,
            trailingIconColor = MaterialTheme.colorScheme.primary,
            disabledHeadlineColor = MaterialTheme.colorScheme.onPrimary,
            disabledLeadingIconColor = MaterialTheme.colorScheme.onPrimary,
            disabledTrailingIconColor = MaterialTheme.colorScheme.onPrimary
        )
    )

@Composable
fun ContactsView() {
    val clipboardManager: ClipboardManager = LocalClipboardManager.current
    val snackbarHostState = remember { SnackbarHostState() }
    val scope = rememberCoroutineScope()
    val showNotification = { text: String ->
        scope.launch {
            snackbarHostState.showSnackbar(text, duration = SnackbarDuration.Short)
        }
    }

    MenuPageTemplate(
        pageName = "Контакты",
        pageHeader = { PageHeaderView(it) },
        pageContent = {
            Scaffold(
                snackbarHost = { SnackbarHost(snackbarHostState) }) {
                Row(
                    horizontalArrangement = Arrangement.Center,
                    modifier = Modifier.fillMaxWidth()
                        .background(color = MaterialTheme.colorScheme.surfaceContainerHigh)
                ) {
                    Column(verticalArrangement = Arrangement.Center, modifier = Modifier.fillMaxHeight()) {
                        Column(
                            verticalArrangement = Arrangement.spacedBy(10.dp),
                            modifier = Modifier.width(ITEMS_WIDTH.dp),
                        ) {
                            Text(
                                "Наши контакты",
                                modifier = Modifier.width(ITEMS_WIDTH.dp),
                                textAlign = TextAlign.Center,
                                fontSize = TextUnit(24f, TextUnitType.Sp),
                                style = MaterialTheme.typography.titleLarge,
                            )
                            ContactType.entries.map { type ->
                                createListItem(type, clipboardManager, showNotification)
                            }
                        }
                    }
                }
            }
        }
    )
}