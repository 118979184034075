package src.novasoft.roads.compose_client.feature.menu.help

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import common.RoadsUiEvent
import common.RoadsViewModel
import kotlinx.coroutines.launch
import novasoft.roads.dto.client_common.HelpTextForDto
import ru.novasoft.roads.compose_client.core.network.api.help.IHelpApi
import ru.novasoft.roads.compose_client.core.network.api.report.IReportApi
import ru.novasoft.roads.compose_client.core.ui.menu.PageHeaderContract
import ru.novasoft.roads.compose_client.core.ui.menu.PageHeaderEvent
import src.novasoft.roads.compose_client.feature.menu.help.treeView.TreeNode
import src.novasoft.roads.compose_client.feature.menu.help.treeView.buildTree
import src.novasoft.roads.compose_client.feature.menu.help.treeView.treeToFlatList


class HelpViewModel(private val helpApi: IHelpApi) : RoadsViewModel(), PageHeaderContract {
    private lateinit var allTexts: List<HelpTextForDto>
    private lateinit var mainText: String
    private lateinit var path: String
    private lateinit var selectedNode: String
    var state by mutableStateOf(HelpState())


    private val nodesList = mutableListOf<Pair<String, String>>()
    private var nodesListPosition = 0
    private lateinit var tree: List<TreeNode>

    init {
        viewModelScope.launch {
            refresh()
        }
    }

    private suspend fun refresh() {
        allTexts = helpApi.getAllHelpTexts().await()
        val root = allTexts.first { it.parentId.isEmpty() }
        tree = buildTree(allTexts)
        treeToFlatList(tree, nodesList)
        selectedNode = root.shortName
        setMainText(root.shortName)
        state = state.copy(
            helpTexts = allTexts,
            pageTitle = root.shortName,
            mainText = mainText,
            selectedNodeId = root.id,
            leftEnabled = false,
            rightEnabled = true
        )
    }

    override fun onEvent(uiEvent: RoadsUiEvent) {
        if (uiEvent !is HelpUiEvent) return
        when (uiEvent) {
            is HelpUiEvent.NodeClicked -> {
                onNodeClicked(uiEvent)
            }
            is HelpUiEvent.ArrowClicked -> {
                when (uiEvent.arrowType) {
                    ArrowType.RIGHT -> {
                        onShowNext()
                    }
                    ArrowType.LEFT -> {
                        onShowPrevious()
                    }
                }
            }
            is HelpUiEvent.LinkClicked -> {
                updateTexts(uiEvent.tag)
            }
        }
    }

    private fun onNodeClicked(uiEvent: HelpUiEvent.NodeClicked) {
        setMainText(uiEvent.nodeId, uiEvent.nodeName)
        nodesListPosition = nodesList.indexOf(Pair(uiEvent.nodeId, uiEvent.nodeName))
        path = findPathToNodeById(
            root = tree.first { it.parentId.isEmpty() },
            targetId = uiEvent.nodeId
        ).toString()
        state = state.copy(
            pageTitle = uiEvent.nodeName,
            path = path,
            mainText = mainText,
            selectedNodeId = uiEvent.nodeId,
            rightEnabled = nodesListPosition < nodesList.size - 1,
            leftEnabled = nodesListPosition > 0
        )
    }

    private fun onShowPrevious() {
        if(nodesListPosition <= 0) return
        nodesListPosition--
        updateTextsForArrows(nodesList[nodesListPosition])
        state = if (nodesListPosition == 0) {
            state.copy(leftEnabled = false, rightEnabled = true, selectedNodeId = nodesList[nodesListPosition].first)
        } else {
            state.copy(leftEnabled = true, rightEnabled = true, selectedNodeId = nodesList[nodesListPosition].first)
        }
    }

    private fun onShowNext() {
        if(nodesListPosition >= nodesList.size - 1) return
        nodesListPosition++
        updateTextsForArrows(nodesList[nodesListPosition])
        state = if (nodesListPosition == nodesList.lastIndex) {
            state.copy(rightEnabled = false, leftEnabled = true, selectedNodeId = nodesList[nodesListPosition].first)
        } else {
            state.copy(rightEnabled = true, leftEnabled = true, selectedNodeId = nodesList[nodesListPosition].first)
        }
    }

    override fun onPageHeaderEvent(event: PageHeaderEvent) {
        when (event) {
            PageHeaderEvent.BackClicked -> {}
            PageHeaderEvent.HelpClicked -> {}
        }
    }

    private fun getTextByName(shortName: String): String {
        val item = allTexts.filter { it.shortName == shortName }
        return if (item.size != 1) "" else item[0].text
    }

    private fun getTextById(id : String) : String {
        val item = allTexts.filter { it.id == id}
        return if (item.size != 1) "" else item[0].text
    }

    private fun findPathToNodeById(root: TreeNode, targetId: String): String? {
        if (root.id == targetId) {
            return root.name
        }

        for (child in root.children) {
            val result = findPathToNodeById(child, targetId)
            if (result != null) {
                return "${root.name} / $result"
            }
        }

        return null
    }

    private fun findPathToNodeByName(root: TreeNode, targetName: String): String? {
        if (root.name == targetName) {
            return root.name
        }

        for (child in root.children) {
            val result = findPathToNodeByName(child, targetName)
            if (result != null) {
                return "${root.name} / $result"
            }
        }

        return null
    }

    private fun updateTexts(shortName: String) {
        setMainText(shortName)
    }

    private fun updateTextsForArrows(shortName: Pair<String, String>) {
        setMainText(shortName.first, shortName.second)
        path = findPathToNodeById(root = tree.first { it.parentId.isEmpty() }, targetId = shortName.first).toString()
        state = state.copy(
            mainText = mainText,
            pageTitle = shortName.second,
            path = path
        )
    }

    private fun setMainText(shortName: String) {
        mainText = getTextByName(shortName)
        println(shortName)
        path = findPathToNodeByName(root = tree.first { it.parentId.isEmpty() }, targetName = shortName).toString()
        nodesListPosition = nodesList.indexOfFirst { it.second == shortName }
        println(nodesListPosition)
        state = state.copy(
            mainText = mainText,
            pageTitle = shortName,
            path = path,
            rightEnabled = nodesListPosition < nodesList.size - 1,
            leftEnabled = nodesListPosition >= 0,
            selectedNodeId = nodesList[nodesListPosition].first
        )
    }

    private fun setMainText(id : String, shortName: String) {
        mainText = getTextById(id)
        path = findPathToNodeByName(root = tree.first { it.parentId.isEmpty() }, targetName = id).toString()
        nodesListPosition = nodesList.indexOfFirst { it.first == id }
        println(nodesListPosition)
        state = state.copy(
            mainText = mainText,
            pageTitle = shortName,
            path = path,
            rightEnabled = nodesListPosition < nodesList.size - 1,
            leftEnabled = nodesListPosition >= 0,
            selectedNodeId = nodesList[nodesListPosition].first
        )
    }
}
