package src.novasoft.roads.compose_client.feature.menu.team

import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import ru.novasoft.roads.compose_client.core.ui.menu.MenuPageTemplate
import ru.novasoft.roads.compose_client.core.ui.menu.PageHeaderView

@Composable
fun DepartmentsView() {
    MenuPageTemplate(
        pageName = "Отделы",
        pageHeader = { PageHeaderView(it) } ,
        pageContent = { Text(text = "Страница отделов") }
    )
}