package ru.novasoft.roads.compose_client.core.ui.table.components

import androidx.compose.foundation.layout.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Modifier
import ru.novasoft.roads.compose_client.core.ui.table.utils.PaginationBar
import ru.novasoft.roads.compose_client.core.ui.table.utils.PaginationProperties
import ru.novasoft.roads.compose_client.core.ui.table.utils.TableState

@Composable
fun BarUnderTheTable(
    modifier: Modifier = Modifier,
    state: MutableState<TableState>,
    paginationProperties: PaginationProperties,
    pageAmount: Int,
) {
    Row(
        modifier = modifier
            .height(IntrinsicSize.Min),
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        if (paginationProperties.isEnabled && state.value.showPages) {
            PaginationBar(
                state,
                pageAmount,
                paginationProperties.maxPageIndexInRow,
                Modifier.weight(2f)
            )
        }
    }
}