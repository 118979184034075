package src.novasoft.roads.compose_client.feature.menu.contract_screen

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.composable
import androidx.navigation.compose.rememberNavController
import org.kodein.di.compose.localDI
import org.kodein.di.compose.rememberDI
import org.kodein.di.compose.subDI
import org.kodein.di.instance
import ru.novasoft.roads.compose_client.core.ui.menu.MenuPageTemplate
import ru.novasoft.roads.compose_client.core.ui.menu.PageHeaderContract
import ru.novasoft.roads.compose_client.core.ui.menu.PageHeaderEvent
import ru.novasoft.roads.compose_client.core.ui.menu.PageHeaderView
import src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs.jobs.JobsDashBoardTab

@Composable
fun ContractPageView(contractId: Int) {
    subDI(
        parentDI = localDI(),
        diBuilder = { import(dashboardDIModule()) }
    ) {
        val contractNavHostController = rememberNavController()

        val contractPageViewModel: ContractPageViewModel by rememberDI { instance(arg = contractId) }
        val entries by contractPageViewModel.entries.collectAsState()
        val resources by contractPageViewModel.resources.collectAsState()
        val resourcesSheets by contractPageViewModel.resourcesSheets.collectAsState()
        val progressDetailing by contractPageViewModel.progressDetailing.collectAsState()

        val isContractDataLoaded by contractPageViewModel.isContractDataLoaded.collectAsState()

        MenuPageTemplate(
            pageName = "Контракт с id=$contractId",
            pageHeader = {
                PageHeaderView(it, object : PageHeaderContract {
                    override fun getTabs(): Map<String, () -> Unit> = DashboardTab.entries.associate { element ->
                        when (element) {
                            DashboardTab.JOBS -> element.tabName to { contractNavHostController.navigate(DashboardTab.entries[0].name) }
                            DashboardTab.RESOURCES -> element.tabName to { contractNavHostController.navigate(DashboardTab.entries[1].name) }
                            DashboardTab.FINANCES -> element.tabName to { contractNavHostController.navigate(DashboardTab.entries[2].name) }
                        }
                    }
                    override fun onPageHeaderEvent(event: PageHeaderEvent) {}
                }
                )
            },
            pageContent = {
                NavHost(
                    navController = contractNavHostController,
                    startDestination = DashboardTab.entries[0].name,
                    modifier = Modifier.fillMaxSize()
                ) {
                    composable(DashboardTab.entries[0].name) {
                        JobsDashBoardTab(
                            entries,
                            contractPageViewModel.appStateInfoRepository.getOpenedContract(),
                            resourcesSheets,
                            isContractDataLoaded,
                            progressDetailing
                        )
                    }
                    composable(DashboardTab.entries[1].name) { Text("тут пока ничего нет") }
                    composable(DashboardTab.entries[2].name) { Text("и тут") }
                }
            }
        )
    }
}