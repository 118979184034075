package ru.novasoft.roads.compose_client.core.ui.chart

import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.Dp
import ru.novasoft.roads.compose_client.core.ui.chart.models.Pie

@Composable
fun DoubleDonutChart(
    label: String = "",
    modifier: Modifier = Modifier,
    outerData: List<Pie>,
    innerData: List<Pie>,
    outerWidth: Dp,
    innerWidth: Dp
) {
    BoxWithConstraints(
        modifier = modifier,
        contentAlignment = Alignment.Center
    ) {
        val outerRadius = minOf(maxWidth, maxHeight) / 2
        val innerRadius = outerRadius - outerWidth

        // Внутренний пончик
        PieChart(
            data = innerData,
            style = Pie.Style.Stroke(width = innerWidth),
            modifier = Modifier.size(innerRadius * 2)
        )

        // Внешний пончик
        PieChart(
            data = outerData,
            style = Pie.Style.Stroke(width = outerWidth),
            modifier = Modifier.size(outerRadius * 2)
        )

        Text(
            text = label,
            modifier = Modifier.width(innerRadius - innerWidth),
            style = MaterialTheme.typography.bodyLarge,
            textAlign = TextAlign.Center,
        )
    }
}
