package ru.novasoft.roads.compose_client.core.ui.bricks

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.material3.Switch
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import ru.novasoft.roads.compose_client.core.ui.widgets.scaledSize

@Composable
fun DualSwitcher(
    swithcherState: MutableState<Boolean>,
    leftContainer: @Composable () -> Unit,
    rightContainer: @Composable () -> Unit,
) {
    Row(
        horizontalArrangement = Arrangement.spacedBy(10.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        leftContainer()
        Switch(
            checked = swithcherState.value,
            onCheckedChange = { swithcherState.value = it },
            modifier = Modifier.scaledSize(0.7f)
        )
        rightContainer()
    }
}
