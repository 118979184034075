package src.novasoft.roads.compose_client.feature.menu.contracts

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Folder
import androidx.compose.material.icons.filled.MoreVert
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.defaultPaddingCard
import ru.novasoft.roads.compose_client.core.ui.DefaultToolTip
import src.novasoft.roads.compose_client.feature.menu.contracts.ContractCardViewConstants.HEIGHT
import src.novasoft.roads.compose_client.feature.menu.contracts.ContractCardViewConstants.WIDTH

object ContractCardViewConstants {
    const val WIDTH = 400.0
    const val HEIGHT = 220.0
}

private const val DEFAULT_CARD_LABEL = "Папка"

@Composable
fun CardBase(
    title: String,
    label: String = DEFAULT_CARD_LABEL,
    imageVector: ImageVector = Icons.Filled.Folder,
    onClick: () -> Unit = {},
    modifier: Modifier = Modifier,
    actions: List<ContractsUiEvent.CardBaseAction> = emptyList(),
    onAction: (ContractsUiEvent.CardBaseAction) -> Unit = {},
    content: @Composable () -> Unit = {},
) {

    ElevatedCard(
        onClick = onClick,
        colors = CardDefaults.cardColors(containerColor = MaterialTheme.colorScheme.onPrimary),
        modifier = Modifier
            .size(width = WIDTH.dp, height = HEIGHT.dp)
            .then(modifier),
        elevation = CardDefaults.elevatedCardElevation()
    ) {

        Column(
            modifier = Modifier.padding(defaultPaddingCard),
            verticalArrangement = Arrangement.spacedBy(5.dp, Alignment.CenterVertically)
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.spacedBy(10.dp, alignment = Alignment.Start),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Card(
                    modifier = Modifier.size(60.dp),
                    shape = RoundedCornerShape(5.dp),
                    colors = CardDefaults.cardColors().copy(
                        containerColor = MaterialTheme.colorScheme.secondaryContainer,
                        contentColor = MaterialTheme.colorScheme.onSecondaryContainer
                    )
                ) {
                    DefaultToolTip(
                        text = "Тип объекта: $label",
                        condition = label != DEFAULT_CARD_LABEL
                    ) {
                        Icon(
                            imageVector,
                            null,
                            modifier = Modifier.fillMaxSize().padding(10.dp),
                        )
                    }
                }

                Column(
                    verticalArrangement = Arrangement.spacedBy(2.5.dp, alignment = Alignment.Top),
                    modifier = Modifier.width(240.dp),
                ) {
                    DefaultToolTip(
                        text = title
                    ) {
                        Text(
                            text = title,
                            style = MaterialTheme.typography.headlineSmall,
                            overflow = TextOverflow.Ellipsis,
                            maxLines = 1,
                        )
                    }
                    ContractCardPropertyLabel(label)
                }

                var expanded by remember { mutableStateOf(false) }
                Box(
                    contentAlignment = Alignment.TopCenter,
                    modifier = Modifier.size(40.dp)
                ) {
                    DefaultToolTip(
                        "Действия"
                    ) {
                        IconButton({ expanded = !expanded }) {
                            Icon(
                                Icons.Filled.MoreVert,
                                "Действия",
                                modifier = Modifier.size(20.dp),
                            )
                        }
                        DropdownMenu(
                            expanded = expanded,
                            onDismissRequest = { expanded = false },
                            modifier = Modifier.width(200.dp),
                            containerColor = MaterialTheme.colorScheme.onPrimary,
                            shadowElevation = 5.dp,
                            tonalElevation = 0.dp,
                        ) {
                            actions.forEach {
                                DropdownMenuItem(
                                    text = {
                                        Text(
                                            text = it.actionName,
                                            style = MaterialTheme.typography.bodySmall,
                                        )
                                    },
                                    onClick = {
                                        expanded = false
                                        onAction(it)
                                    },
                                    modifier = Modifier.height(30.dp),
                                )
                            }
                        }
                    }
                }
            }
            content()
        }
    }

}

@Composable
fun ContractCardPropertyLabel(text: String) {
    Card(
        colors = CardDefaults.cardColors(
            containerColor = MaterialTheme.colorScheme.surfaceVariant,
            contentColor = MaterialTheme.colorScheme.onSurface,
        ),
        shape = RoundedCornerShape(5.dp),
    ) {
        Text(
            text = text,
            modifier = Modifier.padding(5.dp),
            style = MaterialTheme.typography.bodySmall,
            textAlign = TextAlign.Center
        )
    }
}