package novasoft.roads.util

import novasoft.roads.dto.reports.report_properties.DateSliceDto

fun toDateSlice(dto: DateSliceDto) = DateSliceModel(
    dto.from,
    dto.to
)

fun toDateSliceDto(model: DateSliceModel?) = model?.let {
    DateSliceDto(
        model.from,
        model.to
    )
}