package ru.novasoft.roads.compose_client.core.ui.table.components

import androidx.compose.foundation.background
import androidx.compose.foundation.gestures.detectDragGestures
import androidx.compose.foundation.layout.*
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.PointerInputChange
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.unit.dp
import ru.novasoft.roads.compose_client.core.ui.table.mappers.TableColumn
import ru.novasoft.roads.compose_client.core.ui.table.utils.HeaderData
import ru.novasoft.roads.compose_client.core.ui.table.utils.HeaderType
import ru.novasoft.roads.compose_client.core.ui.table.utils.TableState

private val default_divider_size = 2.dp
private val min_width = 20.dp

@Composable
fun <T> HeaderWithSubheaders(
    state: MutableState<TableState>,
    tableColumns: List<TableColumn<T>>,
    headerData: List<HeaderData>,
) {
    val borderColor = MaterialTheme.colorScheme.background
    val lastBorderColor = MaterialTheme.colorScheme.primaryContainer
    Row(modifier = Modifier.fillMaxWidth().height(IntrinsicSize.Max)) {
        for (data in headerData) {
            val labelId = data.id
            val lastVisibleId = getLastVisibleId(headerData, state.value)
            when {
                data.type == HeaderType.SINGLE && labelId != -1 && state.value.labelVisibility[labelId] -> {
                    DrawSingleLabel(tableColumns, labelId, state)
                    HeaderDivider(state, data.id, if (data.id != lastVisibleId) borderColor else lastBorderColor)
                }
                data.type == HeaderType.GROUP && data.subs.find { state.value.labelVisibility[it] } != null -> {
                    DrawGroupLabels(data, state, tableColumns, borderColor)
                    val lastVisibleSubId = data.subs.last { state.value.labelVisibility[it] }
                    HeaderDivider(state, lastVisibleSubId, if (lastVisibleSubId != lastVisibleId) borderColor else lastBorderColor)
                }
            }
        }
    }
}

@Composable
private fun HeaderDivider(
    state: MutableState<TableState>,
    lastVisibleId: Int,
    borderColor: Color
) {
    VerticalDivider(
        thickness = default_divider_size,
        color = borderColor,
        modifier = Modifier.pointerInput(Unit) {
            detectDragGestures { change, dragAmount ->
                dragDivider(state, lastVisibleId, dragAmount, change)
            }
        }.pointerHoverIcon(icon = PointerIcon.Hand)
    )
}

@Composable
private fun <T> RowScope.DrawSingleLabel(
    tableColumns: List<TableColumn<T>>,
    labelId: Int,
    state: MutableState<TableState>
) {
    val column = tableColumns[labelId]
    val header = column.getHeaderComposable()
    Box(
        modifier = Modifier.Companion
            .width(state.value.labelWidths[labelId])
            .background(color = MaterialTheme.colorScheme.primaryContainer)
            .fillMaxHeight()
    ) {
        Box(modifier = Modifier.fillMaxSize()) {
            header()
        }
    }
}

@Composable
private fun <T> RowScope.DrawGroupLabels(
    data: HeaderData,
    state: MutableState<TableState>,
    tableColumns: List<TableColumn<T>>,
    borderColor: Color,
) {
    val parentName = data.name
    // Ширина, расчитанная по формуле: (сумма ширин видимых столбцов) + (размер_разделителя) * (кол-во разделителей), где кол-во разделителей - количество видимых столбцов - 1
    val calculatedWidth = data.subs.filter { state.value.labelVisibility[it] }
        .fold(0.dp) { acc, index -> acc + state.value.labelWidths[index] } + default_divider_size * (data.subs.filter { state.value.labelVisibility[it] }.size - 1)
    Box(
        modifier = Modifier
            .width(calculatedWidth)
            .fillMaxHeight()
            .background(color = MaterialTheme.colorScheme.primaryContainer)
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
        ) {
            GroupHeader(parentName)
            Row(modifier = Modifier.fillMaxWidth()) {
                data.subs.forEach { subLabel ->
                    SubHeader(tableColumns, subLabel, state, data, borderColor)
                }
            }
        }
    }
}

@Composable
private fun <T> SubHeader(
    tableColumns: List<TableColumn<T>>,
    subLabel: Int,
    state: MutableState<TableState>,
    data: HeaderData,
    borderColor: Color
) {
    val subColumn = tableColumns[subLabel]
    if (!state.value.labelVisibility[subLabel]) {
        return
    }
    val subHeader = subColumn.getHeaderComposable()
    Box(modifier = Modifier.width(state.value.labelWidths[subLabel])) {
        Box(modifier = Modifier.fillMaxWidth()) {
            subHeader()
        }
    }
    if (subLabel != data.subs.last { state.value.labelVisibility[it] }) {
        HeaderDivider(state, subLabel, borderColor)
    }
}

@Composable
private fun GroupHeader(parentName: String?) {
    Box(modifier = Modifier.fillMaxWidth()) {
        if (parentName != null) {
            Text(
                parentName,
                color = MaterialTheme.colorScheme.onPrimaryContainer,
                modifier = Modifier.align(Alignment.TopCenter)
            )
        }
    }
}

private fun dragDivider(
    state: MutableState<TableState>,
    subLabel: Int,
    dragAmount: Offset,
    change: PointerInputChange
) {
    val updatedWidths = state.value.labelWidths.toMutableList()
    when {
        updatedWidths[subLabel] > min_width -> {
            updatedWidths[subLabel] += dragAmount.x.dp
        }

        updatedWidths[subLabel] <= min_width && dragAmount.x.dp >= 0.dp -> {
            updatedWidths[subLabel] += dragAmount.x.dp
        }
    }
    state.value = state.value.copy(labelWidths = updatedWidths)
    change.consume()
}

private fun getLastVisibleId(headers: List<HeaderData>, state: TableState): Int {
    var lastVisibleId: Int = -1
    for (header in headers) {
        if (header.type == HeaderType.SINGLE) {
            if (state.labelVisibility[header.id]) {
                lastVisibleId = header.id
            }
        } else {
            if (header.subs.lastOrNull { state.labelVisibility[it] } != null) {
                lastVisibleId = header.subs.last { state.labelVisibility[it] }
            }
        }
    }
    return lastVisibleId
}