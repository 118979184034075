package ru.novasoft.roads.compose_client.core.ui.chart

import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.AnimationVector1D
import androidx.compose.animation.core.tween
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.gestures.detectDragGestures
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.text.BasicText
import androidx.compose.runtime.*
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.*
import androidx.compose.ui.graphics.*
import androidx.compose.ui.graphics.drawscope.DrawScope
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.input.pointer.PointerInputChange
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.text.TextMeasurer
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.drawText
import androidx.compose.ui.text.rememberTextMeasurer
import androidx.compose.ui.unit.*
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import ru.novasoft.roads.compose_client.core.ui.chart.components.LabelHelper
import ru.novasoft.roads.compose_client.core.ui.chart.components.LabelShape
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.drawGridLines
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.line_chart.drawLineGradient
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.line_chart.getLinePath
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.line_chart.getPopupValue
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.spaceBetween
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.split
import ru.novasoft.roads.compose_client.core.ui.chart.models.*
import ru.novasoft.roads.compose_client.core.ui.chart.utils.calculateOffset

data class Popup(
    val properties: PopupProperties,
    val position: Offset,
    val value: String
)

@Composable
private fun Indicators(
    modifier: Modifier = Modifier,
    indicatorProperties: HorizontalIndicatorProperties,
    minValue: Double,
    maxValue: Double
) {
    Column(
        modifier = modifier
            .fillMaxHeight(),
        verticalArrangement = Arrangement.SpaceBetween
    ) {
        split(
            count = indicatorProperties.count,
            minValue = minValue,
            maxValue = maxValue
        ).forEach {
            BasicText(
                text = indicatorProperties.contentBuilder(it),
                style = indicatorProperties.textStyle
            )
        }
    }
}

private fun DrawScope.drawPopup(
    popup: Popup,
    nextPopup: Popup?,
    textMeasurer: TextMeasurer,
    scope: CoroutineScope,
    progress: Float,
    offsetAnimator: Pair<Animatable<Float, AnimationVector1D>, Animatable<Float, AnimationVector1D>>? = null,
) {
    val offset = popup.position
    val popupProperties = popup.properties
    val measureResult = textMeasurer.measure(
        popup.value,
        style = popupProperties.textStyle.copy(
            color = popupProperties.textStyle.color.copy(
                alpha = 1f * progress
            )
        )
    )
    var rectSize = measureResult.size.toSize()
    rectSize = rectSize.copy(
        width = (rectSize.width + (popupProperties.contentHorizontalPadding.toPx() * 2)),
        height = (rectSize.height + (popupProperties.contentVerticalPadding.toPx() * 2))
    )

    val conflictDetected =
        ((nextPopup != null) && offset.y in nextPopup.position.y - rectSize.height..nextPopup.position.y + rectSize.height) ||
                (offset.x + rectSize.width) > size.width


    val rectOffset = if (conflictDetected) {
        offset.copy(x = offset.x - rectSize.width)
    } else {
        offset
    }
    offsetAnimator?.also { (x, y) ->
        if (x.value == 0f || y.value == 0f) {
            scope.launch {
                x.snapTo(rectOffset.x)
                y.snapTo(rectOffset.y)
            }
        } else {
            scope.launch {
                x.animateTo(rectOffset.x)
            }
            scope.launch {
                y.animateTo(rectOffset.y)
            }
        }

    }
    if (offsetAnimator != null) {
        val animatedOffset = Offset(
            x = offsetAnimator.first.value,
            y = offsetAnimator.second.value
        )
        val rect = Rect(
            offset = animatedOffset,
            size = rectSize
        )
        drawPath(
            path = Path().apply {
                addRoundRect(
                    RoundRect(
                        rect = rect.copy(
                            top = rect.top,
                            left = rect.left,
                        ),
                        topLeft = CornerRadius(
                            if (conflictDetected) popupProperties.cornerRadius.toPx() else 0f,
                            if (conflictDetected) popupProperties.cornerRadius.toPx() else 0f
                        ),
                        topRight = CornerRadius(
                            if (!conflictDetected) popupProperties.cornerRadius.toPx() else 0f,
                            if (!conflictDetected) popupProperties.cornerRadius.toPx() else 0f
                        ),
                        bottomRight = CornerRadius(
                            popupProperties.cornerRadius.toPx(),
                            popupProperties.cornerRadius.toPx()
                        ),
                        bottomLeft = CornerRadius(
                            popupProperties.cornerRadius.toPx(),
                            popupProperties.cornerRadius.toPx()
                        ),
                    )
                )
            },
            color = popupProperties.containerColor,
            alpha = 1f * progress
        )
        drawText(
            textLayoutResult = measureResult,
            topLeft = animatedOffset.copy(
                x = animatedOffset.x + popupProperties.contentHorizontalPadding.toPx(),
                y = animatedOffset.y + popupProperties.contentVerticalPadding.toPx()
            )
        )
    }
}

fun DrawScope.drawDots(
    dataPoints: List<Pair<Animatable<Float, AnimationVector1D>, Float>>,
    properties: DotProperties,
    linePath: Path,
    maxValue: Float,
    minValue: Float,
    pathMeasure: PathMeasure,
    scope: CoroutineScope,
    size: Size? = null,
    placeToCenterPoint: Boolean,
) {
    val _size = size ?: this.size

    val pathEffect = properties.strokeStyle.pathEffect

    pathMeasure.setPath(linePath, false)
    val lastPosition = pathMeasure.getPosition(pathMeasure.length)
    dataPoints.forEachIndexed { valueIndex, value ->
        val dotOffset = Offset(
            x = if (placeToCenterPoint) (valueIndex + 0.5f) * (_size.width / dataPoints.size)
            else _size.width.spaceBetween(
                itemCount = dataPoints.count(),
                index = valueIndex
            ),
            y = (_size.height - calculateOffset(
                maxValue = maxValue.toDouble(),
                minValue = minValue.toDouble(),
                total = _size.height,
                value = value.second
            )).toFloat()

        )
        if (lastPosition != Offset.Unspecified && lastPosition.x >= dotOffset.x - 20 || !properties.animationEnabled) {
            if (!value.first.isRunning && properties.animationEnabled && value.first.value != 1f) {
                scope.launch {
                    value.first.animateTo(1f, animationSpec = properties.animationSpec)
                }
            }

            val radius: Float
            val strokeRadius: Float
            if (properties.animationEnabled) {
                radius =
                    (properties.radius.toPx() + properties.strokeWidth.toPx() / 2) * value.first.value
                strokeRadius = properties.radius.toPx() * value.first.value
            } else {
                radius = properties.radius.toPx() + properties.strokeWidth.toPx() / 2
                strokeRadius = properties.radius.toPx()
            }
            drawCircle(
                brush = properties.strokeColor,
                radius = radius,
                center = dotOffset,
                style = Stroke(width = properties.strokeWidth.toPx(), pathEffect = pathEffect),
            )
            drawCircle(
                brush = properties.color,
                radius = strokeRadius,
                center = dotOffset,
            )
        }
    }
}


@Composable
fun LineChart(
    modifier: Modifier = Modifier,
    data: List<Line>,
    curvedEdges: Boolean = true,
    animationDelay: Long = 300,
    animationMode: AnimationMode = AnimationMode.Together(),
    dividerProperties: DividerProperties = DividerProperties(),
    gridProperties: GridProperties = GridProperties(),
    zeroLineProperties: ZeroLineProperties = ZeroLineProperties(),
    indicatorProperties: HorizontalIndicatorProperties = HorizontalIndicatorProperties(
        textStyle = TextStyle.Default,
        padding = 16.dp
    ),
    labelHelperProperties: LabelHelperProperties = LabelHelperProperties(),
    labelHelperPadding: Dp = 26.dp,
    textMeasurer: TextMeasurer = rememberTextMeasurer(),
    popupProperties: PopupProperties = PopupProperties(
        textStyle = TextStyle.Default.copy(
            color = Color.White,
            fontSize = 12.sp
        )
    ),
    dotsProperties: DotProperties = DotProperties(),
    labelProperties: LabelProperties = LabelProperties(enabled = false),
    maxValue: Double = data.maxOfOrNull { it.values.maxOfOrNull { it } ?: 0.0 } ?: 0.0,
    minValue: Double = if (data.any { it.values.any { it < 0.0 } }) data.minOfOrNull {
        it.values.minOfOrNull { it } ?: 0.0
    } ?: 0.0 else 0.0,
    placeToCenterPoint: Boolean = false
) {
    data.validateData(maxValue, minValue)

    val variables = LineChartVariables(
        data,
        labelProperties,
        textMeasurer,
        zeroLineProperties,
        animationDelay,
        animationMode,
        popupProperties,
        curvedEdges,
        maxValue,
        minValue,
        dividerProperties,
        indicatorProperties,
        gridProperties,
        dotsProperties,
        placeToCenterPoint
    ).apply { initVariables() }

    CompositionLocalProvider(LocalLayoutDirection provides LayoutDirection.Ltr) {
        Column(modifier = modifier) {
            if (labelHelperProperties.enabled) {
                //Создание верхней легенды с подписями
                LabelHelper(
                    data = data.map { Triple(it.label, it.color, LabelShape.CIRCLE) },
                    textStyle = labelHelperProperties.textStyle
                )
                Spacer(modifier = Modifier.height(labelHelperPadding))
            }
            Row(modifier = Modifier.fillMaxSize()) {
                val paddingBottom = (variables.labelAreaHeight / variables.density.density).dp
                //Подписи по оси y
                drawIndicators(variables, true, paddingBottom)
                Canvas(
                    modifier = Modifier
                        .getModifierWithGestures(variables)
                        .weight(1f)
                        .fillMaxSize(),
                    onDrawForCanvas(variables)
                )
                drawIndicators(variables, false, paddingBottom)
            }
        }
    }
}

/** Организация управления и переменных состояния для линейного графика */
class LineChartVariables(
    val data: List<Line>,
    val labelProperties: LabelProperties,
    val textMeasurer: TextMeasurer,
    val zeroLineProperties: ZeroLineProperties,
    val animationDelay: Long,
    val animationMode: AnimationMode,
    val popupProperties: PopupProperties,
    val curvedEdges: Boolean,
    val maxValue: Double,
    val minValue: Double,
    val dividerProperties: DividerProperties,
    val indicatorProperties: HorizontalIndicatorProperties,
    val gridProperties: GridProperties,
    val dotsProperties: DotProperties,
    val placeToCenterPoint: Boolean,
) {
    lateinit var density: Density
    lateinit var scope: CoroutineScope
    lateinit var pathMeasure: PathMeasure
    lateinit var popupAnimation: Animatable<Float, AnimationVector1D>
    lateinit var zeroLineAnimation: Animatable<Float, AnimationVector1D>
    lateinit var dotAnimators: SnapshotStateList<List<Animatable<Float, AnimationVector1D>>>
    lateinit var popups: SnapshotStateList<Popup>
    lateinit var popupsOffsetAnimators: SnapshotStateList<Pair<Animatable<Float, AnimationVector1D>, Animatable<Float, AnimationVector1D>>>
    var labelAreaHeight: Int = 0

    @Composable
    fun initVariables() {
        density = LocalDensity.current
        scope = rememberCoroutineScope()

        pathMeasure = remember {
            PathMeasure()
        }

        popupAnimation = remember {
            Animatable(0f)
        }

        zeroLineAnimation = remember {
            Animatable(0f)
        }

        dotAnimators = remember {
            mutableStateListOf<List<Animatable<Float, AnimationVector1D>>>()
        }
        popups = remember {
            mutableStateListOf<Popup>()
        }
        popupsOffsetAnimators = remember {
            mutableStateListOf<Pair<Animatable<Float, AnimationVector1D>, Animatable<Float, AnimationVector1D>>>()
        }
        labelAreaHeight = remember {
            if (labelProperties.enabled) {
                if (labelProperties.labels.isNotEmpty()) {
                    labelProperties.labels.maxOf {
                        textMeasurer.measure(
                            it,
                            style = labelProperties.textStyle
                        ).size.height
                    } + (labelProperties.padding.value * density.density).toInt()
                } else {
                    error("Labels enabled, but there is no label provided to show, disable labels or fill 'labels' parameter in LabelProperties")
                }
            } else {
                0
            }
        }



        LaunchedEffect(Unit) {
            if (zeroLineProperties.enabled) {
                zeroLineAnimation.snapTo(0f)
                zeroLineAnimation.animateTo(1f, animationSpec = zeroLineProperties.animationSpec)
            }
        }

        LaunchedEffect(data) {
            dotAnimators.clear()
            launch {
                data.forEach {
                    val animators = mutableListOf<Animatable<Float, AnimationVector1D>>()
                    it.values.forEach {
                        animators.add(Animatable(0f))
                    }
                    dotAnimators.add(animators)
                }
            }
        }

        LaunchedEffect(data) {
            delay(animationDelay)

            val animateStroke: suspend (ru.novasoft.roads.compose_client.core.ui.chart.models.Line) -> Unit = { line ->
                line.strokeProgress.animateTo(1f, animationSpec = line.strokeAnimationSpec)
            }
            val animateGradient: suspend (ru.novasoft.roads.compose_client.core.ui.chart.models.Line) -> Unit =
                { line ->
                    delay(line.gradientAnimationDelay)
                    line.gradientProgress.animateTo(1f, animationSpec = line.gradientAnimationSpec)
                }
            launch {
                data.forEachIndexed { index, line ->
                    when (animationMode) {
                        is AnimationMode.OneByOne -> {
                            animateStroke(line)
                        }

                        is AnimationMode.Together -> {
                            launch {
                                delay(animationMode.delayBuilder(index))
                                animateStroke(line)
                            }
                        }
                    }
                }
            }
            launch {
                data.forEachIndexed { index, line ->
                    when (animationMode) {
                        is AnimationMode.OneByOne -> {
                            animateGradient(line)
                        }

                        is AnimationMode.Together -> {
                            launch {
                                delay(animationMode.delayBuilder(index))
                                animateGradient(line)
                            }
                        }
                    }
                }
            }
        }
    }

    fun onDragEnd(): () -> Unit = {
        scope.launch {
            popupAnimation.animateTo(0f, animationSpec = tween(500))
            popups.clear()
            popupsOffsetAnimators.clear()
        }
    }

    fun onDrag(): (PointerInputChange, Offset, IntSize) -> Unit =
        { change, amount, size ->
            updateDragPopups(change, size)
            repaintPopups()
        }

    fun updateDragPopups(
        change: PointerInputChange,
        size: IntSize,
        contentBuilder: (Double) -> String = { indicatorProperties.contentBuilder(it) }
    ) {
        val _size = size.toSize()
            .copy(height = (size.height - labelAreaHeight).toFloat())
        popups.clear()
        data.forEach {
            val properties = it.popupProperties ?: popupProperties

            if (properties.enabled) {
                val positionX =
                    (change.position.x).coerceIn(0f, size.width.toFloat())
                val fraction = (positionX / size.width)
                val popupValue = getPopupValue(
                    points = it.values,
                    fraction = fraction.toDouble(),
                    rounded = it.curvedEdges ?: curvedEdges,
                    size = _size,
                    minValue = minValue,
                    maxValue = maxValue
                )
                popups.add(
                    Popup(
                        position = popupValue.offset,
                        value = contentBuilder(popupValue.calculatedValue),
                        properties = properties
                    )
                )
                // add popup offset animators
                if (popupsOffsetAnimators.count() < popups.count()) {
                    repeat(popups.count() - popupsOffsetAnimators.count()) {
                        popupsOffsetAnimators.add(
                            Animatable(0f) to Animatable(
                                0f
                            )
                        )
                    }
                }
            }
        }
    }

    fun repaintPopups() {
        scope.launch {
            // animate popup (alpha)
            if (popupAnimation.value != 1f && !popupAnimation.isRunning) {
                popupAnimation.animateTo(1f, animationSpec = tween(500))
            }
        }
    }
}

@Composable
private fun drawIndicators(variables: LineChartVariables, isStart: Boolean, paddingBottom: Dp) {
    val targetPosition =
        if (isStart) IndicatorPosition.Horizontal.Start
        else IndicatorPosition.Horizontal.End
    if (variables.indicatorProperties.enabled) {
        if (variables.indicatorProperties.position == targetPosition) {
            Indicators(
                modifier = Modifier.padding(bottom = paddingBottom),
                indicatorProperties = variables.indicatorProperties,
                minValue = variables.minValue,
                maxValue = variables.maxValue
            )
            Spacer(modifier = Modifier.width(variables.indicatorProperties.padding))
        }
    }
}


/** Рисование основного блока линейного графика */
fun onDrawForCanvas(variables: LineChartVariables): DrawScope.() -> Unit = {
    val chartAreaHeight = size.height - variables.labelAreaHeight
    val drawZeroLine = {
        val zeroY = chartAreaHeight - calculateOffset(
            minValue = variables.minValue,
            maxValue = variables.maxValue,
            total = chartAreaHeight,
            value = 0f
        ).toFloat()
        drawLine(
            brush = variables.zeroLineProperties.color,
            start = Offset(x = 0f, y = zeroY),
            end = Offset(x = size.width * variables.zeroLineAnimation.value, y = zeroY),
            pathEffect = variables.zeroLineProperties.style.pathEffect,
            strokeWidth = variables.zeroLineProperties.thickness.toPx()
        )
    }

    if (variables.labelProperties.enabled) {
        variables.labelProperties.labels.forEachIndexed { index, label ->
            val measureResult =
                variables.textMeasurer.measure(label, style = variables.labelProperties.textStyle)
            drawText(
                textLayoutResult = measureResult,
                topLeft = Offset(
                    (size.width - measureResult.size.width).spaceBetween(
                        itemCount = variables.labelProperties.labels.count(),
                        index = index
                    ),
                    size.height - variables.labelAreaHeight + variables.labelProperties.padding.toPx()
                )
            )
        }
    }

    drawGridLines(
        dividersProperties = variables.dividerProperties,
        indicatorPosition = variables.indicatorProperties.position,
        xAxisProperties = variables.gridProperties.xAxisProperties,
        yAxisProperties = variables.gridProperties.yAxisProperties,
        size = size.copy(height = chartAreaHeight),
        gridEnabled = variables.gridProperties.enabled
    )
    if (variables.zeroLineProperties.enabled && variables.zeroLineProperties.zType == ZeroLineProperties.ZType.Under) {
        drawZeroLine()
    }
    variables.data.forEachIndexed { index, line ->
        val path = getLinePath(
            dataPoints = line.values.map { it.toFloat() },
            maxValue = variables.maxValue.toFloat(),
            minValue = variables.minValue.toFloat(),
            rounded = line.curvedEdges ?: variables.curvedEdges,
            size = size.copy(height = chartAreaHeight),
            placeToCenterPoint = variables.placeToCenterPoint
        )
        val segmentedPath = Path()
        variables.pathMeasure.setPath(path, false)
        variables.pathMeasure.getSegment(
            0f,
            variables.pathMeasure.length * line.strokeProgress.value,
            segmentedPath
        )
        var pathEffect: PathEffect? = null
        val stroke: Float = when (val drawStyle = line.drawStyle) {
            is DrawStyle.Fill -> {
                0f
            }

            is DrawStyle.Stroke -> {
                pathEffect = drawStyle.strokeStyle.pathEffect
                drawStyle.width.toPx()
            }
        }
        drawPath(
            path = segmentedPath,
            brush = line.color,
            style = Stroke(width = stroke, pathEffect = pathEffect)
        )
        if (line.firstGradientFillColor != null && line.secondGradientFillColor != null) {
            drawLineGradient(
                path = path,
                color1 = line.firstGradientFillColor,
                color2 = line.secondGradientFillColor,
                progress = line.gradientProgress.value,
                size = size.copy(height = chartAreaHeight)
            )
        } else if (line.drawStyle is DrawStyle.Fill) {
            var fillColor = Color.Unspecified
            if (line.color is SolidColor) {
                fillColor = line.color.value
            }
            drawLineGradient(
                path = path,
                color1 = fillColor,
                color2 = fillColor,
                progress = 1f,
                size = size.copy(height = chartAreaHeight)
            )
        }

        if ((line.dotProperties?.enabled ?: variables.dotsProperties.enabled)) {
            drawDots(
                dataPoints = line.values.mapIndexed { mapIndex, value ->
                    (variables.dotAnimators.getOrNull(
                        index
                    )?.getOrNull(mapIndex) ?: Animatable(0f)) to value.toFloat()
                },
                properties = line.dotProperties ?: variables.dotsProperties,
                linePath = segmentedPath,
                maxValue = variables.maxValue.toFloat(),
                minValue = variables.minValue.toFloat(),
                pathMeasure = variables.pathMeasure,
                scope = variables.scope,
                size = size.copy(height = chartAreaHeight),
                variables.placeToCenterPoint
            )
        }
    }
    if (variables.zeroLineProperties.enabled && variables.zeroLineProperties.zType == ZeroLineProperties.ZType.Above) {
        drawZeroLine()
    }
    variables.popups.forEachIndexed { index, popup ->
        drawPopup(
            popup = popup,
            nextPopup = variables.popups.getOrNull(index + 1),
            textMeasurer = variables.textMeasurer,
            scope = variables.scope,
            progress = variables.popupAnimation.value,
            offsetAnimator = variables.popupsOffsetAnimators.getOrNull(index)
        )
    }
}


/** Проверка условий для входных данных линейного графика */
@Composable
fun List<Line>.validateData(maxValue: Double, minValue: Double) {
    if (this.any { it.values.isEmpty() }) return
    if (this.isNotEmpty()) {
        require(minValue <= this.minOf { it.values.minOf { it } }) {
            "Chart data must be at least $minValue (Specified Min Value)"
        }
        require(maxValue >= this.maxOf { it.values.maxOf { it } }) {
            "Chart data must be at most $maxValue (Specified Max Value)"
        }
    }
}

@Composable
fun Modifier.getModifierWithGestures(
    variables: LineChartVariables
): Modifier =
    this.pointerInput(variables.data) {
        detectDragGestures(
            onDragEnd = variables.onDragEnd(),
            onDrag = { change: PointerInputChange, dragAmount: Offset ->
                variables.onDrag().invoke(change, dragAmount, size)
            }
        )
    }