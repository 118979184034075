package src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs.jobs

import common.RoadsUiEvent
import common.RoadsViewModel
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import kotlinx.datetime.minus
import novasoft.roads.dto.meter_progress.*
import novasoft.roads.dto.plan.res_sheet_history.WorkTypeHistoryDto
import novasoft.roads.dto.svor.ContractProblemsDto
import novasoft.roads.dto.svor.SvorEntryDto
import novasoft.roads.dto.svor.WorkAmountDto
import novasoft.roads.util.DateSliceModel
import novasoft.roads.util.DateUtils
import novasoft.roads.util.calculations.IResourceAmountCalculator
import novasoft.roads.util.getMainUnitAbbreviation
import novasoft.roads.util.toLocalDate
import ru.novasoft.roads.compose_client.core.model.model.AmountAndTotalCost
import ru.novasoft.roads.compose_client.core.model.model.table.RRTableRow
import ru.novasoft.roads.compose_client.core.model.model.table.TableRowType
import ru.novasoft.roads.compose_client.core.network.api.analytic.IAnalyticPageApi
import ru.novasoft.roads.compose_client.core.network.api.progress.IProgressApi
import ru.novasoft.roads.compose_client.core.ui.chart.speedometer.RRData
import ru.novasoft.roads.compose_client.core.ui.table.TreeNode
import ru.novasoft.roads.compose_client.core.ui.widgets.ForecastStatusCardData
import src.novasoft.roads.compose_client.feature.menu.contract_screen.ContractForecastData
import src.novasoft.roads.compose_client.feature.menu.contract_screen.ContractRunRateData
import src.novasoft.roads.compose_client.feature.menu.contract_screen.IsNotJobsUiEvent
import src.novasoft.roads.compose_client.feature.menu.contract_screen.JobsUiEvent
import src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs.ProgressDetailing
import kotlin.math.roundToInt
import kotlin.math.roundToLong

const val RUBLES_ABBREVIATION = "руб"

class JobsDashBoardViewModel(
    private val progressApi: IProgressApi,
    private val analyticPageApi: IAnalyticPageApi,
    private val resourceAmountCalculator: IResourceAmountCalculator,
    private val contractId: Int
) : RoadsViewModel() {

    private val _isJobsTabDataLoaded = MutableStateFlow(false)
    val isJobsTabDataLoaded = _isJobsTabDataLoaded.asStateFlow()

    private val _isWtDonePartsLoaded = MutableStateFlow(false)
    val isWtDonePartsLoaded = _isWtDonePartsLoaded.asStateFlow()

    private val _isTPAndPrepLoaded = MutableStateFlow(false)
    val isTPAndPrepLoaded = _isTPAndPrepLoaded.asStateFlow()

    private val _isStatusesLoaded = MutableStateFlow(false)
    val isStatusesLoaded = _isStatusesLoaded.asStateFlow()

    private val _isPicketDatLoaded = MutableStateFlow(false)
    val isPicketDatLoaded = _isPicketDatLoaded.asStateFlow()

    private val _wtDoneParts = MutableStateFlow<List<WorkTypeProgressValuesDto>>(emptyList())
    val wtDoneParts = _wtDoneParts.asStateFlow()

    private val _picketsData = MutableStateFlow<PicketProgressCharDataDto?>(null)
    val picketsData = _picketsData.asStateFlow()

    private val _prepayment = MutableStateFlow<Long>(0L)
    val prepayment = _prepayment.asStateFlow()

    private val _totalProgress = MutableStateFlow<ProgressDto?>(null)
    val totalProgress = _totalProgress.asStateFlow()

    private val _contractProblems = MutableStateFlow<ContractProblemsDto>(ContractProblemsDto())
    val contractProblems = _contractProblems.asStateFlow()

    private val _entriesStatus = MutableStateFlow<List<DashBoardSvorEntryStatusDto>>(emptyList())
    val entriesStatus = _entriesStatus.asStateFlow()

    private val _rrRows = MutableStateFlow<TreeNode<RRTableRow>?>(null)
    val rrRows = _rrRows.asStateFlow()

    private val _rrData = MutableStateFlow<RRData?>(null)
    val rrData = _rrData.asStateFlow()

    private val _title = MutableStateFlow<String?>(null)
    val title = _title.asStateFlow()

    private val _cardData = MutableStateFlow<ForecastStatusCardData?>(null)
    val cardData = _cardData.asStateFlow()

    init { updateAllData() }

    override fun onEvent(uiEvent: RoadsUiEvent) {
        if (uiEvent !is JobsUiEvent) throw IsNotJobsUiEvent()

        when (uiEvent) {
            is JobsUiEvent.RunRateDataReceived -> emitRRData(calcRunRate(uiEvent.dataForCalcRR))

            is JobsUiEvent.ForecastDataReceived -> emitForecastData(
                calcForecastData(
                    uiEvent.detailing,
                    uiEvent.svorEntries,
                    uiEvent.svorWorth
                )
            )
        }
    }

    fun updateAllData() {
        viewModelScope.launch {
            _isWtDonePartsLoaded.emit(false)
            _isStatusesLoaded.emit(false)
            _isPicketDatLoaded.emit(false)
            _isTPAndPrepLoaded.emit(false)
            _isJobsTabDataLoaded.emit(false)

            val jobs = listOf(
                emitWtDoneParts(),
                emitPicketsData(),
                emitPrepayment(),
                emitTotalProgress(),
                emitContractProblems(),
                emitEntriesStatus()
            )
            launch {
                jobs[0].await()
                _isWtDonePartsLoaded.emit(true)
            }
            launch {
                jobs[1].await()
                _isPicketDatLoaded.emit(true)
            }
            launch {
                listOf(jobs[3], jobs[2]).awaitAll()
                _isTPAndPrepLoaded.emit(true)
            }
            launch {
                jobs[5].await()
                _isStatusesLoaded.emit(true)
            }
            launch {
                jobs.awaitAll()
                _isJobsTabDataLoaded.emit(true)
            }
        }
    }
    private fun emitWtDoneParts() = viewModelScope.async {
        _wtDoneParts.emit(progressApi.getProgressTripleForSvorWorkTypes(contractId).await())
    }
    private fun emitPicketsData() = viewModelScope.async {
        _picketsData.emit(analyticPageApi.getPicketProgress(contractId).await())
    }
    private fun emitPrepayment() = viewModelScope.async {
        _prepayment.emit(analyticPageApi.getContractTotalPrepayment(contractId).await())
    }
    private fun emitTotalProgress() = viewModelScope.async {
        _totalProgress.emit(analyticPageApi.getContractTotalProgress(contractId).await())
    }
    private fun emitContractProblems() = viewModelScope.async {
        _contractProblems.emit(analyticPageApi.getContractErrorStatus(contractId).await())
    }
    private fun emitEntriesStatus() = viewModelScope.async {
        _entriesStatus.emit(analyticPageApi.getSvorEntriesDashBoardStatus(contractId).await())
    }

    private fun emitRRData(root: TreeNode<RRTableRow>) = viewModelScope.launch{
        _rrRows.emit(root)
        _rrData.emit(calcSumRRData(root))
    }

    private fun emitForecastData(data: ContractForecastData) = viewModelScope.launch{
        _title.emit(data.title)
        _cardData.emit(data.data)
    }

    private fun calcRunRate(
        data: ContractRunRateData
    ): TreeNode<RRTableRow> {
        val nowUTCMS = DateUtils.nowUTCMS()
        val beforeSlice = DateSliceModel(0, nowUTCMS)
        val afterSlice = DateSliceModel(nowUTCMS, Long.MAX_VALUE)

        val wtItems = data.actualSheet.workTypesResUsage
            .map { it to it.entriesIds.mapNotNull { id -> data.svorEntries[id] } }
            .filter { it.second.isNotEmpty() }
            .sortedBy { it.second.minOfOrNull { e -> e.position } ?: Int.MAX_VALUE }
            .map { (wt: WorkTypeHistoryDto, entries: List<SvorEntryDto>) ->
                val todayPlanCoef = resourceAmountCalculator.calculateVolumeCoefFromPlansInPeriod(
                    wt.globalPlans,
                    beforeSlice
                )
                val leftPlanCoef = resourceAmountCalculator.calculateVolumeCoefFromPlansInPeriod(
                    wt.globalPlans,
                    afterSlice
                )
                val wtDone = data.wtDoneParts[wt.workTypeId] ?: 0
                val runRate = wtDone + (leftPlanCoef * 100).roundToInt()

                // Объемы у ВРК выставляем по самой дорогой работе СВОР
                val mostExpensiveEntryId = entries.maxByOrNull { it.worth ?: 0.0 }?.id
                var wtPlanTotal: AmountAndTotalCost? = null
                var wtPlanTD: AmountAndTotalCost? = null
                var wtUnit: String? = null

                val childItems = entries.mapNotNull { e ->
                    val planTotal = e.workAmounts.find { it.unit.id == e.mainUnit } ?: return@mapNotNull null
                    val totalAmountAndCost = AmountAndTotalCost(planTotal.workAmount, e.worth.toLong())
                    val planTD = WorkAmountDto(planTotal.workAmount * todayPlanCoef, planTotal.unit)
                    val planTDAmountAndCost = AmountAndTotalCost(
                        planTD.workAmount,
                        (totalAmountAndCost.totalCost * todayPlanCoef).roundToLong()
                    )
                    val mainUnit = getMainUnitAbbreviation(e.workAmounts, e.mainUnit)
                    val entry = TreeNode(
                        RRTableRow(
                            e.workName,
                            totalAmountAndCost,
                            planTDAmountAndCost,
                            mainUnit,
                            wtDone,
                            runRate,
                            TableRowType.REGULAR
                        )
                    )

                    if (e.id == mostExpensiveEntryId) {
                        wtPlanTotal = totalAmountAndCost
                        wtPlanTD = planTDAmountAndCost
                        wtUnit = mainUnit
                    }
                    entry
                }

                TreeNode(
                    RRTableRow(
                        wt.workTypeName,
                        wtPlanTotal,
                        wtPlanTD,
                        wtUnit ?: "",
                        fact = wtDone,
                        runRateValue = runRate,
                        rowType = TableRowType.SUB_CHAPTER1
                    ),
                    childItems
                )

            }
        val root = TreeNode(RRTableRow(rowType = TableRowType.MAIN_CHAPTER), wtItems)


        return root
    }

    /** Получение показателей "по плану всего", "по плану на сегодня" и "по факту" в рублях */
    private fun calcSumRRData(root: TreeNode<RRTableRow>): RRData {
        val data = root.children.map { it.content }
        val planTotal = data.sumOf { it.getTotalPlanCost() ?: 0 }.toDouble()
        val planTD = data.sumOf { it.getTDPlanCost() ?: 0 }.toDouble()
        val fact = data.sumOf { (it.getTotalPlanCost() ?: 0) * (it.getFactCost() ?: 0) / 100 }.toDouble()

        return RRData(planTotal, planTD, fact)
    }

    private fun calcForecastData(
        detailing: ProgressDetailing? = null,
        svorEntries: List<SvorEntryDto>,
        svorWorth: Long,
    ): ContractForecastData {
        val statuses = entriesStatus.value.associateBy { it.entryId }

        /* Детализация не выбрана -> общий статус контракта*/
        if (detailing == null) {
            val entries = svorEntries.associateBy { it.id }

            /* Складываем все в разрезе по месяцам */
            val monthsMap = statuses.values
                .flatMap {
                    it.months.mapValues { (_, v) ->
                        val entryCost = entries[it.entryId]!!.worth
                        PlanTDnFactDto(v.planTD * entryCost, v.fact * entryCost)
                    }.toList()
                }
                .groupBy { it.first }
                .mapValues { (_, v) ->
                    /* Здесь важно поделить на суммарную стоимость, т.к. внутри умножается на нее */
                    PlanTDnFactDto(
                        (v.sumOf { it.second.planTD } / svorWorth).let { if (it.isNaN()) 0.0 else it },
                        (v.sumOf { it.second.fact } / svorWorth).let { if (it.isNaN()) 0.0 else it }
                    )
                }

            val totalData = ForecastStatusCardData(
                AmountAndTotalCost(svorWorth.toDouble(), svorWorth),
                RUBLES_ABBREVIATION,
                monthsMap
            )

            return ContractForecastData("Общий статус объекта", totalData)
        }

        val targetEntries = detailing.getTargetEntries()
        val targetById = targetEntries.associateBy { it.id }
        val targetEntriesSize = targetEntries.size

        if (targetEntriesSize > 8) {
            val summaryCost = targetEntries.sumOf { it.worth }

            val summaryMap = statuses
                .filter { targetById.containsKey(it.key) }
                .values
                .flatMap {
                    it.months.mapValues { (_, v) ->
                        val entryCost = targetById[it.entryId]!!.worth
                        PlanTDnFactDto(v.planTD * entryCost, v.fact * entryCost)
                    }.toList()
                }
                .groupBy { it.first }
                .mapValues { (_, v) ->
                    /* Здесь важно поделить на суммарную стоимость, т.к. внутри умножается на нее */
                    PlanTDnFactDto(
                        v.sumOf { it.second.planTD } / summaryCost,
                        v.sumOf { it.second.fact } / summaryCost
                    )
                }
            val summary =
                ForecastStatusCardData(
                    AmountAndTotalCost(summaryCost, summaryCost.toLong()),
                    RUBLES_ABBREVIATION,
                    summaryMap
                )

            return ContractForecastData(detailing.title, summary)
        }

        val elseResult = targetEntries.map { entry ->
            val mainWA = entry.workAmounts.find { it.unit.id == entry.mainUnit }
            val status = statuses[entry.id]

            val daysDelta = status?.let {
                if (status.forecastDateOfFinish == 0L || status.planDateOfFinish == 0L)
                    return@let null

                val forecastLocalDate = status.forecastDateOfFinish.toLocalDate()
                val planLocalDate = status.planDateOfFinish.toLocalDate()
                forecastLocalDate.minus(planLocalDate).days
            }

            val cardData = ForecastStatusCardData(
                AmountAndTotalCost(
                    mainWA?.workAmount ?: 0.0,
                    entry.worth.roundToLong()
                ),
                mainWA?.unit?.abbreviation ?: "",
                status?.months ?: emptyMap(),
                forecast = daysDelta
            )

            ContractForecastData(entry.workName, cardData)
        }

        //TODO(DENIS:убрать .first(), когда разберусь для чего тут список возвращается)
        return elseResult.first()
    }
}
