package ru.novasoft.roads.compose_client.core.ui.chart

import androidx.compose.animation.core.tween
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.gestures.detectDragGestures
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.text.BasicText
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.drawscope.DrawScope
import androidx.compose.ui.input.pointer.PointerInputChange
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.drawText
import androidx.compose.ui.text.rememberTextMeasurer
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import novasoft.roads.util.FormatUtils.formatToAmountAbbr
import novasoft.roads.util.FormatUtils.getDividerForAmount
import ru.novasoft.roads.compose_client.core.designsystem.theme.doneBrightColor
import ru.novasoft.roads.compose_client.core.designsystem.theme.planBrightColor
import ru.novasoft.roads.compose_client.core.designsystem.theme.reportedColor
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.XAxisPadding
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Spacing.innerDefaultSpaceBetween
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.maxBarCornerRadius
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.maxBarWidth
import ru.novasoft.roads.compose_client.core.ui.chart.components.LabelHelper
import ru.novasoft.roads.compose_client.core.ui.chart.components.LabelShape
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.spaceBetween
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.split
import ru.novasoft.roads.compose_client.core.ui.chart.models.*
import kotlin.math.min

/** Типы данных (серий данных) для соответствующего комбинированного графика */
interface CombinedChartValueType {
    /** Название серии данных, которое будет отображено в легенде */
    fun getTitle(): String

    /** Цвет соответствующей серии */
    @Composable
    fun getColor(): Color

    /** Нужно ли рисовать эту серию как линейный график (иначе - столбчатый) */
    fun isLineChart(): Boolean

    /** Нужно ли делать подписи значений по оси Y на левой шкале (иначе - на правой)
     * Примечание: на данный момент все столбчатые данные должны быть по левой стороне */
    fun indicatorsToLeft(): Boolean

    /** Получение подписей к осям У (левой/правой) - должно быть одинаковым для всех, возьмется у первого */
    fun getYAxisTitle(isLeft: Boolean): String
}

/** Организация измерения для комбинированного графика - должны быть значения для каждого типа (серии) */
interface CombinedChartValue<T> where T : Enum<T>, T : CombinedChartValueType {
    /** Получение значения для соответствующего типа */
    fun getValueByType(type: T): Double
}

/** Хранение измерения для графика план/факт/RunRate */
public data class ForecastChartValues(
    val plan: Double,
    val fact: Double,
    val runRate: Double
) : CombinedChartValue<ForecastChartSeriesTypes> {
    override fun getValueByType(type: ForecastChartSeriesTypes): Double = when (type) {
        ForecastChartSeriesTypes.PLAN -> plan
        ForecastChartSeriesTypes.FACT -> fact
        ForecastChartSeriesTypes.RUN_RATE -> runRate
    }
}

/** Типы серий данных для графика план/факт/RunRate */
enum class ForecastChartSeriesTypes : CombinedChartValueType {
    PLAN, FACT, RUN_RATE;

    override fun getTitle(): String = when (this) {
        PLAN -> "План"
        FACT -> "Факт"
        RUN_RATE -> "Run Rate"
    }

    @Composable
    override fun getColor(): Color = when (this) {
        PLAN -> MaterialTheme.colorScheme.planBrightColor
        FACT -> MaterialTheme.colorScheme.doneBrightColor
        RUN_RATE -> MaterialTheme.colorScheme.reportedColor
    }

    override fun isLineChart(): Boolean = when (this) {
        PLAN -> true
        FACT -> false
        RUN_RATE -> true
    }

    override fun indicatorsToLeft(): Boolean = when (this) {
        PLAN -> true
        FACT -> true
        RUN_RATE -> false
    }

    override fun getYAxisTitle(isLeft: Boolean) = when (isLeft) {
        true -> "руб"
        false -> "%"
    }
}

/**
 * Рисование комбинированного линейного/столбчатого графика с правой и левой шкалами
 * @param data данные в формате пар K - идентификатор измерения по оси Х, V - набор значений по всем требуемым типам
 * (`CombinedChartValue` по `CombinedChartValueType`)
 * @param modifier общий Modifier графика
 * @param leftYAxisName опциональный аргумент, если хотим управлять названием левой оси Y извне.
 */
@Composable
inline fun <V, reified T> LineColumnChart(
    data: List<Pair<String, V>>,
    modifier: Modifier,
    leftYAxisName: String? = null
) where T : Enum<T>, T : CombinedChartValueType, V : CombinedChartValue<T> {
    //Все типы серий данных, которые надо рисовать для данного графика
    val allTypes = enumValues<T>()

    //Проверяем текущие ограничения на комбинированный график
    require(allTypes.none { !it.isLineChart() && !it.indicatorsToLeft() }) {
        "Все столбцы должны быть ориентированы по левой стороне"
    }
    require(data.map { v -> allTypes.map { t -> v.second.getValueByType(t) } }.flatten().all { it >= 0.0 }) {
        "Все значения должны быть неотрицательными"
    }

    //Заполняем организаторы работы с графиками (левый линейный, правый линейный, левый столбчатый) и связанные константы
    val chartVariables = createChartVariables(data, allTypes)

    val labelsStyle = MaterialTheme.typography.bodySmall
    val leftLabelAreaWidth = remember { mutableStateOf(0f) }
    val rightIndicatorsWidth = remember { mutableStateOf(0f) }
    val chartWidth = remember { mutableStateOf(0) }
    val innerSpace = innerDefaultSpaceBetween

    //Генерируем элементы содержимого
    CompositionLocalProvider(LocalLayoutDirection provides LayoutDirection.Ltr) {
        Column(
            modifier = modifier
        ) {
            Row(
                modifier = Modifier
                    .fillMaxSize()
                    .weight(1f)
                ,
                horizontalArrangement = Arrangement.spacedBy(innerSpace)
            ) {
                //Подпись названия левой оси ординат
                val mod = Modifier.rotate(-90f).align(Alignment.CenterVertically)
                Row(
                    Modifier.onGloballyPositioned { lc -> leftLabelAreaWidth.value = lc.size.width + innerSpace.value },
                    horizontalArrangement = Arrangement.spacedBy(5.dp)
                ) {
                    Text(
                        text = leftYAxisName ?: allTypes.first().getYAxisTitle(true),
                        textAlign = TextAlign.Center,
                        style = labelsStyle,
                        modifier = mod
                    )
                    val maxDivider = getDividerForAmount(chartVariables.indicatorsLeft.maxOfOrNull { it.toLong() } ?: 0)

                    //Левые подписи оси У (слева в середине) - по максимальному/минимальному значениям по сериям данных, ориентированным влево
                    drawIndicators(chartVariables, true, labelsStyle) {
                        formatToAmountAbbr(it.toLong(), digits = 1, maxDivider = maxDivider)
                    }
                }

                //Сам график (центральный элемент)
                Canvas(
                    modifier = Modifier
                        .fillMaxSize()
                        .weight(1f)
                        //Задаем обработку жестов
                        .addGesturesProcessor(chartVariables)
                        .onGloballyPositioned { lc -> chartWidth.value = lc.size.width },
                    onDraw = mainDrawingFun(chartVariables)
                )

                Row(
                    Modifier.onGloballyPositioned { lc -> rightIndicatorsWidth.value = lc.size.width + innerSpace.value },
                    horizontalArrangement = Arrangement.spacedBy(5.dp)
                ) {
                    //Правые подписи оси У (слева в середине) - по максимальному/минимальному значениям по сериям данных, ориентированным вправо
                    drawIndicators(chartVariables, false, labelsStyle)

                    //Подпись названия правой оси ординат
                    Text(
                        text = allTypes.first().getYAxisTitle(false),
                        textAlign = TextAlign.Center,
                        style = labelsStyle,
                        modifier = mod
                    )
                }
            }

            Spacer(Modifier.height(XAxisPadding))

            //Подписи к оси Х (согласно переданным идентификаторам измерений)
            Box(
                modifier = Modifier.fillMaxWidth(),
            ) {
                val xPadding = chartVariables.columnVariables.xPadding

                chartVariables.labels.forEachIndexed { index, label ->
                    val barCenterX = (
                        (chartWidth.value / chartVariables.columnVariables.data.size.toFloat()) * (index + 0.5f) + xPadding
                    ).dp
                    val labelWidth = chartVariables.columnVariables.textMeasurer.measure(label, style = labelsStyle).size.width.dp
                    val resultOffsetX = leftLabelAreaWidth.value.dp + (barCenterX - labelWidth / 2)

                    BasicText(
                        modifier = Modifier.offset(x = resultOffsetX),
                        text = label,
                        maxLines = 1,
                        style = labelsStyle,
                        softWrap = false,
                    )
                }
            }

            //Легенда наверху - какие серии каким цветом, стилем (квадрат/круг) и положением оси (право/лево)
            LabelHelper(
                data = allTypes.map {
                    Triple(
                        "${it.getTitle()} (${if (it.indicatorsToLeft()) "<-" else "->"})",
                        SolidColor(it.getColor()),
                        if (it.isLineChart()) LabelShape.CIRCLE else LabelShape.SQUARE
                    )
                },
                textStyle = labelsStyle
            )
        }
    }

}

/** Отрисовка графиков - всего три, один поверх другого */
@Composable
fun mainDrawingFun(chartVariables: CombinedChartVariables): DrawScope.() -> Unit = {
    onDrawForCanvas(chartVariables.columnVariables).invoke(this)
    onDrawForCanvas(chartVariables.leftLineVariables).invoke(this)
    onDrawForCanvas(chartVariables.rightLineVariables).invoke(this)
}

/**
 * Отрисовка подписей по оси Х (слева или справа)
 * @param contentBuilder Конвертер значений в подписи на левой Y оси
 */
@Composable
fun drawIndicators(
    chartVariables: CombinedChartVariables,
    isLeft: Boolean,
    labelStyle:  TextStyle,
    contentBuilder: (Double) -> String = { chartVariables.columnVariables.indicatorProperties.contentBuilder(it) }
) {
    val indicatorValues = if (isLeft) chartVariables.indicatorsLeft else chartVariables.indicatorsRight
    val indicatorProperties = chartVariables.columnVariables.indicatorProperties
    val indicatorMeasures = indicatorValues.map {
        chartVariables.columnVariables.textMeasurer.measure(
            if (isLeft) contentBuilder(it) else indicatorProperties.contentBuilder(it),
            style = labelStyle
        )
    }
    val maxIndicatorWidth = indicatorMeasures.maxOf { it.size.width }

    Canvas(
        modifier = Modifier
            .width(maxIndicatorWidth.dp)
            .fillMaxHeight()
    ) {
        indicatorValues.forEachIndexed { index, _ ->
            val measureResult = indicatorMeasures[index]
            val x = (maxIndicatorWidth - measureResult.size.width).toFloat() / 2

            drawText(
                textLayoutResult = measureResult,
                topLeft = Offset(
                    x = x,
                    y = (size.height - measureResult.size.height).spaceBetween(
                        itemCount = indicatorValues.count(),
                        index
                    )
                )
            )
        }
    }
}

/** Хранение управляющих элементов и констант для комбинированного графика */
class CombinedChartVariables(
    /** Подписи по оси Х */
    val labels: List<String>,
    /** Значения по левой оси У */
    val indicatorsLeft: List<Double>,
    /** Ширина подписи к оси Y (горизонтальная, после поворота) */
    val yAxisTitleWidth: Float,
    /** Ширина левой шкалы */
    val leftIndicatorsWidth: Float,
    /** Значения по правой оси У */
    val indicatorsRight: List<Double>,
    /** Ширина правой шкалы с учетом места для подписи */
    val rightIndicatorsWidth: Float,
    /** Высота строки с подписями по оси Х */
    val labelsHeight: Int,
    /** Блок управления левым линейным графиком */
    val leftLineVariables: LineChartVariables,
    /** Блок управления правым линейным графиком */
    val rightLineVariables: LineChartVariables,
    /** Блок управления левым столбчатым графиком */
    val columnVariables: ColumnChartVariables,
)

/** Метод для заполнения текущего CombinedChartVariables */
@Composable
fun <V, T> createChartVariables(
    data: List<Pair<String, V>>,
    allTypes: Array<T>,
): CombinedChartVariables where T : Enum<T>, T : CombinedChartValueType, V : CombinedChartValue<T> {
    //Получаем значения для левой оси У и для правой оси У
    val (leftTypes, rightTypes) = allTypes.partition { it.indicatorsToLeft() }
    val leftIndicatorsData =
        data.map { v -> leftTypes.map { t -> v.second.getValueByType(t) } }.flatten().toSet()
    val rightIndicatorsData =
        data.map { v -> rightTypes.map { t -> v.second.getValueByType(t) } }.flatten().toSet()

    //Определяем минимум/максимум для левой/правой осей У
    val maxLeftValue: Double = leftIndicatorsData.maxOrNull() ?: 0.0
    val minLeftValue: Double = min(0.0, leftIndicatorsData.minOrNull() ?: 0.0)
    val maxRightValue: Double = rightIndicatorsData.maxOrNull() ?: 0.0
    val minRightValue: Double = min(0.0, rightIndicatorsData.minOrNull() ?: 0.0)

    val labels = data.map { it.first }

    //Выделяем данные для каждого графика, преобразуем в списки Line/Bars для дальнейшей отрисовки графиков
    val (lineTypes, columnTypes) = allTypes.partition { it.isLineChart() }
    val lineData: List<Pair<T, Line>> = lineTypes.map { t ->
        val color = SolidColor(t.getColor())
        t to Line(
            label = t.getTitle(),
            values = data.map { (_, v) -> v.getValueByType(t) },
            color = color,
            dotProperties = DotProperties(true, color = color)
        )
    }
    val columnData: List<Bars> = data.map { (k, v) ->
        Bars(
            k,
            columnTypes.map { t ->
                Bars.Data(
                    label = t.getTitle(),
                    value = v.getValueByType(t),
                    color = SolidColor(t.getColor())
                )
            }
        )
    }
    val (leftLines, rightLines) = lineData.partition { it.first.indicatorsToLeft() }

    //Вызываем проверки данных, определенные для линейных/столбчатых графиков
    leftLines.map { it.second }.validateData(maxLeftValue, minLeftValue)
    rightLines.map { it.second }.validateData(maxRightValue, minRightValue)
    columnData.validateData(maxLeftValue, minLeftValue)

    //инициализируем всякие Properties, которые будут использоваться в графиках (чтобы сделать общие настройки)
    val indicatorProperties = HorizontalIndicatorProperties(enabled = false, textStyle = TextStyle.Default)
    val popupProperties = PopupProperties(
        textStyle = TextStyle.Default.copy(
            color = Color.White,
            fontSize = 12.sp
        )
    )
    val labelProperties = LabelProperties(enabled = false)
    val zeroLineProperties = ZeroLineProperties()
    val dividerProperties = DividerProperties()
    val dotsProperties = DotProperties(enabled = true)
    val textMeasurer = rememberTextMeasurer()
    val animationMode = AnimationMode.Together()
    val animationDelay: Long = 100
    val curvedEdges = false
    val placeToCenterPoint = true
    //2 варианта параметров задней сетки: для нижнего уровня графика (столбчатого) с отрисовкой делений по оси Х, для верхних уровней - без рисования
    val gridPropertiesTopLevel = GridProperties(enabled = false)
    val gridPropertiesBaseLevel =
        GridProperties(enabled = true, yAxisProperties = GridProperties.AxisProperties(lineCount = labels.size + 1))

    //Заполняем настройки/состояния каждого графика
    val leftLineVar = LineChartVariables(
        leftLines.map { it.second },
        labelProperties,
        textMeasurer,
        zeroLineProperties,
        animationDelay,
        animationMode,
        popupProperties,
        curvedEdges,
        maxLeftValue,
        minLeftValue,
        dividerProperties,
        indicatorProperties,
        gridPropertiesTopLevel,
        dotsProperties,
        placeToCenterPoint
    ).apply { initVariables() }
    val rightLineVar = LineChartVariables(
        rightLines.map { it.second },
        labelProperties,
        textMeasurer,
        zeroLineProperties,
        animationDelay,
        animationMode,
        popupProperties,
        curvedEdges,
        maxRightValue,
        minRightValue,
        dividerProperties,
        indicatorProperties,
        gridPropertiesTopLevel,
        dotsProperties,
        placeToCenterPoint
    ).apply { initVariables() }
    val columnVar = ColumnChartVariables(
        columnData,
        BarProperties(
            thickness = maxBarWidth,
            cornerRadius = Bars.Data.Radius.Rectangle(topLeft = maxBarCornerRadius, topRight = maxBarCornerRadius)
        ),
        indicatorProperties,
        maxLeftValue,
        minLeftValue,
        textMeasurer,
        popupProperties,
        animationMode,
        tween(500),
        animationDelay,
        onBarClick = null,
        onBarLongClick = null,
        dividerProperties,
        gridPropertiesBaseLevel,
        barAlphaDecreaseOnPopup = .4f
    ).apply { initVariables() }

    //Рассчитываем дополнительные параметры размеров (подписей по разным осям)
    val labelAreaHeight = remember {
        labels.maxOf {
            textMeasurer.measure(
                it,
                style = labelProperties.textStyle
            ).size.height
        } + (labelProperties.padding.value * columnVar.density.density).toInt()
    }

    val indicatorsLeft = split(
        count = indicatorProperties.count,
        minValue = minLeftValue,
        maxValue = maxLeftValue
    )
    val indicatorsRight = split(
        count = indicatorProperties.count,
        minValue = minRightValue,
        maxValue = maxRightValue
    )

    val yAxisTitleWidth = remember {
        listOf(allTypes.first().getYAxisTitle(true), allTypes.first().getYAxisTitle(false)).maxOf {
            textMeasurer.measure(it).size.width.toFloat()
        }
    }
    val leftIndicatorWidth = remember {
        indicatorsLeft.maxOf {
            textMeasurer.measure(indicatorProperties.contentBuilder(it)).size.width
        } + ((indicatorProperties.padding.value) * columnVar.density.density)
    }
    val rightIndicatorWidth = remember {
        indicatorsRight.maxOf {
            textMeasurer.measure(indicatorProperties.contentBuilder(it)).size.width
        } + (indicatorProperties.padding.value * columnVar.density.density) + yAxisTitleWidth / 2
    }

    //Объединяем все рассчитанные поля
    return CombinedChartVariables(
        labels,
        indicatorsLeft,
        yAxisTitleWidth,
        leftIndicatorWidth,
        indicatorsRight,
        rightIndicatorWidth,
        labelAreaHeight,
        leftLineVar,
        rightLineVar,
        columnVar
    )
}

/** Делаем совместную обработку жестов для графиков всех уровней */
@Composable
fun Modifier.addGesturesProcessor(chartVariables: CombinedChartVariables): Modifier {
    //Если никакие подсказки не надо показывать - ничего не меняем и выходим
    return if (!chartVariables.columnVariables.popupProperties.enabled) this
    else this
        // Добавляем обработку перетаскиваний для всех трех графиков
        .pointerInput(chartVariables) {
            detectDragGestures(
                //Текущие подсказки будем хранить в левом графике, там и чистим
                onDragEnd = chartVariables.leftLineVariables.onDragEnd(),
                onDrag = { change: PointerInputChange, dragAmount: Offset ->
                    //Обновляем рассчитанные элементы для обоих линейных графков
                    chartVariables.leftLineVariables.updateDragPopups(change, size) { formatToAmountAbbr(it.toLong(), digits = 1) }
                    chartVariables.rightLineVariables.updateDragPopups(change, size) { formatToAmountAbbr(it.toLong(), digits = 1) }

                    //Копируем рассчитанные значения в один график (левый)
                    chartVariables.leftLineVariables.popups.addAll(chartVariables.rightLineVariables.popups)
                    chartVariables.leftLineVariables.popupsOffsetAnimators.addAll(chartVariables.rightLineVariables.popupsOffsetAnimators)

                    //Перерисовываем на основе линейных графиков
                    chartVariables.leftLineVariables.repaintPopups()

                    //Добавляем обработку для столбчатого графика
                    chartVariables.columnVariables.onDrag().invoke(change)
                }
            )
        }
        // Добавляем обработку нажатий для столбчатого графика
        .pointerInput(chartVariables) {
            detectTapGestures(
                onTap = chartVariables.columnVariables.onTap(),
                onLongPress = chartVariables.columnVariables.onLongPress()
            )
        }
}