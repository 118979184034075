package ru.novasoft.roads.compose_client.core.ui.table.utils

import androidx.compose.foundation.layout.Row
import androidx.compose.material3.Checkbox
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.runtime.*
import ru.novasoft.roads.compose_client.core.ui.table.mappers.Filter
import ru.novasoft.roads.compose_client.core.ui.table.mappers.FilterType
import ru.novasoft.roads.compose_client.core.ui.table.mappers.TableColumn

@Composable
fun <T> buildFilters(tableColumns: List<TableColumn<T>>) : List<Filter<T>> {
    val list : MutableList<Filter<T>> = mutableListOf()
    for(column in tableColumns) {
        list.add(
            when(column.getFilterType()) {
                FilterType.NONE -> continue
                FilterType.TEXT_FIELD -> buildTextFieldFilter(column)
                FilterType.CHECKBOX -> buildCheckBoxFilter(column)
            }
        )
    }
    return list
}

@Composable
fun<T> buildTextFieldFilter(column: TableColumn<T>) : Filter<T>{
    val getter = column.getColumnContent()
    return object : Filter<T> {
        private var text by remember { mutableStateOf("") }
        override fun getFilterComposable(): @Composable () -> Unit {
            return {
                TextField(
                    value = text,
                    onValueChange = { newText -> text = newText },
                    label = { Text(column.getColumnName()) }
                )
            }
        }

        override fun getFilter(): (item: T) -> Boolean {
            return {item ->
                val content = getter?.let { it(item) } ?: true
                if(content is String) {
                    content.contains(text, ignoreCase = true)
                } else {
                    true
                }
            }
        }

        override fun getFilterName(): String {
            return column.getColumnName()
        }
    }
}

@Composable
fun<T> buildCheckBoxFilter(column: TableColumn<T>) : Filter<T> {
    val getter = column.getColumnContent()
    return object : Filter<T> {
        private var checked by remember { mutableStateOf(false) }
        override fun getFilterComposable(): @Composable () -> Unit {
            return {
                Row{
                    Checkbox(
                        checked = checked,
                        onCheckedChange = { checked = !checked }
                    )
                    Text(column.getColumnName())
                }
            }
        }

        override fun getFilter(): (item: T) -> Boolean {
            return { item ->
                val content = getter?.let { it(item) } ?: true
                if (content is Boolean) {
                    content == checked
                } else {
                    true
                }
            }
        }

        override fun getFilterName(): String {
            return column.getColumnName()
        }
    }
}