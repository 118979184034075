package src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs

import novasoft.roads.dto.svor.SvorEntryDto

interface DetailingTree {
    /**
     * Название селектора текущего уровня
     */
    fun getSelectorName(): String

    /**
     * Имя этого экземпляра(который будет в ComboBox этого уровня)
     */
    fun getName(): String

    /**
     * Получение дочерних элементов этого экземпляра
     */
    fun getChildren(): List<DetailingTree>

    /**
     * Функция, которая будет вызвана при выборе этого экземпляра
     */
    fun invokeSelection()
}

abstract class ProgressDetailing(
    val title: String,
    override val childrenList: List<ProgressDetailing>
) : DetailingModels(title, childrenList) {
    abstract fun getTargetEntries(): List<SvorEntryDto>
}

/**
 * Детализация главой СВОР
 * - если переданы children, то используем их как следующий уровень
 * - если не переданы children, то используем svors для определения значений элемента (какие СВОРы входят), без следующего уровня
 */
class SvorChapterDetailing(
    name: String,
    private val children: List<ProgressDetailing>?,
    private val svors: List<SvorEntryDto> = listOf()
) : ProgressDetailing(name, children ?: listOf()) {
    override fun getSelectorName() = "Глава СВОР"

    override fun getTargetEntries(): List<SvorEntryDto> =
        if (children != null)
            childrenList.flatMap { it.getTargetEntries() }
        else svors
}

/**
 * Детализация ВРК
 * - если переданы children, то используем их как следующий уровень
 * - если не переданы children, то используем svors для определения значений элемента (какие СВОРы входят), без следующего уровня
 */
class WorkTypeDetailing(
    name: String,
    private val children: List<SvorEntryDetailing>?,
    private val svors: List<SvorEntryDto> = listOf()
) : ProgressDetailing(name, children ?: listOf()) {
    override fun getSelectorName() = "Вид работ Компании"
    override fun getTargetEntries() =
        if (children != null)
            childrenList.flatMap { it.getTargetEntries() }
        else svors
}

class SvorEntryDetailing(
    val entry: SvorEntryDto
) : ProgressDetailing(entry.workName, listOf()) {
    override fun getSelectorName() = "Вид работ СВОР"

    override fun getTargetEntries(): List<SvorEntryDto> {
        return listOf(entry)
    }
}

abstract class DetailingModels(
    val itemName: String,
    open val childrenList: List<DetailingTree> = listOf(),
    var onSelect: () -> Unit = {}
) : DetailingTree {
    final override fun getName() = itemName
    final override fun invokeSelection() {
        onSelect()
    }

    final override fun getChildren(): List<DetailingTree> = childrenList
}