package ru.novasoft.roads.compose_client.core.network.api.contract

import novasoft.roads.dto.analityc.ResourceUsageDto
import novasoft.roads.dto.client_common.*
import novasoft.roads.dto.excelExchange.ExcelDataDto
import novasoft.roads.dto.excelExchange.ExcelUpdateResultDto
import novasoft.roads.dto.machinary_fact.MachineryFactDto
import novasoft.roads.dto.plan.OperPlanDto
import novasoft.roads.dto.plan.WorkEntryDto
import novasoft.roads.dto.plan.res_sheet_history.ResourcesSheetHistoryDto
import novasoft.roads.dto.reports.RequestString
import novasoft.roads.dto.reports.fact_report.FactReportDto
import novasoft.roads.dto.reports.fact_report.FactReportFromFormDto
import novasoft.roads.dto.reports.fact_report.ReportDto
import novasoft.roads.dto.reports.work_type_info.WorkTypesInfoDto
import novasoft.roads.dto.svor.*
import ru.novasoft.roads.compose_client.core.model.dto.Response
import ru.novasoft.roads.compose_client.core.network.client.ClientMapper
import ru.novasoft.roads.compose_client.core.network.client.RoadsClient

class ContractApi(override val client: RoadsClient) : ClientMapper(client, "contract"), IContractApi {

    override suspend fun getPrepayments(contractId: Int) =
        makeJsonRequest<List<PrepaymentDto>>("${contractId}/get_prepayments")

    override suspend fun savePrepayment(
        prepayment: PrepaymentDto,
        contractId: Int
    ) = httpSendJsonGetString("$contractId/prepayments/save", prepayment)

    override suspend fun deletePrepayment(
        prepaymentId: Long,
        contractId: Int
    ) = httpSendRequestString("$contractId/prepayments/$prepaymentId/delete")

    override suspend fun getPickets(contractId: Int) =
        makeJsonRequest<Array<PicketDto>>("$contractId/pickets")

    override suspend fun postPickets(
        document: ByteArray,
        contractId: Int,
        extension: String
    ): Response<ExcelUpdateResultDto> =
        httpSendAndGetJson("$contractId/upload/pickets/$extension", ExcelDataDto(document, 0))

    override suspend fun addTempPickets(contractId: Int, meter: Float) =
        httpSendRequestString("$contractId/add_temp_pickets/$meter")

    override suspend fun getResourcesUsageHistory(contractId: Int) =
        makeJsonRequest<List<ResourcesSheetHistoryDto>>("$contractId/resources_usage_history")

    override suspend fun downloadResourcesUsageHistory(contractId: Int, historyId: Long) =
        httpSendRequestByteArray("$contractId/download/resources_usage_history/$historyId", 1)

    override suspend fun saveResourcesUsageHistory(contractId: Int, name: String) =
        httpSendRequestString("$contractId/save/resources_usage_history/$name")

    override suspend fun deleteResourcesUsageHistory(contractId: Int, historyId: Long) =
        httpSendRequestString("$contractId/delete/resources_usage_history/$historyId")

    override suspend fun saveM29(
        m29: M29Dto,
        contractId: Int
    ) = httpSendJsonGetString("$contractId/m29/save", m29)

    override suspend fun getM29ByTransaction(
        trId: Long,
        contractId: Int
    ) = makeJsonRequest<M29Dto>("$contractId/m29/$trId")

    override suspend fun getContractById(contractId: Int) =
        makeJsonRequest<ContractDto>("$contractId")

    override suspend fun getDataCounts(contractId: Int) =
        makeJsonRequest<DataCountsDto>("$contractId/data_counts")

    override suspend fun deleteWeights(
        weights: List<Long?>?,
        contractId: Int
    ) = httpSendJsonGetString("$contractId/delete/weights", weights)

    override suspend fun associateWeights(reportId: Long, weights: List<Long?>?, contractId: Int) =
        httpSendJsonGetString("$contractId/associate/weights/$reportId", weights)

    override suspend fun weightsToGeofence(geofenceId: Int, weights: List<Long?>, contractId: Int) =
        httpSendJsonGetString("$contractId/weights_to_geofence/$geofenceId", weights)

    override suspend fun undoDistribution(weightId: Long, contractId: Int) =
        httpSendRequest("$contractId/undo_distribution/$weightId")

    override suspend fun getReports(contractId: Int) =
        makeJsonRequest<Array<ReportDto>>("$contractId/fact_reports")

    override suspend fun getReportsCount(workTypeId: Long, contractId: Int) =
        makeJsonRequest<Int>("$contractId/reports_of_wt/$workTypeId/count")

//    override suspend fun postSensorsExcel(contractId: Int, document: Path?, sheetId: Int) =
//        httpPostOctetStream("$contractId/upload/sensors/$sheetId", document, 3)

//    override suspend fun postSensorsJson(file: Path?, contractId: Int) =
//    httpPostOctetStream("$contractId/upload/sensorsjson", file)

//    override suspend fun postFRJson(file: Path?, contractId: Int) =
//    httpPostOctetStream("$contractId/upload/factreportsjson", file)

    override suspend fun dropContract(contractId: Int) =
        httpSendRequestString("$contractId/delete")

    override suspend fun getWeightsDistributionForContract(contractId: Int) =
        makeJsonRequest<DistributionInfoDto>("$contractId/contract_weights_distribution")

    override suspend fun updateWeightsForContract(contractId: Int) =
        httpSendRequestString("$contractId/update/weights")

//    override suspend fun postWeights(contractId: Int, document: Path?, sheetId: Int) =
//        httpPostOctetStream("$contractId/upload/weights/$sheetId", document)

//    override suspend fun postReports(contractId: Int, document: Path?, sheetId: Int) =
//        httpPostOctetStream("$contractId/upload/reports/$sheetId", document, 3)

    override suspend fun getReportTransactions(contractId: Int) =
        makeJsonRequest<List<SvorEntryReportTransactionDto>>("$contractId/get_report_transactions")

    override suspend fun saveKS2(transaction: SvorEntryReportTransactionDto, contractId: Int) =
        httpSendJsonGetString("$contractId/save_ks2", transaction)

    override suspend fun saveKS2Worth(
        ksId: Long,
        worth: String,
        contractId: Int
    ) = httpSendJsonGetString("$contractId/save_ks2_worth/$ksId", RequestString(worth))

    override suspend fun deleteReportToCustomerTransaction(transactionId: Long, contractId: Int) =
        httpSendRequestString("$contractId/delete_report_transaction/$transactionId")

    override suspend fun fetchUnassignedSVOREntries(contractId: Int) =
        makeJsonRequest<List<SvorEntryDto>>("$contractId/get_unassigned_svor_entries")

    override suspend fun getContractSvorWorkTypes(contractId: Int) =
        makeJsonRequest<Array<WorkTypeDto>>("$contractId/svor_worktype/list")

    override suspend fun getContractPlanV2ResUsagesBySvorId(
        contractId: Int
    ) = makeJsonRequest<Map<String, Collection<ResourceUsageDto>>>("$contractId/ppp_res_usages_by_svor/list")

    override suspend fun deleteWorkType(
        workTypeId: Long,
        contractId: Int
    ) = httpSendRequestString("$contractId/worktype/$workTypeId/delete")

    override suspend fun deleteCustomWorkType(
        workTypeId: Long,
        contractId: Int
    ) = httpSendRequestString("$contractId/custom_worktype/$workTypeId/delete")

    override suspend fun saveReports(
        reports: List<FactReportFromFormDto>,
        contractId: Int,
    ): Response<List<FactReportDto>> = httpSendAndGetJson("$contractId/save_reports", reports)

//    override suspend fun saveWorkType(
//        wt: WorkTypeView,
//        contractId: Int
//    ): Response<WorkTypeDto> = httpSendAndGetJson("$contractId/save_worktype", wt.toJson())

//    override suspend fun saveCustomWorkType(
//        wt: WorkTypeView,
//        workEntriesIds: List<Long>,
//        contractId: Int,
//    ): Response<WorkTypeAnswerDto> = httpSendAndGetJson(
//        "$contractId/save_custom_worktype", CustomWorkTypeFromDto(wt.toJson(), workEntriesIds)
//    )

    override suspend fun getSvorPlanDiff(contractId: Int) =
        makeJsonRequest<List<SvorPlanDifferenceDto>>("$contractId/calculate_svor_plan_difference")

    override suspend fun getFRbyContractAsync(contractId: Int) =
        makeJsonRequest<Array<FactReportDto>>("$contractId/factreports")

    override suspend fun getFRbyContract(contractId: Int) =
        getFRbyContractAsync(contractId).await()

    override suspend fun getCalendarPlans(contractId: Int) =
        makeJsonRequest<List<GlobalPlanDto>>("$contractId/global_plans/list")

    override suspend fun getOperPlans(contractId: Int) =
        makeJsonRequest<List<OperPlanDto>>("$contractId/oper_plan/list")

    override suspend fun saveOperPlan(
        op: OperPlanDto,
        contractId: Int
    ): Response<OperPlanDto> = httpSendAndGetJson("$contractId/oper_plan/save", op)

    override suspend fun removeOperPlan(
        operPlanId: Long,
        contractId: Int
    ) = httpSendRequestString("$contractId/oper_plan/$operPlanId/remove")

    override suspend fun updateGlobalPlans(
        items: List<GlobalPlanDto>,
        contractId: Int
    ) = httpSendJsonGetString("$contractId/global_plan/update", items)

    override suspend fun getWeightData(contractId: Int) =
        makeJsonRequest<List<WeightDataDto>>("$contractId/weights")

    override suspend fun getMachineryData(contractId: Int) =
        makeJsonRequest<List<MachineryFactDto>>("$contractId/machinery_facts")

    override suspend fun undoMachineryDistribution(machineryId: Long, contractId: Int) =
        httpSendRequest("$contractId/undo_machinery_distribution/$machineryId")

    override suspend fun associateMachinery(reportId: Long, machinery: List<Long>, contractId: Int) =
        httpSendJsonGetString("$contractId/machinery_facts/assign_fact_report/$reportId", machinery)

    override suspend fun getSvorEntriesNeedUpdate(contractId: Int) =
        makeJsonRequest<List<Long>>("$contractId/svor_entries_need_update")

    override suspend fun getWorkTypesNeedUpdate(contractId: Int) =
        makeJsonRequest<List<Long>>("$contractId/worktypes_need_update")

    override suspend fun copyContract(contractId: Int) =
        httpSendRequestString("$contractId/copy")

    override suspend fun getContractWorkTypesInfo(contractId: Int) =
        makeJsonRequest<List<WorkTypesInfoDto>>("$contractId/worktypes_info")

    override suspend fun getSvorOrPlanCurrentVersion(
        contractId: Int,
        isSvor: Boolean
    ) = makeJsonRequest<VersionInfoDto>("$contractId/svor_plan_current_version/$isSvor")

    override suspend fun planActualizingIsAvailable(contractId: Int) =
        makeJsonRequest<Boolean>("$contractId/plan_actualizing_is_available")


    override suspend fun getWorktypesByContractId(contractId: Int) =
        makeJsonRequest<List<IdWithNameDto>>("$contractId/worktypesv2")

    override suspend fun getAllWorkTypesByContractId(contractId: Int) =
        makeJsonRequest<List<WorkTypeDto>>("$contractId/all_worktype/list")

    override suspend fun getWorkEntriesByContractId(contractId: Int) =
        makeJsonRequest<List<WorkEntryDto>>("$contractId/work_entries")

    override suspend fun saveWorkEntriesByContractId(
        contractId: Int,
        entries: List<WorkEntryDto>
    ) = httpSendAndGetJson<List<WorkEntryDto>, List<WorkEntryDto>>("$contractId/save/work_entries", entries)

    override suspend fun deleteWorkEntries(contractId: Int, entriesIds: List<Long>): Response<String> =
        httpSendJsonGetString("$contractId/delete/work_entries", entriesIds)

    override suspend fun getContractCustomWorkTypes(contractId: Int) =
        makeJsonRequest<Array<WorkTypeDto>>("$contractId/custom_worktype/list")

    override suspend fun getSVOREntries(contractId: Int) =
        makeJsonRequest<Array<SvorEntryDto>>("$contractId/get_svor_entries")

    override suspend fun deleteFactReport(contractId: Int, reportId: Long) =
        httpSendRequestString("$contractId/deletereport/$reportId")
}