package ru.novasoft.roads.compose_client.core.ui.chart.models

import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Rect

data class SelectedBar(
    val bar: Bars.Data,
    val offset: Offset,
    val rect: Rect
)

internal data class SelectedStackedBar(
    val bar: Bars,
    val rect: Rect
)