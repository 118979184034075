package src.novasoft.roads.compose_client.feature.menu.help

import org.kodein.di.DI
import org.kodein.di.bind
import org.kodein.di.instance
import org.kodein.di.singleton

fun helpDIModule() = DI.Module("helpModule") {
    val helpViewModelSingleton = singleton { HelpViewModel(instance()) }
    bind<HelpViewModel>() with helpViewModelSingleton
}