package src.novasoft.roads.compose_client.feature.menu.help.utils

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.*
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextDecoration
import novasoft.roads.dto.client_common.HelpTextForDto

interface FormatterColors {
    fun getHyperLinkColor() : Color
    fun getImportantColor() : Color
}

data object DefaultFormatterColors : FormatterColors {
    override fun getImportantColor() = Color.Blue
    override fun getHyperLinkColor() = Color.Black
}

enum class TextStyles(val delimiters: Pair<String, String>) {
    HYPERLINK(Pair("<<", ">>")),
    IMPORTANT(Pair("[[", "]]"))
}

class TextFormatter(
    private var linkColors : FormatterColors = DefaultFormatterColors,
    private var texts : List<HelpTextForDto>,
    private var linkFunction : (String) -> Unit = {}
) {
    internal var mainText : AnnotatedString = AnnotatedString("")

    fun format(text : String) {
        mainText = AnnotatedString("")
        formatText(text, 0)
    }

    private fun formatText(text: String, formatStyle: Int) {
        if (formatStyle >= TextStyles.entries.size) // Стиль не найден
            return

        // Ищем разделители нужного стиля
        val delimitersToFind = TextStyles.entries.getOrElse(formatStyle) {return}
        val (startSplitter, endSplitter) = delimitersToFind.delimiters

        val parts = text.split(startSplitter)
        var currentText = parts[0]

        for (i in 1 until parts.size) {
            val partsOfParts = parts[i].split(endSplitter)
            if (partsOfParts.size == 1) {
                println(
                    "Ошибка текста страницы помощи: отсутствуют знаки " +
                            "окончания ссылки (${endSplitter})"
                )
            } else if (isInvalidHyperlink(partsOfParts[0], delimitersToFind)) {
                println("Ошибка текста страницы помощи: значение ссылки отсутствует в списке глав")
            } else {
                setCurrentText(currentText, formatStyle)
                val nextFormatted = stylizeCurrentText(partsOfParts[0], formatStyle)
                if (nextFormatted != null)
                    mainText += nextFormatted
                currentText = partsOfParts[1]
                continue
            }
            currentText += startSplitter + parts[i]
        }
        setCurrentText(currentText, formatStyle)
    }

    private fun isInvalidHyperlink(text : String, delimiters : TextStyles) : Boolean {
        return delimiters == TextStyles.HYPERLINK && texts.none { it.shortName == text }
    }

    private fun setCurrentText(currentText: String, styleNum: Int) {
        if(currentText.isEmpty()) return
        if (styleNum == TextStyles.entries.size - 1) {
            stylizeCurrentText(currentText)?.let { mainText += it }
        } else
            formatText(currentText, styleNum + 1)
    }

    private fun stylizeCurrentText(currentText: String, styleNum: Int? = null): AnnotatedString? {
        if (styleNum == null) {
            return buildAnnotatedString {
                append(currentText)
            }
        }
        when (TextStyles.entries.getOrNull(styleNum)) {
            TextStyles.HYPERLINK -> {
                val colorToUse = linkColors.getHyperLinkColor()
                return buildAnnotatedString {
                    withLink(
                        LinkAnnotation.Clickable(
                            currentText,
                            TextLinkStyles(
                                style = SpanStyle(color = colorToUse),
                                hoveredStyle = SpanStyle(textDecoration = TextDecoration.Underline)
                            ),
                            linkInteractionListener = {
                                val tag = (it as LinkAnnotation.Clickable).tag
                                linkFunction(tag)
                            }
                        )
                    ) {
                        append(currentText)
                    }
                }
            }
            TextStyles.IMPORTANT ->
                return buildAnnotatedString {
                    val colorToUse = linkColors.getImportantColor()
                    withStyle(style = SpanStyle(fontWeight = FontWeight.Bold, color = colorToUse)) {
                        append(currentText)
                    }
                }
            else -> return null
        }
    }
}