package ru.novasoft.roads.compose_client.core.ui.widgets

import androidx.compose.foundation.layout.*
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.times
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.defaultPaddingCard
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.cardSizeBase
import ru.novasoft.roads.compose_client.core.ui.chart.speedometer.RRData
import ru.novasoft.roads.compose_client.core.ui.chart.speedometer.RunRateSpeedometer
import ru.novasoft.roads.compose_client.core.ui.effects.LoadingEffect

@Composable
fun SpeedometerCardContent(
    modifier: Modifier = Modifier,
    rrData: RRData?
) {
    ElevatedCard(
        modifier = Modifier
            .size(width = 2 * cardSizeBase, height = 300.dp),
        colors = CardDefaults.cardColors(containerColor = MaterialTheme.colorScheme.onPrimary),
        elevation = CardDefaults.elevatedCardElevation()
    ) {
        if (rrData == null) {
            LoadingEffect()
            return@ElevatedCard
        }

        Column(
            modifier = modifier
                .fillMaxSize()
                .padding(defaultPaddingCard),
            verticalArrangement = Arrangement.spacedBy(5.dp, alignment = Alignment.Top)
        ) {
            Text(
                text = "Выполнение плана работ",
                style = MaterialTheme.typography.headlineSmall,
                textAlign = TextAlign.Left,
            )
            Text(text = "", style = MaterialTheme.typography.bodySmall)

            RunRateSpeedometer(rrData, modifier)
        }
    }
}