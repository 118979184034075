package ru.novasoft.roads.compose_client.core.model.model.table

import ru.novasoft.roads.compose_client.core.model.model.AmountAndTotalCost
import kotlin.math.roundToLong

const val RUBLES_ABBREVIATION = "руб"
const val LOW_RUN_RATE_ROW_COLOR = 0xFFFA8072


class RRTableRow(
    private val name: String? = null,
    private val planTotalVol: AmountAndTotalCost? = null,
    private val planTDVol: AmountAndTotalCost? = null,
    private val mainUnitAbbr: String = "",
    private val fact: Int? = null,
    private val runRateValue: Int? = null,
    private val rowType: TableRowType
) {
    fun getWorkName(): String = name ?: ""

    fun getUnit(isRubles: Boolean) = if (isRubles) RUBLES_ABBREVIATION else mainUnitAbbr

    fun getTotalPlan(isRubles: Boolean): Float =
        if (isRubles) planTotalVol?.totalCost?.toFloat() ?: 0f else planTotalVol?.amount?.toFloat() ?: 0.0f

    fun getTDPlan(isRubles: Boolean): Float =
        if (isRubles) planTDVol?.totalCost?.toFloat() ?: 0f else planTDVol?.amount?.toFloat() ?: 0f

    fun getFact(isRubles: Boolean, forBars: Boolean = false): Float =
        if (forBars) getTotalPlan(isRubles) / 100f * (fact ?: 0)
        else fact?.toFloat() ?: 0f

    fun getRR(isRubles: Boolean, forBars: Boolean = false): Float =
        if (forBars) getTotalPlan(isRubles) / 100f * (runRateValue ?: 0)
        else runRateValue?.toFloat() ?: 0f

    fun getColorStrategy() = when (val type = getTableRowType()) {
        TableRowType.COMPARING -> LOW_RUN_RATE_ROW_COLOR
        else -> type.getColor()
    }

    fun getSeries(isRubles: Boolean): List<List<Pair<String, Long>>> = listOf(
        listOf("План,\nВсего" to getTotalPlan(isRubles).roundToLong()),
        listOf("План,\n  TD" to getTDPlan(isRubles).roundToLong()),
        listOf("Факт" to getFact(isRubles, true).roundToLong()),
        listOf("RR" to getRR(isRubles, true).roundToLong())
    )

    private fun getTableRowType(): TableRowType =
        if (runRateValue?.let { it < 100 } == true && rowType != TableRowType.REGULAR) TableRowType.COMPARING else rowType

    fun getTotalPlanCost(): Long? = planTotalVol?.totalCost
    fun getTDPlanCost(): Long? = planTDVol?.totalCost
    fun getFactCost(): Int? = fact
}