package ru.novasoft.roads.compose_client.core.designsystem.theme

import androidx.compose.foundation.isSystemInDarkTheme
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Stable
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.TextUnitType
import novasoft_roads.core.designsystem.generated.resources.*
import org.jetbrains.compose.resources.Font

@Stable
@Composable
fun GolosFontFamily() = FontFamily(
    Font(Res.font.GolosText_Regular, weight = FontWeight.Light),
    Font(Res.font.GolosText_Medium, weight = FontWeight.Normal),
    Font(Res.font.GolosText_Bold, weight = FontWeight.Medium),
    Font(Res.font.GolosText_ExtraBold, weight = FontWeight.SemiBold),
    Font(Res.font.GolosText_Black, weight = FontWeight.Bold)
)

@Stable
@Composable
fun RoadsTypography() = Typography().run {
    copy(
        displayLarge = displayLarge.copy(fontFamily = GolosFontFamily()),
        displayMedium = displayMedium.copy(fontFamily = GolosFontFamily()),
        displaySmall = displaySmall.copy(fontFamily = GolosFontFamily()),
        headlineLarge = headlineLarge.copy(fontFamily = GolosFontFamily()),
        headlineMedium = headlineMedium.copy(fontFamily = GolosFontFamily()),
        headlineSmall = headlineSmall.copy(fontFamily = GolosFontFamily(), fontSize = TextUnit(22.0f, TextUnitType.Sp)),
        titleLarge = titleLarge.copy(fontFamily = GolosFontFamily()),
        titleMedium = titleMedium.copy(fontFamily = GolosFontFamily()),
        titleSmall = titleSmall.copy(fontFamily = GolosFontFamily()),
        bodyLarge = bodyLarge.copy(fontFamily = GolosFontFamily()),
        bodyMedium = bodyMedium.copy(fontFamily = GolosFontFamily()),
        bodySmall = bodySmall.copy(fontFamily = GolosFontFamily()),
        labelLarge = labelLarge.copy(fontFamily = GolosFontFamily()),
        labelMedium = labelMedium.copy(fontFamily = GolosFontFamily()),
        labelSmall = labelSmall.copy(fontFamily = GolosFontFamily())
    )
}

val Typography.menu: TextStyle
    @Composable get() = titleSmall.copy(
        fontWeight = FontWeight.W100,
        fontSize = TextUnit(13f, TextUnitType.Sp)
        )

@Composable
fun RoadsTheme(
    content: @Composable () -> Unit
) {
        MaterialTheme(
            colorScheme = RoadsLightColorScheme,
            shapes = Shapes(),
            content = content,
            typography = RoadsTypography()
        )
}

val ColorScheme.planColor: Color @Composable get() = if (!isSystemInDarkTheme()) planLightColor else planDarkColor
val ColorScheme.planBrightColor: Color @Composable get() = if (!isSystemInDarkTheme()) planBrightLightColor else planBrightDarkColor
val ColorScheme.doneColor: Color @Composable get() = if (!isSystemInDarkTheme()) doneLightColor else doneDarkColor
val ColorScheme.doneBrightColor: Color @Composable get() = if (!isSystemInDarkTheme()) doneBrightLightColor else doneBrightDarkColor
val ColorScheme.reportedColor: Color @Composable get() = if (!isSystemInDarkTheme()) reportedLightColor else reportedDarkColor
val ColorScheme.reportedBrightColor: Color @Composable get() = if (!isSystemInDarkTheme()) reportedBrightLightColor else reportedBrightDarkColor
val ColorScheme.redColor: Color @Composable get() = if (!isSystemInDarkTheme()) uiRedLightColor else uiRedDarkColor
val ColorScheme.uiBrightRedColor: Color @Composable get() = if (!isSystemInDarkTheme()) uiBrightRedLightColor else uiBrightRedDarkColor
val ColorScheme.yellowColor: Color @Composable get() = if (!isSystemInDarkTheme()) uiYellowLightColor else uiYellowDarkColor
val ColorScheme.contrastGreenColor: Color @Composable get() = if (!isSystemInDarkTheme()) contrastGreenLightColor else contrastGreenDarkColor
val ColorScheme.unselectedColor: Color @Composable get() = Color.Transparent
val ColorScheme.hyperlinkColor: Color @Composable get() = hyperTextColor
val ColorScheme.unselectedTextColor: Color @Composable get() = unselectedTextColorLight