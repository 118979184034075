package src.novasoft.roads.compose_client.feature.menu.contract_screen

import common.RoadsUiEvent
import common.RoadsViewModel
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import novasoft.roads.dto.plan.res_sheet_history.ResourcesSheetHistoryDto
import novasoft.roads.dto.resource.ResourceDto
import novasoft.roads.dto.svor.SvorEntryDto
import ru.novasoft.roads.compose_client.core.data.remote.IAppStateInfoRepository
import ru.novasoft.roads.compose_client.core.network.api.analytic.IAnalyticPageApi
import ru.novasoft.roads.compose_client.core.network.api.contract.IContractApi
import ru.novasoft.roads.compose_client.core.network.api.folders.IFoldersApi
import src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs.ProgressDetailing
import src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs.SvorChapterDetailing
import src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs.SvorEntryDetailing
import src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs.WorkTypeDetailing

class ContractPageViewModel(
    private val contractApi: IContractApi,
    private val foldersApi: IFoldersApi,
    private val analyticPageApi: IAnalyticPageApi,
    private val contractId: Int,
    val appStateInfoRepository: IAppStateInfoRepository
): RoadsViewModel() {

    private val companyId = appStateInfoRepository.getCompanyId()

    private val _entries = MutableStateFlow<List<SvorEntryDto>>(emptyList())
    val entries = _entries.asStateFlow()
    private val _resources = MutableStateFlow<List<ResourceDto>>(emptyList())
    val resources = _resources.asStateFlow()
    private val _resourcesSheets = MutableStateFlow<List<ResourcesSheetHistoryDto>>(emptyList())
    val resourcesSheets = _resourcesSheets.asStateFlow()
    private val _progressDetailing = MutableStateFlow<List<ProgressDetailing>>(emptyList())
    internal val progressDetailing = _progressDetailing.asStateFlow()


    private val _isContractDataLoaded = MutableStateFlow(false)
    val isContractDataLoaded = _isContractDataLoaded.asStateFlow()

    init { updateAllData() }

    fun updateAllData() {
        viewModelScope.launch {
            val jobs = listOf(
                emitEntries(),
                emitResources(),
                emitResourcesSheets(),
                setContractSvorWorth()
            )
            listOf(jobs[0], jobs[2]).awaitAll()
            emitProgressDetailing()
            jobs.awaitAll()
            _isContractDataLoaded.emit(true)
        }

    }

    private fun emitEntries() =
        viewModelScope.async { _entries.emit(contractApi.getSVOREntries(contractId).await().toList()) }
    private fun emitResources() =
        viewModelScope.async { _resources.emit(foldersApi.getCompanyResources(companyId).await()) }
    private fun emitResourcesSheets() =
        viewModelScope.async { _resourcesSheets.emit(contractApi.getResourcesUsageHistory(contractId).await()) }
    private fun setContractSvorWorth() = viewModelScope.async {
        val svorWorth = analyticPageApi.getContractWorth(contractId).await()
        appStateInfoRepository.getOpenedContract().svorWorth = svorWorth
    }
    private fun emitProgressDetailing() =
        viewModelScope.launch { _progressDetailing.emit(getContractDetailingRoot(true)) }

    override fun onEvent(uiEvent: RoadsUiEvent) {
        TODO("Not yet implemented")
    }

    /**
     * Создание иерархии элементов
     * @param withSvorLevel надо ли добавлять финальный уровень СВОР, или ограничиться только главой свор и врк
     */
    private fun getContractDetailingRoot(withSvorLevel: Boolean): List<ProgressDetailing> {
        val svor = entries.value
            .sortedBy { it.position }
            .associateBy { it.id }
        val curSheet = resourcesSheets.value.maxByOrNull { it.date }

        if (svor.isEmpty() || curSheet == null)
            return emptyList()

        val chapterEntries = svor.values.filter { it.chapter }

        // В СВОР нет заголовков или нет иерархии
        if (chapterEntries.isEmpty() || svor.values.all { !it.svorReportId.contains(".") }) {

            // Нет ВРК или есть пустые ВРК
            // - если нужен уровень СВОР, то будет детализация только по СВОР
            // - если не нужен уровень СВОР, то пустой список
            if (curSheet.workTypesResUsage.isEmpty() || curSheet.workTypesResUsage.any { it.entriesIds.isEmpty() }) {
                return if (withSvorLevel) svor.values.map { SvorEntryDetailing(it) }
                else emptyList()
            }

            return curSheet.workTypesResUsage.map {
                //Если нужен уровень СВОР, то передаем селекторы для него, иначе SvorEntryJson
                if (withSvorLevel)
                    WorkTypeDetailing(
                        it.workTypeName,
                        it.entriesIds
                            .mapNotNull { svor[it] }
                            .map { SvorEntryDetailing(it) }
                    )
                else WorkTypeDetailing(
                    it.workTypeName,
                    children = null,
                    svors = it.entriesIds.mapNotNull { svor[it] }
                )
            }

        }

        val chaptersDotsCount = chapterEntries.associateWith { it.svorReportId.count { c -> c == '.' } }
        val minDotsCount = chaptersDotsCount.values.min()
        val mainChapters = chaptersDotsCount.filter { it.value == minDotsCount }.keys

        val chaptersEntries =
            mainChapters.associateWith { c -> svor.values.filter { it.svorReportId.startsWith(c.svorReportId) } }

        //val leftEntries = svor.values - chaptersEntries.values.flatten()
        //val resultChapters = chaptersEntries.plus(SvorEntryJson(workName = "Работы без глав") to leftEntries)

        if (curSheet.workTypesResUsage.any { it.entriesIds.isEmpty() }) {
            return chaptersEntries.map { (chapter, entries) ->
                //Если нужен уровень СВОР, то передаем селекторы для него, иначе SvorEntryJson
                if (withSvorLevel)
                    SvorChapterDetailing(chapter.workName, entries.map { SvorEntryDetailing(it) })
                else
                    SvorChapterDetailing(chapter.workName, children = null, svors = entries)
            }
        }

        return chaptersEntries.mapNotNull { (chapter, entries) ->

            val curEntriesById = entries.associateBy { it.id }
            val workTypes = curSheet.workTypesResUsage
                .associateWith { it.entriesIds.mapNotNull { eId -> curEntriesById[eId] } }
                .filter { it.value.isNotEmpty() }
                .map { (wt, es) ->
                    //Если нужен уровень СВОР, то передаем селекторы для него, иначе SvorEntryJson
                    if (withSvorLevel) {
                        val detailing = es.map { SvorEntryDetailing(it) }
                        WorkTypeDetailing(wt.workTypeName, detailing)
                    } else
                        WorkTypeDetailing(wt.workTypeName, children = null, svors = es)
                }
            if (workTypes.isEmpty()) return@mapNotNull null
            SvorChapterDetailing(chapter.workName, workTypes)
        }
    }
}