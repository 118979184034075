package src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs.jobs

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import novasoft.roads.dto.plan.res_sheet_history.ResourcesSheetHistoryDto
import novasoft.roads.dto.svor.SvorEntryDto
import org.kodein.di.compose.rememberDI
import org.kodein.di.instance
import ru.novasoft.roads.compose_client.core.model.model.ContractModel
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.defaultPaddingWindow
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Spacing.defaultSpaceBetweenCards
import ru.novasoft.roads.compose_client.core.ui.widgets.*
import src.novasoft.roads.compose_client.feature.menu.contract_screen.ContractRunRateData
import src.novasoft.roads.compose_client.feature.menu.contract_screen.JobsUiEvent
import src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs.DetailingBlock
import src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs.ProgressDetailing
import kotlin.math.roundToInt

@OptIn(ExperimentalLayoutApi::class)
@Composable
fun JobsDashBoardTab(
    svorEntries: List<SvorEntryDto>?,
    contract: ContractModel,
    resourcesSheets: List<ResourcesSheetHistoryDto>,
    isContractDataLoaded: Boolean,
    detailingList: List<ProgressDetailing>? = null
) {
    // Состояния для выбора
    val topSelection = remember<MutableState<ProgressDetailing?>> { mutableStateOf(null) }
    val botSelection = remember<MutableState<ProgressDetailing?>> { mutableStateOf(null) }
    val firstOptions = remember(detailingList) { mutableStateOf(detailingList) }
    val targetEntries: Map<Long, SvorEntryDto>? = remember(topSelection.value, botSelection.value) {
        val resolvedSelection = if (botSelection.value == null) topSelection else botSelection
        resolvedSelection.value?.getTargetEntries()?.associateBy { it.id }
    }

    val jobsViewModel: JobsDashBoardViewModel by rememberDI { instance(arg = contract.contractId) }

    val wtDoneParts by jobsViewModel.wtDoneParts.collectAsState()
    val picketsData by jobsViewModel.picketsData.collectAsState()
    val prepayment by jobsViewModel.prepayment.collectAsState()
    val totalProgress by jobsViewModel.totalProgress.collectAsState()
    val contractProblems by jobsViewModel.contractProblems.collectAsState()
    val entriesStatus by jobsViewModel.entriesStatus.collectAsState()
    val rrRows by jobsViewModel.rrRows.collectAsState()
    val rrData by jobsViewModel.rrData.collectAsState()
    val title by jobsViewModel.title.collectAsState()
    val cardData by jobsViewModel.cardData.collectAsState()

    val isJobsTabDataLoaded by jobsViewModel.isJobsTabDataLoaded.collectAsState()
    val isWtDonePartsLoaded by jobsViewModel.isWtDonePartsLoaded.collectAsState()
    val isTPAndPrepLoaded by jobsViewModel.isTPAndPrepLoaded.collectAsState()
    val isPicketDatLoaded by jobsViewModel.isPicketDatLoaded.collectAsState()
    val isStatusesLoaded by jobsViewModel.isStatusesLoaded.collectAsState()

    Column(
        modifier = Modifier
            .fillMaxWidth()
    ) {
        DetailingBlock(
            firstOptions = firstOptions,
            firstSelected = topSelection,
            secondSelected = botSelection,
        )

        FlowRow(
            modifier = Modifier
                .fillMaxWidth()
                .padding(defaultPaddingWindow)
                .background(MaterialTheme.colorScheme.surfaceContainer)
                .verticalScroll(rememberScrollState()),
            horizontalArrangement = Arrangement.spacedBy(defaultSpaceBetweenCards, Alignment.CenterHorizontally),
            verticalArrangement = Arrangement.spacedBy(defaultSpaceBetweenCards, Alignment.Top),
        ) {
            BudgetCardHolder(
                targetEntries,
                totalProgress,
                prepayment,
                contract,
                isContractDataLoaded && isTPAndPrepLoaded
            )

            SpeedometerCardContent(rrData = rrData)

            PicketCardContent(
                Modifier,
                targetEntries,
                picketsData,
                PicketCardContentType.PROGRESS,
                contract.contractId,
                isContractDataLoaded && isPicketDatLoaded
            )

            ForecastCardContent(title, cardData)

            RunRateCardContent(Modifier, rrRows)
        }
    }

    //Расчёты для карточки SpeedometerCardContent
    LaunchedEffect(isContractDataLoaded, resourcesSheets, targetEntries, wtDoneParts, svorEntries) {
        if (isContractDataLoaded && isWtDonePartsLoaded && svorEntries != null) {
            jobsViewModel.onEvent(
                JobsUiEvent.RunRateDataReceived(
                    dataForCalcRR = ContractRunRateData(
                        resourcesSheets.maxBy { it.date },
                        targetEntries ?: svorEntries.associateBy { it.id },
                        wtDoneParts.associate { it.wortTypeId to (it.done * 100).roundToInt() }
                    )
                )
            )
        }
    }
    //Расчёты для карточки ForecastCardContent
    LaunchedEffect(isContractDataLoaded, svorEntries, isStatusesLoaded, topSelection.value, botSelection.value) {
        if (isStatusesLoaded && isContractDataLoaded && svorEntries !== null) {
            jobsViewModel.onEvent(
                JobsUiEvent.ForecastDataReceived(
                    detailing = if (botSelection.value == null) topSelection.value else botSelection.value,
                    svorEntries = svorEntries,
                    svorWorth = contract.svorWorth
                )
            )
        }
    }
}
