package src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Filter1
import androidx.compose.material.icons.filled.Filter2
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.RectangleShape
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun DetailingBlock(
    firstOptions: MutableState<List<ProgressDetailing>?>,
    firstSelected: MutableState<ProgressDetailing?>,
    secondSelected: MutableState<ProgressDetailing?>,
) {
    var isDialogOpen by remember { mutableStateOf(false) }

    Card(
        modifier = Modifier
            .width(DefaultSizes.tabWidth * 3)
            .clip(RoundedCornerShape(bottomEnd = DefaultSizes.CornerRadius.defaultRadius)),
        shape = RectangleShape,
        colors = CardDefaults.cardColors(containerColor = MaterialTheme.colorScheme.onPrimary),
    ) {
        Column(
            modifier = Modifier.padding(DefaultSizes.Padding.menuStartPadding),
        ) {
            Button(
                onClick = { isDialogOpen = true },
                modifier = Modifier.fillMaxWidth()
            ) {
                Text(
                    text = "Выбрать параметры детализации",
                    style = MaterialTheme.typography.labelLarge,
                    maxLines = 1
                )
            }
            // Отображение Chips, если выбрана детализация
            val currFirstSelected = firstSelected.value
            if (currFirstSelected != null) {
                if (firstSelected.value?.title != null)
                    DetailingFilterChip(
                        { firstSelected.value = null; secondSelected.value = null },
                        currFirstSelected.title,
                        Icons.Filled.Filter1,
                        "Пункт СВОР"
                    )

                val currSecondSelected = secondSelected.value
                if (currSecondSelected != null)
                    DetailingFilterChip(
                        { secondSelected.value = null },
                        currSecondSelected.title,
                        Icons.Filled.Filter2,
                        "Работа из пункта СВОР"
                    )
            }
        }
    }

    if (isDialogOpen) {
        ModalBottomSheet(
            containerColor = MaterialTheme.colorScheme.surfaceContainer,
            onDismissRequest = { isDialogOpen = false }
        ) {
            Column(modifier = Modifier.padding(DefaultSizes.Padding.menuStartPadding)) {

                SelectableList(
                    headLine = { Text(text = "Глава СВОР") },
                    detailing = firstOptions.value,
                    selectedItem = firstSelected,
                    clickAction = {
                        if (secondSelected.value != null) secondSelected.value = null
                        firstSelected.value = firstOptions.value!![it]
                    }
                )
                if (firstSelected.value == null) return@Column

                Spacer(modifier = Modifier.height(DefaultSizes.Spacing.defaultSpacing))
                SelectableList(
                    headLine = { Text(text = "Вид работ СВОР") },
                    detailing = firstSelected.value?.childrenList,
                    selectedItem = secondSelected,
                    clickAction = {
                        secondSelected.value = firstSelected.value?.childrenList!![it]
                    }
                )
            }
        }
    }
}