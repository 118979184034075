package ru.novasoft.roads.compose_client.core.ui.chart


import androidx.compose.animation.core.Spring
import androidx.compose.animation.core.spring
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.text.BasicText
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.TileMode
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import ru.novasoft.roads.compose_client.core.designsystem.theme.doneBrightColor
import ru.novasoft.roads.compose_client.core.designsystem.theme.planBrightColor
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.XAxisPadding
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.maxBarWidth
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.format
import ru.novasoft.roads.compose_client.core.ui.chart.models.*


@Composable
fun PlanFactStackedBarChart(
    plan: Double,
    planTD: Double,
    fact: Double,
    unit: String,
    barProperties : BarProperties = BarProperties(
        cornerRadius = Bars.Data.Radius.None,
        thickness = maxBarWidth,
        spacing = 10.dp
    )
) {
    val isLagged = planTD > fact
    val colorStopsColor = if (isLagged) MaterialTheme.colorScheme.planBrightColor else MaterialTheme.colorScheme.doneBrightColor
    val colorStops = List(100) { index ->
        when {
            index % 3 != 0 -> MaterialTheme.colorScheme.background
            else ->  colorStopsColor
        }
    }
    val lag = if (isLagged) planTD - fact else fact - planTD
    val value = if (isLagged) fact else planTD
    val type = if (isLagged) "Отставание" else "Опережение"
    val text = type + " " + lag.format(2).replace(".", ",") + unit
    val test =
        listOf(
            Bars(
                label = "План",
                values = listOf(
                    Bars.Data(
                        label = "", value = value, color = SolidColor(MaterialTheme.colorScheme.planBrightColor)
                    ),
                    Bars.Data(
                        label = "", value = if (isLagged) lag else 0.0, color = Brush.linearGradient(colorStops, tileMode = TileMode.Repeated, end = Offset(100.dp.value, 100.dp.value))
                    ),
                    Bars.Data(
                        label = "", value = plan - planTD, color = SolidColor(MaterialTheme.colorScheme.surfaceVariant)
                    ),
                )
            ),
            Bars(
                label = "Факт",
                values = listOf(
                    Bars.Data(
                        label = "", value = value, color = SolidColor(MaterialTheme.colorScheme.doneBrightColor)
                    ),
                    Bars.Data(
                        label = "", value = if (isLagged) 0.0 else lag, color = Brush.linearGradient(colorStops, tileMode = TileMode.Repeated, end = Offset(100.dp.value, 100.dp.value))
                    ),
                    Bars.Data(
                        label = "", value = 0.0, color = SolidColor(Color.Transparent)
                    ),
                )
            )
        )
    Column(
        modifier = Modifier.fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally
    ){
        StackedBarChart(
            modifier = Modifier
                .fillMaxSize()
                .weight(1f),
            data = test,
            barProperties = barProperties,
            animationSpec = spring(
                dampingRatio = Spring.DampingRatioMediumBouncy,
                stiffness = Spring.StiffnessLow
            ),
            labelHelperProperties = LabelHelperProperties(enabled = false),
            labelProperties = LabelProperties(
                textStyle = MaterialTheme.typography.bodySmall,
                padding = XAxisPadding,
                enabled = true,
                rotationDegreeOnSizeConflict = 0f
            ),
            indicatorProperties = HorizontalIndicatorProperties(
                textStyle = MaterialTheme.typography.bodySmall,
                count = 3,
                padding = 5.dp,
                position = IndicatorPosition.Horizontal.Start,
                contentBuilder = { it.toInt().toString() }
            ),
            popupProperties = PopupProperties(
                textStyle = TextStyle.Default.copy(
                    color = MaterialTheme.colorScheme.onPrimaryContainer,
                    fontSize = 12.sp
                ),
                contentBuilder = { indicator ->
                    indicator.format(1) + " " + unit
                }
            )
        )
        //Легенда
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .align(Alignment.CenterHorizontally),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        ) {
            Box(
                modifier = Modifier
                    .size(10.dp)
                    .background(Brush.linearGradient(colorStops, tileMode = TileMode.Repeated))
            )
            Spacer(modifier = Modifier.width(5.dp))
            BasicText(text = text, style = MaterialTheme.typography.bodySmall)
        }
    }
}