package ru.novasoft.roads.compose_client.core.model.model.table

private const val MAIN_CHAPTER_COLOR = 0xFFCCCCCC
private const val SUB_CHAPTER1_COLOR = 0xFFF0F0F0
private const val SUB_CHAPTER2_COLOR = 0xFFFAFAFA
private const val COMPARING_COLOR = 0x33FF9933.toLong()
private const val REGULAR_COLOR = 0x00000000.toLong()

enum class TableRowType {
    MAIN_CHAPTER, SUB_CHAPTER1, SUB_CHAPTER2, COMPARING, REGULAR;

    fun getColor(): Long = when (this) {
        MAIN_CHAPTER -> MAIN_CHAPTER_COLOR
        SUB_CHAPTER1 -> SUB_CHAPTER1_COLOR
        SUB_CHAPTER2 -> SUB_CHAPTER2_COLOR
        COMPARING -> COMPARING_COLOR
        REGULAR -> REGULAR_COLOR
    }
}