package ru.novasoft.roads.compose_client.core.ui.widgets

import androidx.compose.foundation.layout.*
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.times
import novasoft.roads.dto.meter_progress.ProgressDto
import novasoft.roads.dto.svor.SvorEntryDto
import novasoft.roads.util.FormatUtils.formatToAmountAbbr
import org.kodein.di.compose.rememberDI
import org.kodein.di.instance
import ru.novasoft.roads.compose_client.core.model.mapping.toProgressDto
import ru.novasoft.roads.compose_client.core.model.model.ContractModel
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.defaultPaddingCard
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.cardSizeBase
import ru.novasoft.roads.compose_client.core.ui.chart.WorthDDChart
import ru.novasoft.roads.compose_client.core.ui.effects.LoadingEffect
import kotlin.math.roundToInt
import ru.novasoft.roads.compose_client.core.network.api.progress.IProgressApi



const val BUDGET_CARD_NAME = "Бюджет"

enum class BudgetType(val typeName: String) {
    SVOR("По СВОР"),
    PLAN("Запланировано"),
    DONE("Выполнено"),
    REPORTED("Сдано")
}

data class BudgetAndProgress(
    val budget: Long, val progress: Int
) {
    constructor(budget: Long, progress: Double) : this(budget, progress.let {
        if (it.isNaN()) 0
        else it.times(100).roundToInt()
    })
}

@Composable
fun BudgetCardContent(
    contractWorth: Long?,
    leftPrepayment: Long?,
    budgets: Map<BudgetType, BudgetAndProgress>,
    isDataLoaded: Boolean
) {
    val subtitle = remember(contractWorth, leftPrepayment) {
        buildString {
            contractWorth?.let {
                append("По договору: ${formatToAmountAbbr(it)} руб.")
            }
            leftPrepayment?.takeIf { it > 0 }?.let {
                append("  Остаток аванса: ${formatToAmountAbbr(it)} руб.")
            }
        }
    }

    ElevatedCard(
        modifier = Modifier
            .size(width = 2 * cardSizeBase, height = 300.dp),
        colors = CardDefaults.cardColors(containerColor = MaterialTheme.colorScheme.onPrimary),
        elevation = CardDefaults.elevatedCardElevation()
    ) {
        if (!isDataLoaded) {
            LoadingEffect()
            return@ElevatedCard
        }

        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(defaultPaddingCard),
            verticalArrangement = Arrangement.spacedBy(5.dp, alignment = Alignment.Top)
        ) {
            Text(
                text = BUDGET_CARD_NAME,
                style = MaterialTheme.typography.headlineSmall,
                textAlign = TextAlign.Left,
            )

            Text(
                text = subtitle,
                style = MaterialTheme.typography.bodySmall,
                color = MaterialTheme.colorScheme.outlineVariant,
                modifier = Modifier.padding(start = 5.dp)
            )

            WorthDDChart(
                svorABS = budgets[BudgetType.SVOR]?.budget ?: 0.0,
                planABS = budgets[BudgetType.PLAN]?.budget ?: 0.0,
                doneABS = budgets[BudgetType.DONE]?.budget ?: 0.0,
                reportedABS = budgets[BudgetType.REPORTED]?.budget ?: 0.0
            )
        }
    }
}


@Composable
fun BudgetCardHolder(
    targetEntries: Map<Long, SvorEntryDto>?,
    totalProgress: ProgressDto?,
    prepayment: Long?,
    contract: ContractModel,
    isDataLoaded: Boolean
) {
    val prepaymentCard = remember { mutableStateOf<Long?>(null) }
    val contractWorth = remember { mutableStateOf<Long?>(null) }
    val budgetMap = remember { mutableStateOf<Map<BudgetType, BudgetAndProgress>>(emptyMap()) }
    val client: IProgressApi by rememberDI { instance() }

    var calculationsIsDone: Boolean by remember(targetEntries) { mutableStateOf(false) }

    BudgetCardContent(contractWorth.value, prepaymentCard.value, budgetMap.value, calculationsIsDone && isDataLoaded)

    LaunchedEffect(targetEntries, totalProgress, isDataLoaded) {
        if (totalProgress == null || !isDataLoaded) return@LaunchedEffect

        calculationsIsDone = false
        if (targetEntries != null) {
            val totalEntriesProgress = try {
                client.getContractTotalProgressForSvors(
                    contract.contractId,
                    targetEntries.keys
                ).data.toProgressDto() ?: ProgressDto(0, 0, 0, 0, 0.0, 0.0, 0.0)
            } catch (e: Exception) {
                return@LaunchedEffect
            }
            contractWorth.value = null
            prepaymentCard.value = null
            budgetMap.value = (
                mapOf(
                    BudgetType.SVOR to BudgetAndProgress(
                        targetEntries.values.sumOf { it.worth }.toLong(),
                        1.0
                    ),
                    BudgetType.PLAN to BudgetAndProgress(
                        totalEntriesProgress.gplanned,
                        totalEntriesProgress.gplanPart.coerceIn(0.0, 1.0)
                    ),
                    BudgetType.DONE to BudgetAndProgress(
                        totalEntriesProgress.done,
                        totalEntriesProgress.donePart.coerceIn(0.0, 1.0)
                    ),
                    BudgetType.REPORTED to BudgetAndProgress(
                        totalEntriesProgress.reported,
                        totalEntriesProgress.passedPart.coerceIn(0.0, 1.0)
                    )
                )
            )
        } else {
            contractWorth.value = contract.worth.toLong()
            prepaymentCard.value = prepayment
            // Если неправильно указана стоимость контракта, то рисуем 999%
            val svorToContractWorthRatio =
                (contract.svorWorth.toDouble() / (contractWorth.value ?: 0L)).let { if (it.isNaN()) 0.0 else it }
                    .coerceIn(0.0, 9.99)
            totalProgress.let { p ->
                budgetMap.value = (
                    mapOf(
                        BudgetType.SVOR to BudgetAndProgress(contract.svorWorth, svorToContractWorthRatio),
                        BudgetType.PLAN to BudgetAndProgress(p.gplanned, p.gplanPart.coerceIn(0.0, 1.0)),
                        BudgetType.DONE to BudgetAndProgress(p.done, p.donePart.coerceIn(0.0, 1.0)),
                        BudgetType.REPORTED to BudgetAndProgress(p.reported, p.passedPart.coerceIn(0.0, 1.0))
                    )
                )
            }
        }
        calculationsIsDone = true
    }
}


