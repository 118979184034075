package ru.novasoft.roads.compose_client.core.ui.menu

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.unit.dp
import ru.novasoft.roads.compose_client.core.designsystem.theme.menu
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Spacing.defaultSpacing
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.menuStartPadding
import ru.novasoft.roads.compose_client.core.ui.NovasoftLogo

@Composable
fun MenuLogo(expanded: MutableState<Boolean>) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .height(50.dp)
            .clip(RoundedCornerShape(bottomStart = 20.dp)),
        colors = CardDefaults.cardColors(
            containerColor = MaterialTheme.colorScheme.secondaryContainer,
            contentColor = MaterialTheme.colorScheme.onSecondaryContainer,
        ),
        shape = RectangleShape

    ) {
        Row(
            modifier = Modifier.fillMaxSize().padding(start = menuStartPadding, top = 10.dp),
            horizontalArrangement = Arrangement.spacedBy(defaultSpacing),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            NovasoftLogo(modifier = Modifier.height(18.dp).width(22.dp))
            if(expanded.value) Text("Novasoft", style = MaterialTheme.typography.menu) else Unit
        }
    }
}