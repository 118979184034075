package ru.novasoft.roads.compose_client.core.ui

import androidx.compose.ui.unit.dp

object DefaultSizes {
    val cardSizeBase = 250.dp
    val maxBarCornerRadius = 10.dp
    val maxBarWidth = 40.dp
    val tabWidth = 200.dp

    object Padding {
        val defaultPadding = 30.dp
        val defaultPaddingWindow = 40.dp
        val defaultPaddingCard = 20.dp
        val menuStartPadding = 14.dp
        val XAxisPadding = 3.dp
    }

    object Spacing {
        val defaultSpaceBetweenCards = 50.dp
        val defaultSpacing = 10.dp
        val defaultExtraSpacing = 20.dp
        val innerDefaultSpaceBetween = 5.dp

    }

    object CornerRadius {
        val defaultRadius = 20.dp
        val smallRadius = 10.dp
    }
}