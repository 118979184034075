package novasoft.roads.util.calculations

import novasoft.roads.dto.analityc.ResourceUsageDto
import novasoft.roads.dto.plan.res_sheet_history.GlobalPlanCopyDto
import novasoft.roads.dto.resource.ResourceDto
import novasoft.roads.util.DateSliceModel
import novasoft.roads.util.DateUtils
import ru.novasoft.roads.compose_client.core.data.remote.IMeasuringUnitsHandler
import ru.novasoft.roads.compose_client.core.model.extension.getConversionRatioTo
import novasoft.roads.util.toDateSlice
import ru.novasoft.roads.compose_client.core.model.model.AmountAndTotalCost
import ru.novasoft.roads.compose_client.core.model.model.TargetResourceType


interface IResourceAmountCalculator {
    fun calculateVolumeCoefFromPlansInPeriod(gPlans: List<GlobalPlanCopyDto>, period: DateSliceModel): Double

    fun calculateYTDAmountAndCost(
        type: TargetResourceType,
        resource: ResourceDto,
        amount: ResourceUsageDto,
        gPlans: List<GlobalPlanCopyDto>,
        cost: Double
    ): AmountAndTotalCost

    fun calculateTotalAmountAndCost(
        type: TargetResourceType,
        resource: ResourceDto,
        amount: ResourceUsageDto,
        cost: Double
    ): AmountAndTotalCost

    fun calculateTotalAmountAndCost(
        type: TargetResourceType,
        resource: ResourceDto,
        amounts: List<ResourceUsageDto>,
        cost: Double
    ): AmountAndTotalCost
}


class ResourceAmountCalculator(private val measuringUnitsHandler: IMeasuringUnitsHandler) : IResourceAmountCalculator {
    /**
     * Считает машино(человеко)смены = кол-во техники(людей) * кол-во смен
     */
    private fun calculateShifts(amounts: List<ResourceUsageDto>, cost: Double): AmountAndTotalCost {
        val totalShifts = amounts.sumOf { a -> (a.amount * (a.shifts ?: 0f)).toDouble() }
        val totalCost = (totalShifts * cost).toLong()
        return AmountAndTotalCost(totalShifts, totalCost)
    }

    /**
     * Считает объемы, приводя все к одной ЕИ, по которой устанавливается стоимость
     * Для асфальта и инертных это должны быть Тонны
     */
    private fun calculateTotalAmount(resource: ResourceDto, amounts: List<ResourceUsageDto>): Double {
        val costUnit = resource.prefUnit ?: resource.targetVariable.unit.let { unitId ->
            measuringUnitsHandler.getUnitById(unitId)
        } ?: throw NoSuchElementException("Unknown unit id: ${amounts.first().unit}")

        return amounts.sumOf { a ->
            measuringUnitsHandler.getUnitById(a.unit)
                ?.getConversionRatioTo(costUnit)
                ?.times(a.amount)
                ?.toDouble()
                ?: throw IllegalArgumentException("Inconvertible units with ids: ${costUnit.id} and ${a.unit} ")
        }
    }

    private fun calculateTonnsWithCost(
        resource: ResourceDto,
        amounts: List<ResourceUsageDto>,
        cost: Double
    ): AmountAndTotalCost {
        val totalAmount = calculateTotalAmount(resource, amounts)
        val totalCost = totalAmount * cost
        return AmountAndTotalCost(totalAmount, totalCost.toLong())
    }

    override fun calculateVolumeCoefFromPlansInPeriod(gPlans: List<GlobalPlanCopyDto>, period: DateSliceModel): Double {
        // Вычисляем какая часть глобальных планов попадает в period
        val plansOverlaps = gPlans.mapNotNull { plan ->
            val planSlice = plan.dateRange
            val overlap = toDateSlice(planSlice).getOverlap(period)
            if (overlap <= 0) return@mapNotNull null
            val coef = overlap.toDouble() / toDateSlice(planSlice).getDuration()
            plan to coef
        }

        return plansOverlaps.sumOf { (plan, coef) ->
            // Доли объемов на пикетах
            plan.picketsWithCoeffs.values.sum() * coef
        }
    }

    private fun calculateYTDAmountFromPlans(
        amount: ResourceUsageDto,
        gPlans: List<GlobalPlanCopyDto>
    ): ResourceUsageDto {
        // Вычисляем какая часть глобальных планов попадает в промежуток от начала года до сегодня
        val ytdSlice = DateUtils.getYTDSlice()
        val ytdCoef = calculateVolumeCoefFromPlansInPeriod(gPlans, ytdSlice)
        return if (amount.shifts != null)
            ResourceUsageDto(amount.resourceId, amount.amount, amount.unit, (amount.shifts!! * ytdCoef).toFloat())
        else
            ResourceUsageDto(amount.resourceId, (amount.amount * ytdCoef).toFloat(), amount.unit, amount.shifts)
    }

    override fun calculateYTDAmountAndCost(
        type: TargetResourceType,
        resource: ResourceDto,
        amount: ResourceUsageDto,
        gPlans: List<GlobalPlanCopyDto>,
        cost: Double
    ): AmountAndTotalCost {
        val ytdAmount = calculateYTDAmountFromPlans(amount, gPlans)
        return calculateTotalAmountAndCost(type, resource, ytdAmount, cost)
    }

    override fun calculateTotalAmountAndCost(
        type: TargetResourceType,
        resource: ResourceDto,
        amount: ResourceUsageDto,
        cost: Double
    ): AmountAndTotalCost = calculateTotalAmountAndCost(
        type, resource, listOf(amount), cost
    )

    override fun calculateTotalAmountAndCost(
        type: TargetResourceType,
        resource: ResourceDto,
        amounts: List<ResourceUsageDto>,
        cost: Double
    ): AmountAndTotalCost {
        return when (type) {
            TargetResourceType.WORK -> calculateShifts(amounts, cost)
            TargetResourceType.MACHINES -> calculateShifts(amounts, cost)
            TargetResourceType.SUBCONTRACT -> {
                val rubles = amounts.sumOf { it.amount.toDouble() }
                AmountAndTotalCost(rubles, rubles.toLong())
            }

            // У остальных(Не асфальт и не инертные) ТМЦ объем считаются в рублях, чтобы можно было складывать на графике
            TargetResourceType.INVENTORY -> {
                val totalCost = calculateTotalAmount(resource, amounts) * cost
                AmountAndTotalCost(totalCost, totalCost.toLong())
            }

            TargetResourceType.INERT -> calculateTonnsWithCost(resource, amounts, cost)
            TargetResourceType.ASPHALT -> calculateTonnsWithCost(resource, amounts, cost)
        }
    }
}
