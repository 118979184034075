package ru.novasoft.roads.compose_client.core.ui.chart.speedometer

import androidx.compose.foundation.Canvas
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.center
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.graphics.drawscope.rotate
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.text.rememberTextMeasurer
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.coerceAtLeast
import androidx.compose.ui.unit.dp
import novasoft.roads.util.GeometricUtils
import kotlin.math.roundToInt

typealias SpeedometerDecoration =  @Composable SpeedometerScope.(
    sections: MutableList<SpeedometerSection>,
    ticks: MutableList<Float>,
) -> Unit

val SpeedViewDecoration: SpeedometerDecoration
    get() = { sections, _ ->
        SpeedViewBackground(sections)
    }

@Composable
private fun SpeedometerScope.SpeedViewBackground(
    sections: MutableList<SpeedometerSection>,
) {
    Canvas(modifier = Modifier.fillMaxSize()) {
        sections.forEach { section ->
            val startAngle = degreeAtPercent(section.startOffset)
            val sweepAngle = degreeAtPercent(section.endOffset) - startAngle
            val roundAngle = if (section.style == StrokeCap.Butt) {
                0f
            } else {
                getRoundAngle(section.width.toPx(), this.size.width - section.width.toPx())
            }
            drawArc(
                color = section.color,
                startAngle = startAngle + roundAngle,
                sweepAngle = sweepAngle - roundAngle * 2f,
                useCenter = false,
                topLeft = Offset(section.width.toPx() * .5f, section.width.toPx() * .5f),
                size = this.size.offsetSize(section.width.toPx()),
                style = Stroke(
                    width = section.width.toPx(),
                    cap = section.style,
                    join = StrokeJoin.Round,
                ),
            )
        }
    }
}

@Composable
fun Speedometer(
    modifier: Modifier = Modifier,
    decoration: SpeedometerDecoration = SpeedViewDecoration,
    minSpeed: Float = SpeedometerDefaults.MinSpeed,
    maxSpeed: Float = SpeedometerDefaults.MaxSpeed,
    speed: Float = minSpeed,
    startDegree: Int = SpeedometerDefaults.StartDegree,
    endDegree: Int = SpeedometerDefaults.EndDegree,
    unit: String = SpeedometerDefaults.Unit,
    unitSpeedSpace: Dp = SpeedometerDefaults.UnitSpeedSpace,
    unitUnderSpeed: Boolean = SpeedometerDefaults.UnitUnderSpeed,
    backgroundCircleColor: Color = SpeedometerDefaults.BackgroundCircleColor,
    indicator: @Composable BoxScope.() -> Unit = SpeedometerDefaults.Indicator,
    centerContent: @Composable BoxScope.() -> Unit = SpeedometerDefaults.CenterContent,
    speedText: @Composable () -> Unit = SpeedometerDefaults.SpeedometerText(speed),
    unitText: @Composable () -> Unit = SpeedometerDefaults.UnitText(unit),
    sections: MutableList<SpeedometerSection> = SpeedometerDefaults.Sections,
    marksCount: Int = SpeedometerDefaults.marksCount,
    marksColor: Color = SpeedometerDefaults.marksColor,
    marksPadding: Dp = SpeedometerDefaults.marksPadding,
    marksWidth: Dp = SpeedometerDefaults.marksWidth,
    marksHeight: Dp = SpeedometerDefaults.marksHeight,
    marksCap: StrokeCap = SpeedometerDefaults.marksCap,
    ticks: MutableList<Float> = SpeedometerDefaults.Ticks,
    tickPadding: Dp = SpeedometerDefaults.TickPadding,
    tickRotate: Boolean = SpeedometerDefaults.TickRotate,
    tickLabel: @Composable BoxScope.(index: Int, tickSpeed: Float) -> Unit =
        SpeedometerDefaults.TickLabel,
    speed2: Float? = null,
    indicator2: @Composable BoxScope.() -> Unit = SpeedometerDefaults.Indicator,
    centerColor: Color = MaterialTheme.colorScheme.primary,
    centerRadius: Dp = 5.dp
) {

    LaunchedEffect(ticks) {
        require(ticks.all { it in 0f..1f }) { "Ticks must be between [0f, 1f]!" }
    }

    BaseSpeedometer(
        modifier = modifier,
        minSpeed = minSpeed,
        maxSpeed = maxSpeed,
        startDegree = startDegree,
        endDegree = endDegree,
    ) {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .background(backgroundCircleColor, CircleShape),
        )

        decoration(sections, ticks)

        Marks(
            marksCount = marksCount,
            color = marksColor,
            paddingTop = marksPadding,
            markWidth = marksWidth,
            markHeight = marksHeight,
            cap = marksCap,
        )

        SpeedUnitText(
            speed = speed,
            speedText = speedText,
            unitText = unitText,
            drawUnit = unit.isNotBlank(),
            spacer = unitSpeedSpace,
            unitUnderSpeed = unitUnderSpeed,
            startDegree = startDegree,
            endDegree = endDegree
        )

        IndicatorBox(
            modifier = Modifier.fillMaxSize(),
            speed = speed,
            indicator = indicator,
        )

        if(speed2 != null) {
            IndicatorBox(
                modifier = Modifier.fillMaxSize(),
                speed = speed2,
                indicator = indicator2,
            )
        }
        //Точка в середине (в основании стрелок)
        Canvas(modifier = modifier.fillMaxSize()) {
            drawCircle(color = centerColor, radius = centerRadius.toPx(), center = size.center)
        }

        Ticks(
            ticks = ticks,
            paddingTop = tickPadding,
            isRotate = tickRotate,
            label = tickLabel,
        )

        MinMaxSpeed(ticks = minSpeed.toInt() to maxSpeed.toInt())

        CenterBox(
            modifier = Modifier.fillMaxSize(),
            center = centerContent,
        )
    }
}

/** Рисует стрелку спидометра */
@Composable
fun SpeedometerScope.IndicatorBox(
    modifier: Modifier = Modifier,
    speed: Float,
    indicator: @Composable BoxScope.() -> Unit,
) {
    Box(
        modifier = modifier.rotate(90f + degreeAtSpeed(speed)),
        contentAlignment = Alignment.Center,
    ) {
        indicator()
    }
}

@Composable
internal fun CenterBox(
    modifier: Modifier = Modifier,
    center: @Composable BoxScope.() -> Unit,
) {
    Box(
        modifier = modifier,
        contentAlignment = Alignment.Center,
    ) {
        center()
    }
}

/**
 * Делает разметку на спидометре
 */
@Composable
internal fun SpeedometerScope.Marks(
    marksCount: Int,
    modifier: Modifier = Modifier,
    color: Color = Color.White,
    paddingTop: Dp = 0.dp,
    markWidth: Dp = 3.dp,
    markHeight: Dp = 9.dp,
    cap: StrokeCap = StrokeCap.Butt,
) {
    Canvas(modifier = modifier.fillMaxSize()) {
        val risk = if (cap == StrokeCap.Round) markWidth.toPx() * .5f else 0f
        val center = size.center
        val markPath = Path()
        markPath.moveTo(center.x, paddingTop.toPx() + risk)
        markPath.lineTo(center.x, paddingTop.toPx() + risk + markHeight.toPx())

        val everyDegree = (endDegree - startDegree) / (marksCount + 1f)

        for (i in 1..marksCount) {
            rotate(
                degrees = 90 + startDegree + everyDegree * i,
                pivot = center,
            ) {
                drawPath(
                    path = markPath,
                    color = color,
                    style = Stroke(
                        width = markWidth.toPx(),
                        cap = cap,
                    ),
                )
            }
        }
    }
}

/** Рисует показания скорости (кроме главной в центре) */
@Composable
internal fun SpeedometerScope.Ticks(
    ticks: MutableList<Float>,
    paddingTop: Dp,
    isRotate: Boolean,
    label: @Composable BoxScope.(index: Int, tick: Float) -> Unit,
) {
    val range = endDegree - startDegree
    ticks.forEachIndexed { index, tick ->
        val d = startDegree + range * tick
        Box(
            modifier = Modifier
                .fillMaxSize()
                .clip(shape = CircleShape)
                .rotate(d + 90f)
                .padding(top = paddingTop),
            contentAlignment = Alignment.TopCenter,
        ) {
            Box(modifier = Modifier.rotate(if (isRotate) 0f else -(d + 90f))) {
                label(index, this@Ticks.getSpeedAtPercent(tick))
            }
        }
    }
}

/** Добавляет показания min & max скоростей снизу */
@Composable
internal fun MinMaxSpeed(
    ticks: Pair<Int, Int>,
) {
    Box(
        modifier = Modifier.fillMaxSize(),
        contentAlignment = Alignment.BottomCenter,
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            val valueList = ticks.toList()
            val maxValString = buildString { append(valueList.max()); append("%") }
            val maxWidth = rememberTextMeasurer()
                .measure(maxValString, style = MaterialTheme.typography.bodyLarge).size.width
            valueList.forEach {
                Text(
                    modifier = Modifier
                        .width(maxWidth.dp),
                    text = "$it%",
                    style = MaterialTheme.typography.bodyLarge,
                    textAlign = TextAlign.End
                )
            }
        }
    }
}

/** Пишет скорость под центром спидометра */
@Composable
internal fun SpeedUnitText(
    speed: Float,
    speedText: @Composable () -> Unit,
    unitText: @Composable () -> Unit,
    drawUnit: Boolean,
    unitUnderSpeed: Boolean = false,
    spacer: Dp = 2.dp,
    startDegree: Int = SpeedometerDefaults.StartDegree,
    endDegree: Int = SpeedometerDefaults.EndDegree
) {
    val textMeasurer = rememberTextMeasurer()
    val textHeight = textMeasurer.measure(speed.roundToInt().toString(), style = SpeedometerDefaults.SpeedStyle())
        .size.height
    val rowWidth = remember { mutableStateOf(0) }

    BoxWithConstraints(
        modifier = Modifier.fillMaxSize(),
        contentAlignment = Alignment.BottomCenter,
    ) {
        val radius = maxWidth / 2
        val fi = (startDegree - (endDegree - 360)).toFloat()
        val speedPadding = {
            GeometricUtils.calcHeightInSimilarIsoscelesTriangle(radius.value.toDouble(), fi, rowWidth.value.toDouble())
        }

        if (unitUnderSpeed) {
            Column(
                modifier = Modifier,
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                if (drawUnit) {
                    Spacer(modifier = Modifier.height(spacer))
                    unitText()
                }
            }
        } else {
            Column {
                Row(
                    modifier = Modifier
                        .onGloballyPositioned { coordinates ->
                            rowWidth.value = coordinates.size.width
                        }
                        .padding(bottom = (radius - speedPadding().dp - textHeight.dp).coerceAtLeast(0.dp))
                ) {
                    speedText()
                    if (drawUnit) {
                        Spacer(modifier = Modifier.width(spacer))
                        unitText()
                    }
                }
            }
        }
    }
}