package src.novasoft.roads.compose_client.feature.menu.help.treeView

import novasoft.roads.dto.client_common.HelpTextForDto

fun buildTree(nodes: List<HelpTextForDto>): List<TreeNode> {
    val treeNodes = nodes.map { helpText ->
        TreeNode(
            id = helpText.id,
            parentId = helpText.parentId,
            name = helpText.shortName
        )
    }

    val nodeMap = treeNodes.associateBy { it.id }
    val roots = mutableListOf<TreeNode>()

    for (node in treeNodes) {
        if (node.parentId.isEmpty()) {
            roots.add(node)
        } else {
            nodeMap[node.parentId]?.let { parent ->
                parent.children += node
            }
        }
    }

    return roots
}

fun treeToFlatList(nodes: List<TreeNode>, namesList: MutableList<Pair<String, String>>) {
    for (node in nodes) {
        namesList.add(Pair(node.id, node.name))
        treeToFlatList(node.children, namesList)
    }
}