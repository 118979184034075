package ru.novasoft.roads.compose_client.core.ui.menu

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.selection.selectable
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Help
import androidx.compose.material.icons.filled.ChevronLeft
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import co.touchlab.kermit.Logger
import ru.novasoft.roads.compose_client.core.designsystem.theme.menu
import ru.novasoft.roads.compose_client.core.designsystem.theme.redColor
import ru.novasoft.roads.compose_client.core.designsystem.theme.unselectedTextColor
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.CornerRadius.defaultRadius
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.tabWidth
import ru.novasoft.roads.compose_client.core.ui.DefaultToolTip

/**
 * В сотых
 * */
private const val HEADER_LINE_HEIGHT = 4

@Composable
fun PageHeaderView(
    name: String,
    contract: PageHeaderContract = object : PageHeaderContract {
        override fun onPageHeaderEvent(event: PageHeaderEvent) {
            Logger.w("Undefined PageHeaderContract") { "Page <$name> has undefined page header logic." }
        }
    }
) {
    val tabs = contract.getTabs()
    val selectedTabInd = mutableStateOf(0)
    val colorStopsColor =
        if (tabs.isEmpty()) MaterialTheme.colorScheme.surfaceContainer else MaterialTheme.colorScheme.secondaryContainer
    val colorStops = getColorStops(MaterialTheme.colorScheme.surfaceContainer, colorStopsColor)
    Box(
        modifier = Modifier.fillMaxSize(),
        contentAlignment = Alignment.BottomCenter,
    ) {
        Card(
            modifier = Modifier.fillMaxWidth().height(40.dp).clip(RoundedCornerShape(topStart = 20.dp)),
            shape = RectangleShape
        ) {
            Box(modifier = Modifier.fillMaxSize().background(Brush.verticalGradient(colorStops))) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically,
                ) {
                    Row {
                        tabs.toList().forEachIndexed { ind, tab ->
                            PageHeaderTab(ind, tab.first, selectedTabInd) { tab.second() }
                        }
                    }
                    Row(
                        horizontalArrangement = Arrangement.spacedBy(5.dp, Alignment.End),
                        verticalAlignment = Alignment.CenterVertically,
                    ) {
                        DefaultToolTip(
                            text = "Назад"
                        ) {
                            IconButton(onClick = { contract.onPageHeaderEvent(PageHeaderEvent.BackClicked) }) {
                                Icon(
                                    Icons.Filled.ChevronLeft,
                                    "Back",
                                    tint = MaterialTheme.colorScheme.primary,
                                )
                            }
                        }
                        DefaultToolTip(
                            text = "Помощь"
                        ) {
                            IconButton(onClick = { contract.onPageHeaderEvent(PageHeaderEvent.HelpClicked) }) {
                                Icon(
                                    Icons.AutoMirrored.Filled.Help,
                                    "Help",
                                    tint = MaterialTheme.colorScheme.primary,
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun PageHeaderTab(ind: Int, name: String, selectedInd: MutableState<Int>, onSelect: () -> Unit) {
    val colorStopsColor =
        if (selectedInd.value == ind) MaterialTheme.colorScheme.redColor else MaterialTheme.colorScheme.secondaryContainer
    val colorStops = getColorStops(MaterialTheme.colorScheme.background, colorStopsColor)
    val textColor =
        if (selectedInd.value == ind) MaterialTheme.colorScheme.onBackground else MaterialTheme.colorScheme.unselectedTextColor
    Card(
        modifier = Modifier.width(tabWidth).fillMaxHeight()
            .selectable(selectedInd.value == ind) {
                selectedInd.value = ind
                onSelect()
            },
        shape = if (ind == 0) RoundedCornerShape(topStart = defaultRadius) else RectangleShape
    ) {
        Box(modifier = Modifier.fillMaxSize().background(Brush.verticalGradient(colorStops))) {
            Text(
                text = name,
                modifier = Modifier.fillMaxSize().wrapContentHeight(align = Alignment.CenterVertically),
                style = MaterialTheme.typography.menu,
                color = textColor,
                textAlign = TextAlign.Center
            )
        }
    }
}

@Composable
private fun getColorStops(firstColor: Color, second: Color): List<Color> =
    List(100 - HEADER_LINE_HEIGHT) { firstColor } + List(HEADER_LINE_HEIGHT) { second }