package src.novasoft.roads.compose_client.feature.menu.contracts

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Edit
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import novasoft.roads.dto.client_common.ContractDto
import novasoft.roads.dto.client_common.ContractType
import novasoft.roads.util.FormatUtils.formatBitDepth
import novasoft.roads.util.fromEditorToDouble
import novasoft.roads.util.toDateString
import ru.novasoft.roads.compose_client.core.ui.editors.FilterDropDownMenu
import ru.novasoft.roads.compose_client.core.ui.dialog.CommonDialog
import ru.novasoft.roads.compose_client.core.ui.dialog.DatePickerModal
import ru.novasoft.roads.compose_client.core.ui.editors.CommonDialogDropDownMenu
import ru.novasoft.roads.compose_client.core.ui.editors.CommonDialogTextField
import ru.novasoft.roads.compose_client.core.ui.editors.EditorValidation
import src.novasoft.roads.compose_client.feature.menu.contracts.contractCard.getContractTypeFromString
import src.novasoft.roads.compose_client.feature.menu.contracts.contractCard.text

@Composable
fun EditContractDialog(
    editingContract: ContractDto?,
    onDismissRequest: () -> Unit,
    customers: List<String>,
    onSave: (newContractData: ContractDto) -> Unit,
) {

    var nameState by remember { mutableStateOf(editingContract?.name ?: "") }
    var numberState by remember { mutableStateOf(editingContract?.contractNumber ?: "") }
    var worthState by remember { mutableStateOf(editingContract?.worth?.let { formatBitDepth(it) } ?: "") }
    var customerState by remember { mutableStateOf(editingContract?.customerName) }
    var typeState by remember { mutableStateOf(editingContract?.type?.text ?: "") }
    var date by remember { mutableStateOf(editingContract?.startDate) }
    val openEditDateDialog = remember { mutableStateOf(false) }
    var archiveState by remember { mutableStateOf(editingContract?.archive) }

    var isAllFormValid by remember { mutableStateOf(false) }

    fun validateAllForm() {
        isAllFormValid = true
        //TODO
    }

    validateAllForm()

    CommonDialog(
        title = "Изменить контракт",
        onOk = {
            onSave(
                ContractDto(
                    contractId = editingContract?.contractId ?: -1,
                    name = nameState,
                    customerName = customerState!!,
                    type = getContractTypeFromString(typeState),
                    startDate = date!!,
                    archive = archiveState!!,
                    contractNumber = numberState,
                    worth = worthState.fromEditorToDouble() ?: 0.0,
                )
            )
        },
        onDismissRequest = { onDismissRequest() },

        isValidState = isAllFormValid,
    ) {

        CommonDialogTextField(
            text = nameState,
            label = "Название контракта",
            onValueChange = {
                nameState = it
                validateAllForm()
            },
            validation = listOf(
                EditorValidation.EmptinessValidation,
                EditorValidation.LastIsNotWhitespaceValidation,
                EditorValidation.FirstIsNotWhitespaceValidation,
            )
        )

        CommonDialogDropDownMenu(
            text = typeState,
            label = "Тип",
            options = ContractType.entries.map { it.text },
            listOf(EditorValidation.EmptinessValidation),
            onSelect = {
                typeState = it
                validateAllForm()
            }
        )

        FilterDropDownMenu(
            selectedOption = customerState,
            label = "Заказчик",
            customers,
            listOf(EditorValidation.EmptinessValidation),
            onSelect = { ind, opt ->
                customerState = customers[ind]
                validateAllForm()
            }
        )

        //TODO: Вынести этот datePicker в дефолтную функцию core.editors
        var fieldDateState by remember { mutableStateOf(date?.toDateString() ?: "") }
        CommonDialogTextField(text = fieldDateState, "Дата начала", true) {
            IconButton(
                modifier = Modifier.padding(10.dp),
                onClick = { openEditDateDialog.value = true }
            ) {
                Icon(imageVector = Icons.Filled.Edit, "Изменить")
            }
        }
        if (openEditDateDialog.value)
            DatePickerModal(
                initialValue = date,
                onDateSelected = {
                    it?.let {
                        date = it
                        fieldDateState = it.toDateString()
                        validateAllForm()
                    }
                }
            ) { openEditDateDialog.value = false }


        CommonDialogTextField(
            text = numberState,
            label = "Номер",
            onValueChange = {
                numberState = it
                validateAllForm()
            },
            validation = listOf(
                EditorValidation.EmptinessValidation,
                EditorValidation.LastIsNotWhitespaceValidation,
                EditorValidation.FirstIsNotWhitespaceValidation,
            )
        )

        CommonDialogTextField(
            text = worthState,
            label = "Стоимость",
            onValueChange = {
                worthState = it
                validateAllForm()
            },
            validation = EditorValidation.FormattedNumberValidation
        )

        //TODO: Вынести этот checkbox в дефолтную функцию core.editors
        Card(
            modifier = Modifier.fillMaxWidth().height(60.dp),
            colors = CardDefaults.cardColors().copy(containerColor = MaterialTheme.colorScheme.surfaceContainerLow),
            shape = RoundedCornerShape(5.dp),
        ) {
            Row(
                modifier = Modifier.padding(15.dp),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.spacedBy(10.dp)
            ) {
                Text(text = "Архивный", modifier = Modifier.height(30.dp))
                Checkbox(
                    checked = archiveState ?: false,
                    onCheckedChange = { archiveState = it },
                    modifier = Modifier.size(30.dp)
                )
            }

        }
    }
}