package ru.novasoft.roads.compose_client.core.model.routing

/**
 * Страницы, которые не входят в SPA(Single page application)
 * - Авторизация
 * - Само приложение SPA
 * - далее будет еще лендинг, регистрация и т.п.
 */
enum class RootRouting {
    Auth,
    MainApp,
}


/**
 * Экраны SPA. У них отдельный NavHostController и они не перерисовывают левое меню
 * Примечание: порядок объявления учитывается при составлении меню
 */
enum class Menu {
    Companies,
    CompaniesContracts,
    CustomersAssignment,
    SubcontractorsAssignment,
    Contracts,
    OpenedContract,
    Dashboard,
    Data,
    Planning,
    Control,
    Reports,
    ContractSettings,
    Folders,
    Resources,
    Geofences,
    SubcontractorsIdSetter,
    Team,
    Workers,
    Departments,
    Roles,
    Modules,
    Help,
    Contacts;

    fun isBaseItem() = when (this) {
        Companies,
        Contacts,
        Contracts,
        Folders,
        Help,
        Team -> true

        else -> false
    }

    fun getChildren() = when (this){
        Companies -> listOf(
            CompaniesContracts,
            CustomersAssignment,
            SubcontractorsAssignment
        )
        Folders -> listOf(
            Resources,
            Geofences,
            SubcontractorsIdSetter
        )
        Team -> listOf(
            Workers,
            Departments,
            Roles,
            Modules
        )
        OpenedContract ->listOf(
            Dashboard,
            Data,
            Planning,
            Control,
            Reports,
            ContractSettings
        )
        else -> emptyList()
    }
}