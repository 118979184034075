package ru.novasoft.roads.compose_client.core.ui.chart

import androidx.compose.animation.core.AnimationSpec
import androidx.compose.animation.core.tween
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.text.BasicText
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Rect
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.TransformOrigin
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.layout.LayoutCoordinates
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.text.TextMeasurer
import androidx.compose.ui.text.drawText
import androidx.compose.ui.text.rememberTextMeasurer
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.unit.coerceAtMost
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.launch
import novasoft.roads.util.formatBitDepth
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.XAxisPadding
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.maxBarCornerRadius
import ru.novasoft.roads.compose_client.core.ui.chart.components.RCCPicketLabelHelper
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.addRoundRect
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.drawGridLines
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.spaceBetween
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.split
import ru.novasoft.roads.compose_client.core.ui.chart.models.*
import ru.novasoft.roads.compose_client.core.ui.chart.utils.ImplementRCAnimation
import ru.novasoft.roads.compose_client.core.ui.chart.utils.calculateOffset
import kotlin.math.absoluteValue
import kotlin.math.ceil
import kotlin.math.floor

internal const val MAX_BARS_COUNT = 100
private const val MAX_PRECISION_ERROR = 0.005

@Composable
fun PicketStackedBarChart(
    modifier: Modifier = Modifier,
    /** Все DataValues должны быть неотрицательными */
    data: List<Bars>,
    barProperties: BarProperties = BarProperties(),
    onBarClick: ((Bars) -> Unit)? = null,
    /** Св-ва подписей на абсциссе */
    labelProperties: LabelProperties = LabelProperties(
        textStyle = MaterialTheme.typography.bodySmall,
        padding = 0.dp,
        enabled = true,
        rotationDegreeOnSizeConflict = 90f
    ),
    /** Св-ва подписей на ординате */
    indicatorProperties: HorizontalIndicatorProperties = HorizontalIndicatorProperties(
        textStyle = MaterialTheme.typography.bodySmall,
        padding = XAxisPadding,
        count = 11
    ),
    /** Св-ва осей координат */
    dividerProperties: DividerProperties = DividerProperties(),
    gridProperties: GridProperties = GridProperties(enabled = false),
    /** Св-ва легенды */
    labelHelperProperties: LabelHelperProperties = LabelHelperProperties(
        textStyle = MaterialTheme.typography.bodySmall
    ),
    /** Задержка в появлении бара. Аргумент - индекс бара */
    animationMode: AnimationMode = AnimationMode.Together { 100L },
    /** Спецификация анимации, указывающая длительность и тип анимации */
    animationSpec: AnimationSpec<Float> = tween(500),
    animationDelay: Long = 100,
    textMeasurer: TextMeasurer = rememberTextMeasurer(),
    /** Св-ва для всплывающего окна, которое отображает данные при наведении или клике на столбец */
    popupProperties: PopupProperties = PopupProperties(
        textStyle = MaterialTheme.typography.labelSmall
    ),
    initialMaxValue: Double = data.maxOfOrNull { it.values.sumOf { it.value } } ?: 0.0,
    minValue: Double = 0.0,
    /** Максимальный процент, который хотим видеть на ординате */
    maxYLabelIndicator: Double = 100.0
) {
    LaunchedEffect(data) {
        require(data.all { it.values.isNotEmpty() }) {
            "Пустой список Bars.values"
        }
        require(data.all { it.values.all { it.value >= 0 } }) {
            "Some of chart data is below zero"
        }
        val tempCount = data.firstOrNull()?.values?.count() ?: 0
        require(data.all { it.values.count() == tempCount }) {
            "Размер списков для всех столбцов должен быть равным"
        }
        if (data.isNotEmpty()) require(initialMaxValue >= data.maxOf { it.values.sumOf { it.value } }) {
            "Chart data must be at most $initialMaxValue (Specified Max Value)"
        }
        require(minValue <= 0) {
            "Min value in column chart must be 0 or lower."
        }
    }

    val density = LocalDensity.current
    val (recalculatedData, ration) = data.recalculateWithMultiple()
    val maxValue = (recalculatedData.maxOfOrNull { it.values.sumOf { it.value } } ?: 100.0).coerceAtLeast(100.0)

    /** Столбец + его данные */
    val barWithRect = remember {
        mutableStateMapOf<String, Pair<Bars, Rect>>()
    }

    /** Выбранное значение */
    val selectedValue = remember {
        mutableStateOf<SelectedStackedBar?>(null)
    }

    val indicators = remember {
        split(
            count = indicatorProperties.count,
            minValue = minValue,
            maxValue = maxYLabelIndicator
        )
    }
    val maxIndicatorWidth = remember { mutableStateOf(0) }
    val indicatorAreaWidth: Float = remember {
        if (indicatorProperties.enabled) {
            indicators.maxOf {
                textMeasurer.measure(indicatorProperties.contentBuilder(it)).size.width.also { if (maxIndicatorWidth.value < it) maxIndicatorWidth.value = it }
            } + (indicatorProperties.padding.value * density.density)
        } else {
            0f
        }
    }

    /** Расстояние от края канваса до ординаты */
    val xPadding: Float = remember {
        if (indicatorProperties.enabled && indicatorProperties.position == IndicatorPosition.Horizontal.Start) {
            indicatorAreaWidth
        } else {
            0f
        }
    }

    val chartWidth = remember {
        mutableFloatStateOf(0f)
    }

    val dataCount = recalculatedData.count()
    val firstBarX = remember { mutableFloatStateOf(0f) }
    val lastBarRightSideX = remember { mutableFloatStateOf(0f) }
    val commonWidth = remember { mutableFloatStateOf(0f) }
    val everyBarWidth = remember { mutableFloatStateOf(0f) }
    var labelMaxWidthPx: Int = 0
    val tooltipState = rememberTooltipState(isPersistent = true)
    val canvasCoordinates = remember { mutableStateOf<LayoutCoordinates?>(null) }

    ImplementRCAnimation(
        data = recalculatedData,
        animationMode = animationMode,
        spec = { it.animationSpec ?: animationSpec },
        delay = animationDelay,
        before = {
            barWithRect.clear()
        }
    )
    CompositionLocalProvider(LocalLayoutDirection provides LayoutDirection.Ltr) {

        Column(modifier = modifier, verticalArrangement = Arrangement.SpaceBetween) {
            val scope = rememberCoroutineScope()
            Canvas(
                modifier = Modifier
                    .fillMaxSize()
                    .weight(1f)
                    .onGloballyPositioned { coordinates ->
                        canvasCoordinates.value = coordinates
                    }
                    .pointerInput(Unit) {
                        detectTapGestures(
                            onTap = {
                                val position = Offset(it.x, it.y)
                                barWithRect
                                    .values
                                    .firstOrNull { (_, rect) ->
                                        rect.contains(position)
                                    }
                                    ?.let { (bars, rect) ->
                                        if (popupProperties.enabled) {
                                            selectedValue.value = SelectedStackedBar(
                                                bar = bars,
                                                rect = rect
                                            )
                                        }
                                        scope.launch { tooltipState.show() }
                                        onBarClick?.invoke(bars)
                                    } ?: selectedValue.apply { value = null }
                            }
                        )
                    }
            ) {

                commonWidth.value = size.width
                val barsAreaWidth = size.width - (indicatorAreaWidth)
                chartWidth.value = barsAreaWidth


                /** Ширина столбцов */
                everyBarWidth.value = with(density) {
                    ((chartWidth.value - barProperties.spacing.toPx() * (dataCount + 1)) / dataCount.toFloat())
                        .coerceAtMost(barProperties.thickness.toPx())
                }

                /** Ордината нулевой абсциссы */
                val zeroY = size.height - calculateOffset(
                    maxValue = maxValue,
                    minValue = minValue,
                    total = size.height,
                    value = 0.0f
                ).toFloat()

                if (indicatorProperties.enabled) {
                    indicators.forEachIndexed { index, indicator ->
                        val measureResult =
                            textMeasurer.measure(
                                indicatorProperties.contentBuilder(indicator),
                                style = indicatorProperties.textStyle
                            )
                        val x = when (indicatorProperties.position) {
                            IndicatorPosition.Horizontal.Start -> (maxIndicatorWidth.value - measureResult.size.width).toFloat() / 2
                            IndicatorPosition.Horizontal.End -> barsAreaWidth + indicatorProperties.padding.value * density.density
                        }
                        val rationHeight = size.height * maxYLabelIndicator.toFloat() / maxValue.toFloat()
                        drawText(
                            textLayoutResult = measureResult,
                            topLeft = Offset(
                                x = x,
                                y = (rationHeight - measureResult.size.height).spaceBetween(
                                    itemCount = indicators.count(),
                                    index
                                ) + (size.height - rationHeight)
                            )
                        )
                    }
                }

                drawGridLines(
                    xPadding = xPadding,
                    size = size.copy(width = barsAreaWidth),
                    dividersProperties = dividerProperties,
                    indicatorPosition = indicatorProperties.position,
                    xAxisProperties = gridProperties.xAxisProperties,
                    yAxisProperties = gridProperties.yAxisProperties,
                    gridEnabled = gridProperties.enabled
                )

                recalculatedData.forEachIndexed oneBarScope@{ dataIndex: Int, accumBars: Bars ->
                    //После построения stacked столбца, следующий начинаем рисовать опять с нулевой ординаты
                    var prevY = zeroY

                    val barX = with(density) {
                        xPadding + dividerProperties.yAxisProperties.thickness.toPx() +
                                barProperties.spacing.toPx() * (dataIndex + 1) + everyBarWidth.value * dataIndex
                    }.also {
                        if (dataIndex == 0) {
                            firstBarX.value = it
                        } else if (dataIndex == dataCount - 1) {
                            lastBarRightSideX.value = it + everyBarWidth.value
                        }
                    }
                    val barsCount = accumBars.values.count()

                    accumBars.values.forEachIndexed pieceScope@{ pieceIndex, barDataPiece: Bars.Data ->
                        val isZeroDataCondition: (Double) -> Boolean = { it < MAX_PRECISION_ERROR }
                        if (isZeroDataCondition(barDataPiece.value)) return@pieceScope

                        val animateValue = barDataPiece.animator.value
                        val isLastElementCondition = pieceIndex + 1 == barsCount
                        val areRemainingNumsZeroCondition =
                            if (isLastElementCondition) true
                            else {
                                accumBars.values.subList(fromIndex = pieceIndex + 1, toIndex = barsCount)
                                    .all { isZeroDataCondition(it.value) }
                            }

                        val barHeight: Double =
                            ((barDataPiece.value * size.height) / (maxValue - minValue)) * animateValue
                        prevY -= barHeight.toFloat().coerceAtLeast(0f)

                        val rect = Rect(
                            offset = Offset(
                                x = barX,
                                y = prevY
                            ),
                            size = Size(
                                width = everyBarWidth.value,
                                height = barHeight.absoluteValue.toFloat()
                            ),
                        )
                        val path = Path()
                        val resultRadius = (everyBarWidth.value.toDp() / 2).coerceAtMost(maxBarCornerRadius)
                        val radius = if (isLastElementCondition || areRemainingNumsZeroCondition)
                            Bars.Data.Radius.Rectangle(topLeft = resultRadius, topRight = resultRadius)
                        else null

                        path.addRoundRect(
                            rect = rect,
                            radius = radius ?: barDataPiece.properties?.cornerRadius ?: barProperties.cornerRadius
                        )
                        drawPath(
                            path = path,
                            brush = barDataPiece.color,
                            alpha = 1f,
                            style = (barDataPiece.properties?.style
                                ?: barProperties.style).getStyle(density.density)
                        )
                        if (animateValue == 1f && (isLastElementCondition || areRemainingNumsZeroCondition)) {
                            val coordX = barX.formatBitDepth(5)

                            if (barWithRect.contains(coordX)) return@pieceScope

                            val commonRect = Rect(
                                offset = Offset(
                                    x = barX,
                                    y = prevY
                                ),
                                size = Size(
                                    width = everyBarWidth.value,
                                    height = zeroY - prevY
                                ),
                            )
                            barWithRect[coordX] = accumBars to commonRect
                            return@oneBarScope
                        }
                    }


                }
            }

            selectedValue.value?.let { selectedValue ->
                val barLocalCoordinates = selectedValue.rect.topRight
                val barOffset = canvasCoordinates.value
                    ?.localToWindow(Offset(barLocalCoordinates.x, barLocalCoordinates.y))
                    ?: Offset.Zero
                val bar = selectedValue.bar

                TooltipBox(
                    positionProvider = exactTooltipPositionProvider(
                        IntOffset(
                            x = barOffset.x.toInt(),
                            y = barOffset.y.toInt()
                        )
                    ),
                    tooltip = {
                        PlainTooltip {
                            val picket =
                                if (ration == 1) bar.label
                                else if (bar.commonLabel[0] == null) bar.commonLabel[1]
                                else "${bar.commonLabel[0]} - ${bar.commonLabel[1]}"
                            Text(
                                StringBuilder()
                                    .append(" <$picket> - Σ${bar.values.sumOf { it.value.toInt() }}\n")
                                    .append(" • сдано: ${bar.values[0].value.toInt()}\n")
                                    .append(" • факт: ${bar.values[1].value.toInt()}\n")
                                    .append(" • план: ${bar.values[2].value.toInt()} ")
                                    .toString()
                            )
                        }
                    },
                    state = tooltipState,
                ) {}
            }

            //Добавляем надписи на абсциссу
            if (labelProperties.enabled && commonWidth.value != 0f) {
                Spacer(modifier = Modifier.height(labelProperties.padding))
                if (dataCount == 0)
                    BasicText(
                    text = "",
                    style = labelProperties.textStyle,
                )
                //Измеряем высоту надписей на абсциссе
                val labelHeightPx: Int = recalculatedData.firstOrNull()?.let {
                    textMeasurer.measure(it.label, style = labelProperties.textStyle, maxLines = 1).size.height
                } ?: 0
                labelMaxWidthPx = recalculatedData.maxOfOrNull {
                    textMeasurer.measure(it.label, style = labelProperties.textStyle, maxLines = 1).size.width
                } ?: 0
                //Максимальное кол-во надписей на абсциссе
                val maxAbscissaLabelCount =
                    floor((commonWidth.value / labelHeightPx / density.density).dp.value).toInt() - 1
                val countRatio = ceil(dataCount.toFloat() / maxAbscissaLabelCount).toInt()

                val widthModifier =
                    if (indicatorProperties.position == IndicatorPosition.Horizontal.End) {
                        Modifier.width((chartWidth.value / density.density).dp)
                    } else {
                        Modifier.fillMaxWidth()
                    }

                //Корректирующая величина для центровки надписи и столбца графика.
                val t = everyBarWidth.value * 0.5 - labelHeightPx * 0.5
                val startPaddingDp = ((firstBarX.value + t) / density.density).dp
                val endPaddingDp = ((commonWidth.value - lastBarRightSideX.value + t) / density.density).dp
                val mod = Modifier
                    .graphicsLayer {
                        rotationZ = labelProperties.rotationDegreeOnSizeConflict
                        transformOrigin = TransformOrigin(0f, 1f)
                        translationY = -5.dp.toPx()
                    }
                    .requiredWidth((labelHeightPx / density.density).dp)


                Row(
                    modifier = widthModifier
                        .fillMaxWidth()
                        .padding(
                            start = startPaddingDp,
                            end = if (endPaddingDp < 0.dp) 0.dp else endPaddingDp
                        ),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    recalculatedData.forEachIndexed { index, bar ->
                        if (index.mod(countRatio) == 0 || dataCount == index + 1) {
                            BasicText(
                                modifier = mod,
                                text = bar.label,
                                maxLines = 1,
                                style = labelProperties.textStyle,
                                overflow = TextOverflow.Visible,
                                softWrap = false,
                            )
                        }
                    }
                }
            }

            Spacer(Modifier.height(((labelMaxWidthPx + 3) / density.density).dp))

            //Добавляем легенду
            if (labelHelperProperties.enabled) {
                if (dataCount == 0) {
                    Spacer(Modifier.height(textMeasurer.measure("none", labelProperties.textStyle).size.height.dp))
                }
                RCCPicketLabelHelper(data = recalculatedData, textStyle = labelHelperProperties.textStyle)
            }

            //Если отношение не один к одному добавляем подпись
            if (ration != 1 && dataCount != 0) {
                Spacer(Modifier.height(XAxisPadding))
                BasicText(
                    text = "* один столбец - $ration шт. пикетов",
                    style = MaterialTheme.typography.labelSmall,
                )
            }
        }
    }
}
