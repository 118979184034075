package ru.novasoft.roads.compose_client.core.ui.chart

import androidx.compose.animation.core.AnimationSpec
import androidx.compose.animation.core.tween
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.text.BasicText
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Rect
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.TransformOrigin
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.layout.LayoutCoordinates
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.text.TextMeasurer
import androidx.compose.ui.text.drawText
import androidx.compose.ui.text.rememberTextMeasurer
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.*
import androidx.compose.ui.window.PopupPositionProvider
import kotlinx.coroutines.launch
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.XAxisPadding
import ru.novasoft.roads.compose_client.core.ui.chart.components.RCChartLabelHelper
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.*
import ru.novasoft.roads.compose_client.core.ui.chart.models.*
import ru.novasoft.roads.compose_client.core.ui.chart.utils.ImplementRCAnimation
import ru.novasoft.roads.compose_client.core.ui.chart.utils.calculateOffset
import kotlin.math.absoluteValue
import kotlin.math.ceil
import kotlin.math.floor

private const val MAX_PRECISION_ERROR = 0.005

@Composable
fun StackedBarChart(
    modifier: Modifier = Modifier,
    /** Все DataValues должны быть неотрицательными */
    data: List<Bars>,
    barProperties: BarProperties = BarProperties(),
    onBarClick: ((Bars.Data) -> Unit)? = null,
    /** Св-ва подписей на абсциссе */
    labelProperties: LabelProperties = LabelProperties(
        textStyle = MaterialTheme.typography.labelSmall,
        padding = 0.dp,
        enabled = true,
        rotationDegreeOnSizeConflict = 90f
    ),
    /** Св-ва подписей на ординате */
    indicatorProperties: HorizontalIndicatorProperties = HorizontalIndicatorProperties(
        textStyle = MaterialTheme.typography.labelSmall,
        padding = XAxisPadding,
        count = 11
    ),
    /** Св-ва осей координат */
    dividerProperties: DividerProperties = DividerProperties(),
    gridProperties: GridProperties = GridProperties(enabled = false),
    /** Св-ва легенды */
    labelHelperProperties: LabelHelperProperties = LabelHelperProperties(
        textStyle = MaterialTheme.typography.labelSmall
    ),
    /** Задержка в появлении бара. Аргумент - индекс бара */
    animationMode: AnimationMode = AnimationMode.Together { 100L },
    /** Спецификация анимации, указывающая длительность и тип анимации */
    animationSpec: AnimationSpec<Float> = tween(500),
    animationDelay: Long = 100,
    textMeasurer: TextMeasurer = rememberTextMeasurer(),
    /** Св-ва для всплывающего окна, которое отображает данные при наведении или клике на столбец */
    popupProperties: PopupProperties = PopupProperties(
        textStyle = MaterialTheme.typography.labelSmall
    ),
    maxValueInitial: Double = data.maxOfOrNull { it.values.sumOf { it.value } } ?: 0.0,
    minValue: Double = 0.0,
    /** Максимальное кол-во строк в подписях на абсциссе */
    maxLines: Int = 1
) {
    LaunchedEffect(data) {
        require(data.all { it.values.isNotEmpty() }) {
            "Пустой список Bars.values"
        }
        require(data.isNotEmpty()) {
            "Chart data is empty"
        }
        require(data.all { it.values.all { it.value >= 0 } }) {
            "Some of chart data is below zero"
        }
        val tempCount = data.firstOrNull()?.values?.count() ?: 0
        require(data.all { it.values.count() == tempCount }) {
            "Размер списков для всех столбцов должен быть равным"
        }
        require(maxValueInitial
                >= data.maxOf { it.values.sumOf { it.value } }) {
            "Chart data must be at most $maxValueInitial (Specified Max Value)"
        }
        require(minValue <= 0) {
            "Min value in column chart must be 0 or lower."
        }
    }

    val density = LocalDensity.current
    val (recalculatedData, ration) = data.recalculateWithMultiple()
    val maxValue = recalculatedData.maxOfOrNull { it.values.sumOf { it.value } } ?: 0.0

    /** Столбец + его данные */
    val barWithRect = remember {
        mutableStateMapOf<Rect, Bars.Data>()
    }

    /** Выбранное значение */
    val selectedValue = remember {
        mutableStateOf<SelectedBar?>(null)
    }

    val indicators = remember(recalculatedData) {
        split(
            count = indicatorProperties.count,
            minValue = minValue,
            maxValue = maxValue
        )
    }
    val maxIndicatorWidth = remember(recalculatedData) { mutableStateOf(0) }
    val indicatorAreaWidth: Float = remember(recalculatedData) {
        if (indicatorProperties.enabled) {
            indicators.maxOf {
                textMeasurer.measure(indicatorProperties.contentBuilder(it)).size.width.also { if (maxIndicatorWidth.value < it) maxIndicatorWidth.value = it }
            } + (indicatorProperties.padding.value * density.density)
        } else {
            0f
        }
    }

    /** Расстояние от края канваса до ординаты */
    val xPadding: Float = remember(recalculatedData) {
        if (indicatorProperties.enabled && indicatorProperties.position == IndicatorPosition.Horizontal.Start) {
            indicatorAreaWidth
        } else {
            0f
        }
    }

    val chartWidth = remember {
        mutableFloatStateOf(0f)
    }

    val dataCount = recalculatedData.count()
    val firstBarX = remember { mutableFloatStateOf(0f) }
    val lastBarRightSideX = remember { mutableFloatStateOf(0f) }
    val commonWidth = remember { mutableFloatStateOf(0f) }
    val everyBarWidth = remember { mutableFloatStateOf(0f) }
    var labelMaxWidthPx = 0
    val tooltipState = rememberTooltipState(isPersistent = true)
    val canvasCoordinates = remember { mutableStateOf<LayoutCoordinates?>(null) }

    ImplementRCAnimation(
        data = recalculatedData,
        animationMode = animationMode,
        spec = { it.animationSpec ?: animationSpec },
        delay = animationDelay,
        before = {
            barWithRect.clear()
        }
    )
    CompositionLocalProvider(LocalLayoutDirection provides LayoutDirection.Ltr) {

        Column(modifier = modifier, verticalArrangement = Arrangement.SpaceBetween) {
            val scope = rememberCoroutineScope()
            Canvas(
                modifier = Modifier
                    .fillMaxSize()
                    .weight(1f)
                    .onGloballyPositioned { coordinates ->
                        canvasCoordinates.value = coordinates
                    }
                    .pointerInput(Unit) {
                        detectTapGestures(
                            onTap = {
                                val position = Offset(it.x, it.y)
                                barWithRect
                                    .firstNotNullOfOrNull { rect ->
                                        if (rect.key.contains(position)) rect else null
                                    }
                                    ?.let { (rect, bars) ->
                                        if (popupProperties.enabled) {
                                            selectedValue.value = SelectedBar(
                                                bar = bars,
                                                rect = rect,
                                                offset = Offset(
                                                    0f,
                                                    0f
                                                )
                                            )
                                        }
                                        scope.launch { tooltipState.show() }
                                        onBarClick?.invoke(bars)
                                    } ?: selectedValue.apply { value = null }
                            }
                        )
                    }
            ) {

                commonWidth.value = size.width
                val barsAreaWidth = size.width - (indicatorAreaWidth)
                chartWidth.value = barsAreaWidth


                /** Ширина столбцов */
                everyBarWidth.value = with(density) {
                    ((chartWidth.value - barProperties.spacing.toPx() * (dataCount + 1)) / dataCount.toFloat())
                        .coerceAtMost(barProperties.thickness.toPx())
                }

                /** Ордината нулевой абсциссы */
                val zeroY = size.height - calculateOffset(
                    maxValue = maxValue,
                    minValue = minValue,
                    total = size.height,
                    value = 0.0f
                ).toFloat()

                if (indicatorProperties.enabled) {
                    indicators.forEachIndexed { index, indicator ->
                        val measureResult =
                            textMeasurer.measure(
                                indicatorProperties.contentBuilder(indicator),
                                style = indicatorProperties.textStyle
                            )
                        val x = when (indicatorProperties.position) {
                            IndicatorPosition.Horizontal.Start -> (maxIndicatorWidth.value - measureResult.size.width).toFloat() / 2
                            IndicatorPosition.Horizontal.End -> barsAreaWidth + indicatorProperties.padding.value * density.density
                        }
                        drawText(
                            textLayoutResult = measureResult,
                            topLeft = Offset(
                                x = x,
                                y = (size.height - measureResult.size.height).spaceBetween(
                                    itemCount = indicators.count(),
                                    index
                                )
                            )
                        )
                    }
                }

                drawGridLines(
                    xPadding = xPadding,
                    size = size.copy(width = barsAreaWidth),
                    dividersProperties = dividerProperties,
                    indicatorPosition = indicatorProperties.position,
                    xAxisProperties = gridProperties.xAxisProperties,
                    yAxisProperties = gridProperties.yAxisProperties,
                    gridEnabled = gridProperties.enabled
                )

                recalculatedData.forEachIndexed oneBarScope@{ dataIndex: Int, accumBars: Bars ->
                    //После построения stacked столбца, следующий начинаем рисовать опять с нулевой ординаты
                    var prevY = zeroY

                    val barX = with(density) {
                        xPadding + dividerProperties.yAxisProperties.thickness.toPx() +
                                barProperties.spacing.toPx() * (dataIndex + 1) + everyBarWidth.value * dataIndex
                    }.also {
                        if (dataIndex == 0) {
                            firstBarX.value = it
                        } else if (dataIndex == dataCount - 1) {
                            lastBarRightSideX.value = it + everyBarWidth.value
                        }
                    }
                    val barsCount = accumBars.values.count()

                    accumBars.values.forEachIndexed pieceScope@{ pieceIndex, barDataPiece: Bars.Data ->
                        val isZeroDataCondition: (Double) -> Boolean = { it < MAX_PRECISION_ERROR }
                        if (isZeroDataCondition(barDataPiece.value)) return@pieceScope

                        val animateValue = barDataPiece.animator.value
                        val isLastElementCondition = pieceIndex + 1 == barsCount
                        val areRemainingNumsZeroCondition =
                            if (isLastElementCondition) true
                            else {
                                accumBars.values.subList(fromIndex = pieceIndex + 1, toIndex = barsCount)
                                    .all { isZeroDataCondition(it.value) }
                            }

                        val barHeight: Double =
                            ((barDataPiece.value * size.height) / (maxValue - minValue)) * animateValue
                        prevY -= barHeight.toFloat().coerceAtLeast(0f)

                        val rect = Rect(
                            offset = Offset(
                                x = barX,
                                y = prevY
                            ),
                            size = Size(
                                width = everyBarWidth.value,
                                height = barHeight.absoluteValue.toFloat()
                            ),
                        )
                        val path = Path()
                        val resultRadius = (everyBarWidth.value.toDp() / 2).coerceAtMost(10f.toDp())
                        val radius = if (isLastElementCondition || areRemainingNumsZeroCondition)
                            Bars.Data.Radius.Rectangle(topLeft = resultRadius, topRight = resultRadius)
                        else null

                        path.addRoundRect(
                            rect = rect,
                            radius = radius ?: barDataPiece.properties?.cornerRadius ?: barProperties.cornerRadius
                        )
                        drawPath(
                            path = path,
                            brush = barDataPiece.color,
                            alpha = 1f,
                            style = (barDataPiece.properties?.style
                                ?: barProperties.style).getStyle(density.density)
                        )
                        if (animateValue == 1f) {
                            if (barWithRect[rect] == null) barWithRect[rect] = barDataPiece
                            if (areRemainingNumsZeroCondition) return@oneBarScope
                        }

                    }
                }
            }

            selectedValue.value?.let { selectedValue ->
                val barLocalCoordinates = selectedValue.rect.topRight
                val barOffset = canvasCoordinates.value
                    ?.localToWindow(Offset(barLocalCoordinates.x, barLocalCoordinates.y))
                    ?: Offset.Zero

                TooltipBox(
                    positionProvider = exactTooltipPositionProvider(
                        IntOffset(
                            x = barOffset.x.toInt(),
                            y = barOffset.y.toInt()
                        )
                    ),
                    tooltip = {
                        PlainTooltip {
                            Text((selectedValue.bar.value.format(1)), style = MaterialTheme.typography.labelSmall)
                        }
                    },
                    state = tooltipState,
                ) {}
            }

            //Добавляем надписи на абсциссу
            if (labelProperties.enabled && commonWidth.value != 0f) {
                Spacer(modifier = Modifier.height(labelProperties.padding))
                val labelSize: IntSize = recalculatedData.firstOrNull()?.let {
                    textMeasurer.measure(it.label, style = labelProperties.textStyle, maxLines = maxLines).size
                } ?: IntSize(0, 0)
                labelMaxWidthPx = recalculatedData.maxOfOrNull {
                    textMeasurer.measure(it.label, style = labelProperties.textStyle, maxLines = maxLines).size.width
                } ?: 0
                //Максимальное кол-во надписей на абсциссе
                val maxAbscissaLabelCount =
                    floor((commonWidth.value / labelSize.width / density.density).dp.value).toInt() - 1
                val countRatio = ceil(dataCount.toFloat() / maxAbscissaLabelCount).toInt()

                val widthModifier =
                    if (indicatorProperties.position == IndicatorPosition.Horizontal.End) {
                        Modifier.width((chartWidth.value / density.density).dp)
                    } else {
                        Modifier.fillMaxWidth()
                    }

                //Корректирующая величина для центровки надписи и столбца графика.
                val t = everyBarWidth.value * 0.5 - labelSize.width * 0.5
                val startPaddingDp = ((firstBarX.value + t) / density.density).dp
                val endPaddingDp = ((commonWidth.value - lastBarRightSideX.value + t) / density.density).dp
                val mod = Modifier
                    .graphicsLayer {
                        rotationZ = labelProperties.rotationDegreeOnSizeConflict
                        transformOrigin = TransformOrigin(0f, 1f)
                    }
                    .requiredWidth((labelSize.width / density.density).dp)


                Row(
                    modifier = widthModifier
                        .fillMaxWidth()
                        .padding(
                            start = startPaddingDp,
                            end = if (endPaddingDp < 0.dp) 0.dp else endPaddingDp
                        ),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    recalculatedData.forEachIndexed { index, bar ->
                        if (index.mod(countRatio) == 0 || dataCount == index + 1) {
                            BasicText(
                                modifier = mod,
                                text = bar.label,
                                maxLines = maxLines,
                                style = labelProperties.textStyle,
                                overflow = TextOverflow.Visible,
                            )
                        }
                    }
                }
            }

            //Добавляем легенду
            if (labelHelperProperties.enabled) {
                Spacer(Modifier.height(((labelMaxWidthPx + 3) / density.density).dp))
                RCChartLabelHelper(data = recalculatedData, textStyle = labelHelperProperties.textStyle)
            }

            //Если отношение не один к одному добавляем подпись
            if (ration != 1) {
                Spacer(Modifier.height(XAxisPadding))
                BasicText(
                    text = "* один столбец - $ration шт. пикетов",
                    style = MaterialTheme.typography.labelSmall,
                )
            }
        }
    }
}


/**
 * Разбивает данные в списке Bars кратно константе MAX_BARS_COUNT. Для первого столбца лейбл присваивается по первому
 * столбцу изначальных данных. Для остальных - по последнему в группе (группа из MAX_BARS_COUNT элементов).
 * Данные суммируются по их индексу в листе values.
 * Также, если ration не равен единице, добавляем каждому групповому столбцу groupLabel с началом группы и концом. Если
 * в последней группе один элемент (кейс посдеднего элемента), то первый будет равен null
 * */
internal fun List<Bars>.recalculateWithMultiple(): Pair<List<Bars>, Int> {
    val dataCount = this.count()
    if (dataCount == 0) return emptyList<Bars>() to 0
    val ration = ceil(dataCount.toFloat() / MAX_BARS_COUNT).toInt()
    if (ration == 1)
        return this to 1

    val label = StringBuilder()
    val result: MutableList<Bars> = mutableListOf()
    val accumulate: MutableList<Double> = this.first().values.map { 0.0 }.toMutableList()
    val groupLabel: Array<String?> = arrayOf(null, null)
    this.forEachIndexed { index, bars: Bars ->
        val firstInGroupCondition = index.mod(ration) == 0
        val lastInGroupCondition = (index + 1).mod(ration) == 0
        val lastCondition = (index + 1) == dataCount
        val lastInGroupOrLastCondition = lastInGroupCondition || lastCondition

        if (firstInGroupCondition && !lastCondition) groupLabel[0] = bars.label
        if (lastInGroupOrLastCondition) groupLabel[1] = bars.label

        bars.values.forEachIndexed { i, data: Bars.Data ->
            accumulate[i] += data.value
        }

        if (index == 0) label.clear().append(bars.label)
        if (lastInGroupOrLastCondition) {
            if ((index + 1) != ration) label.clear().append(bars.label)
            result.add(
                Bars(
                    label.toString(),
                    bars.values.mapIndexed { bi, barsData -> barsData.copyWithAnotherVal(accumulate[bi] / ration) },
                    groupLabel.toList()
                )
            )
            for (i in accumulate.indices) accumulate[i] = 0.0
            groupLabel[0] = null
            groupLabel[1] = null
        }
    }
    return result to ration
}


fun exactTooltipPositionProvider(offset: IntOffset): PopupPositionProvider {
    return object : PopupPositionProvider {
        override fun calculatePosition(
            anchorBounds: IntRect,
            windowSize: IntSize,
            layoutDirection: LayoutDirection,
            popupContentSize: IntSize
        ): IntOffset = offset
    }
}