package src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs

import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.ExperimentalMaterialApi
import androidx.compose.material.FilterChip
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.scale
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.style.TextOverflow

@OptIn(ExperimentalMaterialApi::class)
@Composable
fun DetailingFilterChip(
    clickAction: () -> Unit,
    text: String,
    icon: ImageVector,
    iconDescription: String = ""
) {
    val iconMode = Modifier.clip(CircleShape).scale(0.7f)

    FilterChip(
        selected = false,
        onClick = clickAction,
        leadingIcon = {
            Icon(
                icon,
                contentDescription = iconDescription,
                modifier = iconMode
            )
        },
        content = {
            Text(
                text = text,
                style = MaterialTheme.typography.labelMedium,
                overflow = TextOverflow.Ellipsis,
                maxLines = 1
            )
        },
    )
}