package src.novasoft.roads.compose_client.feature.menu.contracts

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.mohamedrejeb.compose.dnd.DragAndDropContainer
import com.mohamedrejeb.compose.dnd.rememberDragAndDropState
import novasoft.roads.dto.client_common.ContractDto
import novasoft.roads.dto.client_common.FolderDto
import org.kodein.di.compose.localDI
import org.kodein.di.compose.rememberDI
import org.kodein.di.compose.subDI
import org.kodein.di.instance
import ru.novasoft.roads.compose_client.core.model.mapping.toContractModel
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Spacing.defaultSpaceBetweenCards
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.defaultPaddingWindow
import ru.novasoft.roads.compose_client.core.ui.menu.MenuPageTemplate
import ru.novasoft.roads.compose_client.core.ui.menu.PageHeaderView
import src.novasoft.roads.compose_client.feature.menu.contracts.contractCard.ContractCardView
import src.novasoft.roads.compose_client.feature.menu.contracts.folderCard.FolderCardVIew

data class ContractsDragAndDropData(
    val contract: ContractDto? = null,
    val folder: FolderDto? = null,
)

@Composable
fun ContractsView(onContractClick: (contract: ContractDto) -> Unit) {
    subDI(
        parentDI = localDI(),
        diBuilder = { import(contractsDIModule()) }
    ) {
        val contractsViewModel: ContractsViewModel by rememberDI { instance() }
        MenuPageTemplate(
            pageName = "Контракты",
            pageHeader = { PageHeaderView(it, contractsViewModel) },
            pageContent = { ContractsContentView(contractsViewModel, onContractClick) },
        )
    }
}

@OptIn(ExperimentalLayoutApi::class)
@Composable
private fun ContractsContentView(
    contractsViewModel: ContractsViewModel,
    onContractClick: (contract: ContractDto) -> Unit
) {
    val dragAndDropState = rememberDragAndDropState<ContractsDragAndDropData>()
    DragAndDropContainer(state = dragAndDropState) {
        FlowRow(
            modifier = Modifier
                .fillMaxWidth()
                .padding(defaultPaddingWindow)
                .background(MaterialTheme.colorScheme.surfaceContainer)
                .verticalScroll(rememberScrollState()),
            horizontalArrangement = Arrangement.spacedBy(defaultSpaceBetweenCards, Alignment.CenterHorizontally),
            verticalArrangement = Arrangement.spacedBy(defaultSpaceBetweenCards, Alignment.Top),
        ) {
            contractsViewModel.state.folders.forEach { folder ->
                FolderCardVIew(
                    folder,
                    dragAndDropState,
                    onClick = {
                        contractsViewModel.onEvent(
                            ContractsUiEvent.FolderClicked(folder)
                        )
                    },
                    actions = contractsViewModel.getFolderCardActions(folder),
                    onAction = { contractsViewModel.onEvent(it) },
                    onDrop = { data, targetFolder ->
                        contractsViewModel.onEvent(
                            ContractsUiEvent.SomethingDroppedOnFolder(data, targetFolder)
                        )
                    }
                )
            }
            contractsViewModel.state.contracts.forEach { contract ->
                ContractCardView(
                    contract,
                    contractsViewModel.getContractParams(contract.contractId),
                    dragAndDropState,
                    actions = contractsViewModel.getContractCardActions(contract),
                    onAction = { contractsViewModel.onEvent(it) },
                    onClick = {
                        contractsViewModel.onEvent(
                            ContractsUiEvent.ContractClicked(contract)
                        )
                    }
                )
            }
        }

    }

    val openRenameFolderDialog = remember { mutableStateOf(false) }
    val renamingFolder = remember { mutableStateOf<FolderDto?>(null) }
    if (openRenameFolderDialog.value)
        EditFolderDialog(renamingFolder.value!!, { openRenameFolderDialog.value = false }) { folderId, newName ->
            openRenameFolderDialog.value = false
            contractsViewModel.onEvent(ContractsUiEvent.RenameFolder(folderId, newName))
        }

    val openEditContractDialog = remember { mutableStateOf(false) }
    val editingContract = remember { mutableStateOf<ContractDto?>(null) }
    if (openEditContractDialog.value)
        EditContractDialog(
            editingContract = editingContract.value!!,
            onDismissRequest = { openEditContractDialog.value = false },
            customers = contractsViewModel.getAllCustomers(),
        ) { newContractDto ->
            openEditContractDialog.value = false
            contractsViewModel.onEvent(ContractsUiEvent.EditContract(newContractDto))
        }

    LaunchedEffect(Unit) {
        contractsViewModel.events.collect { event ->
            if (event !is ContractsAppEvent)
                return@collect

            when (event) {
                is ContractsAppEvent.OpenedContractState -> {
                    val openingContract = event.openedContract!!
                    contractsViewModel.appStateInfoRepo.setOpenedContract(toContractModel(openingContract))
                    onContractClick(openingContract)
                }

                is ContractsAppEvent.RenameFolder -> {
                    renamingFolder.value = event.renamingFolder
                    openRenameFolderDialog.value = true
                }

                is ContractsAppEvent.EditContract -> {
                    editingContract.value = event.editingContract
                    openEditContractDialog.value = true
                }
            }
        }
    }
}