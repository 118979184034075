package novasoft.roads.util

import kotlin.math.max
import kotlin.math.min

data class DateSliceModel(
    var from: Long,
    var to: Long,
) {
    fun getOverlap(dateSlice: DateSliceModel): Long {
        val start = maxOf(from, dateSlice.from)
        val end = minOf(to, dateSlice.to)
        return end - start
    }

    fun getYears(): List<Int> = (min(from, to).toLocalDate().year..max(from, to).toLocalDate().year).toList()

    fun getDuration(): Long = max(from, to) - min(from, to)

    fun overlaps(dateSlice: DateSliceModel): Boolean = getOverlap(dateSlice) > 0L

    override fun toString() = "${from.toDateString()} - ${to.toDateString()}"
}