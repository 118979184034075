package ru.novasoft.roads.compose_client.core.model.model.table

import ru.novasoft.roads.compose_client.core.model.model.AmountAndTotalCost


class ForecastStatusRow(
    private val name: String? = null,
    private val planTotalVol: AmountAndTotalCost? = null,
    private val factVol: AmountAndTotalCost? = null,
    private val mainUnitAbbr: String = "",
    private val runRateValue: Int? = null,
) {
    fun getMonth(): String = name ?: ""

    fun getTotalPlan(isRubles: Boolean): Float =
        if (isRubles) planTotalVol?.totalCost?.toFloat() ?: 0f else planTotalVol?.amount?.toFloat() ?: 0f

    fun getFact(isRubles: Boolean): Float =
        if (isRubles) factVol?.totalCost?.toFloat() ?: 0f else factVol?.amount?.toFloat() ?: 0f

    fun getUnit(isRubles: Boolean) = if (isRubles) RUBLES_ABBREVIATION else mainUnitAbbr

    fun getRR(): Float = runRateValue?.toFloat() ?: 0f
}
