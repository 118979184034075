package ru.novasoft.roads.compose_client.core.ui.table.components

import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Modifier
import ru.novasoft.roads.compose_client.core.ui.table.mappers.Filter
import ru.novasoft.roads.compose_client.core.ui.table.mappers.TableColumn
import ru.novasoft.roads.compose_client.core.ui.table.utils.PaginationProperties
import ru.novasoft.roads.compose_client.core.ui.table.utils.TableState

@Composable
fun <T> RowScope.TopMenu(
    combinedFilters: List<Filter<T>>,
    state: MutableState<TableState>,
    tableColumns: List<TableColumn<T>>,
    paginationProperties: PaginationProperties
) {
    LazyRow(modifier = Modifier.Companion.weight(1f)) {
        items(combinedFilters.size) { index ->
            if (state.value.filterVisibility[index]) {
                val filter = combinedFilters[index]
                val filterComposable = filter.getFilterComposable()
                filterComposable()
            }
        }
    }
    TableDropDownMenu(
        state,
        tableColumns,
        combinedFilters,
        paginationProperties.isEnabled
    )
}