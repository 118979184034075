import common.RoadsAppEvent
import common.RoadsUiEvent

sealed class SpaMenuUiEvent : RoadsUiEvent {
    data class MainMenuItemClicked(val menuItem: MenuItem) : SpaMenuUiEvent()
    data class DropdownMenuItemClicked(val parent: MenuItem, val child: MenuItem): SpaMenuUiEvent()
}

sealed class SpaMenuAppEvent : RoadsAppEvent {
    data class UpdateMenuWidth(val setExtendedWidth: Boolean): SpaMenuAppEvent()
}