package ru.novasoft.roads.compose_client.core.ui.chart

import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.AnimationSpec
import androidx.compose.animation.core.AnimationVector1D
import androidx.compose.animation.core.tween
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.gestures.detectDragGestures
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.text.BasicText
import androidx.compose.runtime.*
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.*
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.TransformOrigin
import androidx.compose.ui.graphics.drawscope.DrawScope
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.input.pointer.PointerInputChange
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.text.TextMeasurer
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.drawText
import androidx.compose.ui.text.rememberTextMeasurer
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.*
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import novasoft.roads.util.FormatUtils.formatToAmountAbbr
import ru.novasoft.roads.compose_client.core.ui.chart.components.RCChartLabelHelper
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.addRoundRect
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.spaceBetween
import ru.novasoft.roads.compose_client.core.ui.chart.extensions.split
import ru.novasoft.roads.compose_client.core.ui.chart.models.*
import ru.novasoft.roads.compose_client.core.ui.chart.utils.ImplementRCAnimation
import ru.novasoft.roads.compose_client.core.ui.chart.utils.calculateOffset
import kotlin.math.absoluteValue

internal fun DrawScope.drawPopup(
    selectedBar: SelectedBar,
    properties: PopupProperties,
    textMeasurer: TextMeasurer,
    progress: Float,
    contentBuilder: (Double) -> String = { properties.contentBuilder(it) }
) {
    val measure = textMeasurer.measure(
        contentBuilder(selectedBar.bar.value),
        style = properties.textStyle.copy(
            color = properties.textStyle.color.copy(
                alpha = 1f * progress
            )
        )
    )

    val textSize = measure.size.toSize()
    val popupSize = Size(
        width = (textSize.width + (properties.contentHorizontalPadding.toPx() * 2)),
        height = textSize.height + properties.contentVerticalPadding.toPx() * 2
    )
    val value = selectedBar.bar.value
    val barRect = selectedBar.rect
    val barWidth = barRect.right - barRect.left
    val barHeight = barRect.bottom - barRect.top
    var popupPosition = selectedBar.offset.copy(
        y = selectedBar.offset.y - popupSize.height + barHeight / 10,
        x = selectedBar.offset.x + barWidth / 2
    )
    if (value < 0) {
        popupPosition = popupPosition.copy(
            y = selectedBar.offset.y - barHeight / 10
        )
    }
    val outOfCanvas = popupPosition.x + popupSize.width > size.width
    if (outOfCanvas) {
        popupPosition = popupPosition.copy(
            x = (selectedBar.offset.x - popupSize.width) + barWidth / 2
        )
    }
    val cornerRadius =
        CornerRadius(
            properties.cornerRadius.toPx(),
            properties.cornerRadius.toPx()
        )
    drawPath(
        path = Path().apply {
            addRoundRect(
                RoundRect(
                    rect = Rect(
                        offset = popupPosition,
                        size = popupSize.copy(
                            width = popupSize.width * progress
                        ),
                    ),
                    topRight = if (value < 0 && outOfCanvas) CornerRadius.Zero else cornerRadius,
                    topLeft = if (value < 0 && !outOfCanvas) CornerRadius.Zero else cornerRadius,
                    bottomRight = if (value > 0 && outOfCanvas) CornerRadius.Zero else cornerRadius,
                    bottomLeft = if (value > 0 && !outOfCanvas) CornerRadius.Zero else cornerRadius
                )
            )
        },
        color = properties.containerColor,
    )
    drawText(
        textLayoutResult = measure,
        topLeft = popupPosition.copy(
            x = popupPosition.x + properties.contentHorizontalPadding.toPx(),
            y = popupPosition.y + properties.contentVerticalPadding.toPx()
        ),
    )
}


@Composable
fun ColumnChart(
    modifier: Modifier = Modifier,
    data: List<Bars>,
    barProperties: BarProperties = BarProperties(),
    onBarClick: ((Bars.Data) -> Unit)? = null,
    onBarLongClick: ((Bars.Data) -> Unit)? = null,
    labelProperties: LabelProperties = LabelProperties(
        textStyle = TextStyle.Default,
        enabled = true
    ),
    indicatorProperties: HorizontalIndicatorProperties = HorizontalIndicatorProperties(
        textStyle = TextStyle.Default
    ),
    dividerProperties: DividerProperties = DividerProperties(),
    gridProperties: GridProperties = GridProperties(),
    labelHelperProperties: LabelHelperProperties = LabelHelperProperties(),
    animationMode: AnimationMode = AnimationMode.Together { it * 200L },
    animationSpec: AnimationSpec<Float> = tween(500),
    animationDelay: Long = 100,
    textMeasurer: TextMeasurer = rememberTextMeasurer(),
    popupProperties: PopupProperties = PopupProperties(
        textStyle = TextStyle.Default.copy(
            color = Color.White,
            fontSize = 12.sp
        )
    ),
    barAlphaDecreaseOnPopup: Float = .4f,
    maxValue: Double = data.maxOfOrNull { it.values.maxOfOrNull { it.value } ?: 0.0 } ?: 0.0,
    minValue: Double = if (data.any { it.values.any { it.value < 0 } }) -maxValue else 0.0,
) {
    data.validateData(maxValue, minValue)

    val variables = ColumnChartVariables(
        data,
        barProperties,
        indicatorProperties,
        maxValue,
        minValue,
        textMeasurer,
        popupProperties,
        animationMode,
        animationSpec,
        animationDelay,
        onBarClick,
        onBarLongClick,
        dividerProperties,
        gridProperties,
        barAlphaDecreaseOnPopup
    ).apply { initVariables() }

    CompositionLocalProvider(LocalLayoutDirection provides LayoutDirection.Ltr) {
        Column(modifier = modifier) {
            if (labelHelperProperties.enabled) {
                RCChartLabelHelper(data = data, textStyle = labelHelperProperties.textStyle)
                Spacer(modifier = Modifier.height(24.dp))
            }
            Row(
                modifier = Modifier
                    .fillMaxSize()
                    .weight(1f)
            ) {
                Canvas(
                    modifier = Modifier
                        .getModifierWithGestures(variables)
                        .fillMaxSize(),
                    onDrawForCanvas(variables)
                )
            }
            if (labelProperties.enabled) {
                Spacer(modifier = Modifier.height(labelProperties.padding))

                val widthModifier =
                    if (indicatorProperties.position == IndicatorPosition.Horizontal.End) {
                        Modifier.width((variables.chartWidth.value / variables.density.density).dp)
                    } else {
                        Modifier.fillMaxWidth()
                    }

                val labelMeasures =
                    data.map { textMeasurer.measure(it.label, style = labelProperties.textStyle, maxLines = 1) }
                val labelWidths = labelMeasures.map { it.size.width }
                val maxLabelWidth = labelWidths.max()
                val minLabelWidth = labelWidths.min()

                var textModifier: Modifier = Modifier
                var shouldRotate = false
                if ((maxLabelWidth / minLabelWidth.toDouble()) >= 1.5) {
                    textModifier = textModifier.width((minLabelWidth / variables.density.density).dp)
                    shouldRotate = true
                }
                Row(
                    modifier = widthModifier
                        .padding(
                            start = (variables.xPadding / variables.density.density).dp,
                        ), horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    data.forEachIndexed { index, bar ->
                        BasicText(
                            modifier = if (shouldRotate) textModifier.graphicsLayer {
                                rotationZ = labelProperties.rotationDegreeOnSizeConflict
                                transformOrigin =
                                    TransformOrigin((labelMeasures[index].size.width / minLabelWidth.toFloat()), .5f)
                                translationX =
                                    -(labelMeasures[index].size.width - minLabelWidth.toFloat()) - minLabelWidth / 2
                            } else textModifier,
                            text = bar.label,
                            style = labelProperties.textStyle.copy(),
                            overflow = TextOverflow.Visible,
                            softWrap = false,
                        )
                    }
                }
            }

        }
    }
}

/** Проверка условий для входных данных столбчатого графика */
@Composable
fun List<Bars>.validateData(maxValue: Double, minValue: Double) {
    if (this.isEmpty()) return

    require(maxValue >= this.maxOf { it.values.maxOf { it.value } }) {
        "Chart data must be at most $maxValue (Specified Max Value)"
    }
    require(minValue <= 0) {
        "Min value in column chart must be 0 or lower."
    }
    require(minValue <= this.minOf { it.values.minOf { it.value } }) {
        "Chart data must be at least $minValue (Specified Min Value)"
    }
}

/** Организация управления и переменных состояния для столбчатого графика */
class ColumnChartVariables(
    val data: List<Bars>,
    val barProperties: BarProperties,
    val indicatorProperties: HorizontalIndicatorProperties,
    val maxValue: Double,
    val minValue: Double,
    val textMeasurer: TextMeasurer,
    val popupProperties: PopupProperties,
    val animationMode: AnimationMode,
    val animationSpec: AnimationSpec<Float>,
    val animationDelay: Long,
    val onBarClick: ((Bars.Data) -> Unit)?,
    val onBarLongClick: ((Bars.Data) -> Unit)?,
    var dividerProperties: DividerProperties,
    var gridProperties: GridProperties,
    var barAlphaDecreaseOnPopup: Float
) {
    lateinit var density: Density
    lateinit var scope: CoroutineScope
    var everyDataWidth: Float = 0f
    var averageSpacingBetweenBars: Double = 0.0
    lateinit var barWithRect: SnapshotStateList<Pair<Bars.Data, Rect>>
    lateinit var selectedValue: MutableState<SelectedBar?>
    lateinit var popupAnimation: Animatable<Float, AnimationVector1D>
    lateinit var indicators: List<Double>
    var xPadding: Float = 0f
    lateinit var chartWidth: MutableFloatState


    @Composable
    fun initVariables() {
        density = LocalDensity.current
        scope = rememberCoroutineScope()

        everyDataWidth = with(density) {
            data.maxOfOrNull { rowData ->
                rowData.values.map {
                    (it.properties?.thickness
                        ?: barProperties.thickness).toPx() + (it.properties?.spacing
                        ?: barProperties.spacing).toPx()
                }.sum()
            } ?: 0f
        }
        averageSpacingBetweenBars = with(density) {
            data.map { it.values }.flatten().map { (it.properties?.spacing ?: barProperties.spacing).toPx() }.average()
        }

        barWithRect = remember {
            mutableStateListOf()
        }

        selectedValue = remember {
            mutableStateOf(null)
        }

        popupAnimation = remember {
            Animatable(0f)
        }

        indicators = remember {
            split(
                count = indicatorProperties.count,
                minValue = minValue,
                maxValue = maxValue
            )
        }

        xPadding = remember { 0f }

        chartWidth = remember {
            mutableFloatStateOf(0f)
        }

        LaunchedEffect(selectedValue.value) {
            if (selectedValue.value != null) {
                delay(popupProperties.duration)
                popupAnimation.animateTo(0f, animationSpec = popupProperties.animationSpec)
                selectedValue.value = null
            }
        }

        ImplementRCAnimation(
            data = data,
            animationMode = animationMode,
            spec = { it.animationSpec ?: animationSpec },
            delay = animationDelay,
            before = {
                barWithRect.clear()
            }
        )
    }

    fun onTap(): ((Offset) -> Unit) = {
        val position = Offset(it.x, it.y)
        barWithRect
            .lastOrNull { (_, rect) ->
                rect.contains(position)
            }
            ?.let { (bar, rect) ->
                if (popupProperties.enabled) {
                    selectedValue.value = SelectedBar(
                        bar = bar,
                        rect = rect,
                        offset = Offset(
                            rect.left,
                            if (bar.value < 0) rect.bottom else rect.top
                        )
                    )
                    scope.launch {
                        popupAnimation.snapTo(0f)
                        popupAnimation.animateTo(
                            1f,
                            animationSpec = popupProperties.animationSpec
                        )
                    }
                }
                onBarClick?.invoke(bar)
            }
    }

    fun onLongPress(): ((Offset) -> Unit) = {
        val position = Offset(it.x, it.y)
        barWithRect
            .lastOrNull { (_, rect) ->
                rect.contains(position)
            }
            ?.let { (bar, _) ->
                onBarLongClick?.invoke(bar)
            }
    }

    fun onDrag(): (PointerInputChange) -> Unit = { change ->
        barWithRect
            .lastOrNull { (_, rect) ->
                change.position.x in rect.left..rect.right
            }
            ?.let { (bar, rect) ->
                selectedValue.value = SelectedBar(
                    bar = bar,
                    rect = rect,
                    offset = Offset(
                        rect.left,
                        if (bar.value < 0) rect.bottom else rect.top
                    )
                )
                scope.launch {
                    if (popupAnimation.value != 1f && !popupAnimation.isRunning) {
                        popupAnimation.animateTo(
                            1f,
                            animationSpec = popupProperties.animationSpec
                        )
                    }
                }
            }
    }
}


@Composable
fun Modifier.getModifierWithGestures(
    variables: ColumnChartVariables
): Modifier =
    this.pointerInput(Unit) {
        if (variables.popupProperties.enabled) {
            detectDragGestures { change, dragAmount ->
                variables.onDrag().invoke(change)
            }
        }
    }
        .pointerInput(Unit) {
            detectTapGestures(
                onTap = variables.onTap(),
                onLongPress = variables.onLongPress()
            )
        }

/** Рисование основного блока столбчатого графика */
fun onDrawForCanvas(variables: ColumnChartVariables): DrawScope.() -> Unit = {

    val barsAreaWidth = size.width
    variables.chartWidth.value = barsAreaWidth
    val zeroY = size.height - calculateOffset(
        maxValue = variables.maxValue,
        minValue = variables.minValue,
        total = size.height,
        value = 0.0f
    ).toFloat()


    //Подписи по оси y
    if (variables.indicatorProperties.enabled) {
        variables.indicators.forEachIndexed { index, indicator ->
            val measureResult =
                variables.textMeasurer.measure(
                    variables.indicatorProperties.contentBuilder(indicator),
                    style = variables.indicatorProperties.textStyle
                )
            val x = when (variables.indicatorProperties.position) {
                IndicatorPosition.Horizontal.Start -> 0f
                IndicatorPosition.Horizontal.End -> barsAreaWidth + variables.indicatorProperties.padding.value * variables.density.density
            }
            drawText(
                textLayoutResult = measureResult,
                topLeft = Offset(
                    x = x,
                    y = (size.height - measureResult.size.height).spaceBetween(
                        itemCount = variables.indicators.count(),
                        index
                    )
                )
            )
        }
    }

    //Примечание: если везде по одному столбцу, размещаем его в центре соответствующего деления
    val placeToCenter = variables.data.all { it.values.size == 1 }

    variables.data.forEachIndexed { dataIndex, columnChart ->
        columnChart.values.forEachIndexed { valueIndex, col ->

            val barHeight =
                ((col.value * size.height) / (variables.maxValue - variables.minValue)) * col.animator.value

            val stroke = (col.properties?.thickness ?: variables.barProperties.thickness).toPx()
            val barX: Float
            if (!placeToCenter) {
                val spacing = (col.properties?.spacing ?: variables.barProperties.spacing).toPx()

                val everyBarWidth = (stroke + spacing)

                barX =
                    (valueIndex * everyBarWidth) + (barsAreaWidth - variables.everyDataWidth).spaceBetween(
                        itemCount = variables.data.count(),
                        index = dataIndex
                    ) + variables.xPadding + (variables.averageSpacingBetweenBars / 2).toFloat()
            } else
                barX = (barsAreaWidth / variables.data.size) * (dataIndex + 0.5f) - 0.5f * stroke + variables.xPadding

            val rect = Rect(
                offset = Offset(
                    x = barX,
                    y = (zeroY - barHeight.toFloat().coerceAtLeast(0f))
                ),
                size = Size(width = stroke, height = barHeight.absoluteValue.toFloat()),
            )
            if (variables.barWithRect.none { it.second == rect }) variables.barWithRect.add(col to rect)
            val path = Path()

            val selectedRadius = variables.barProperties.cornerRadius
            val maxRadius =
                if (selectedRadius is Bars.Data.Radius.Rectangle)
                    maxOf(selectedRadius.topLeft.toPx(), selectedRadius.topRight.toPx())
                else 0f

            val resultRadius = (stroke.toDp() / 2).coerceAtMost(
                minOf(barHeight.absoluteValue.toFloat().toDp(), maxRadius.toDp())
            )
            val radius = Bars.Data.Radius.Rectangle(topLeft = resultRadius, topRight = resultRadius)

            path.addRoundRect(rect = rect, radius = radius)
            val alpha = if (rect == variables.selectedValue.value?.rect) {
                1f - (variables.barAlphaDecreaseOnPopup * variables.popupAnimation.value)
            } else {
                1f
            }
            drawPath(
                path = path,
                brush = col.color,
                alpha = alpha,
                style = (col.properties?.style
                    ?: variables.barProperties.style).getStyle(variables.density.density)
            )
        }
    }
    variables.selectedValue.value?.let { selectedValue ->
        drawPopup(
            selectedBar = selectedValue,
            properties = variables.popupProperties,
            textMeasurer = variables.textMeasurer,
            progress = variables.popupAnimation.value
        ) {
            formatToAmountAbbr(it.toLong(), digits = 1)
        }
    }
}