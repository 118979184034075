package novasoft.roads.util

import kotlin.math.PI
import kotlin.math.cos
import kotlin.math.sin
import kotlin.math.sqrt

object GeometricUtils {

    /** Конвертирует угол из градусов в радианы */
    fun degreesToRadians(angleInDegrees: Float): Double {
        return angleInDegrees * PI / 180
    }

    /**
     * Вычисляет длину основания равнобедренного треугольника по длине равных сторон и углу между ними
     * @param a длина равных сторон треугольника
     * @param fi угол между равными сторонами треугольника в градусах
     * */
    fun calcBaseOfIsoscelesTriangle(a: Double, fi: Float): Double {
        val radians = degreesToRadians(fi)
        return a * sqrt(2 * (1 - cos(radians)))
    }

    /**
     * Вычисляет длину катета прямоугольного треугольника по длине гипотенузы и противолежащему углу
     * @param c длина гипотенузы
     * @param alpha противолежащий искомому катету угол в градусах
     * */
    fun calcCathetus(c: Double, alpha: Float): Double {
        val angleInRadians = degreesToRadians(alpha)
        return c * sin(angleInRadians) // Формула для длины катета
    }

    /**
     * Вычисляет высоту второго треугольника в подобном треугольнике.
     * @param base1 основание первого подобного треугольника.
     * @param base2 основание второго подобного треугольника.
     * @param h1 высота первого подобного треугольника.
     */
    fun calcHeightInSimilarIsoscelesTriangle(base1: Double, base2: Double, h1: Double): Double = h1 * (base2 / base1)

    /**
     * Вычисляет высоту второго подобного треугольника по стороне первого, углу между равными сторонами и основанию второго.
     * @param a1 длина равных сторон первого треугольника
     * @param fi угол между равными сторонами треугольника в градусах
     * @param base2 основание второго подобного треугольника.
     */
    fun calcHeightInSimilarIsoscelesTriangle(a1: Double, fi: Float, base2: Double): Double {
        val base1 = calcBaseOfIsoscelesTriangle(a1, fi)
        val alpha = (180 - fi) / 2
        val h1 = calcCathetus(a1, alpha)
        val result = calcHeightInSimilarIsoscelesTriangle(base1, base2, h1)

        return result
    }
}