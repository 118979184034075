package ru.novasoft.roads.compose_client.core.ui.table.utils

import ru.novasoft.roads.compose_client.core.ui.table.TreeNode

fun <T> filterTreeNodes(nodes: List<TreeNode<T>>, filter: (T) -> Boolean): List<TreeNode<T>> {
    return nodes.mapNotNull { node ->
        val filteredChildren = filterTreeNodes(node.children, filter)
        if (filter(node.content) || filteredChildren.isNotEmpty()) {
            node.copy(children = filteredChildren)
        } else {
            null
        }
    }
}