package src.novasoft.roads.compose_client.feature.menu.help.treeView

import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.core.tween
import androidx.compose.foundation.VerticalScrollbar
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.rememberScrollbarAdapter
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.KeyboardArrowRight
import androidx.compose.material.icons.filled.KeyboardArrowDown
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.delay
import novasoft.roads.dto.client_common.HelpTextForDto
import ru.novasoft.roads.compose_client.core.designsystem.theme.unselectedColor

data class TreeNode(
    val id: String,
    val parentId: String,
    val name: String,
    var children: List<TreeNode> = listOf()
)

@Composable
fun TreeView(
    nodes: List<HelpTextForDto>,
    selectedNodeId: String,
    onClick: (String, String) -> Unit = { _, _ -> }
) {
    val treeNodes = buildTree(nodes)
    val listState = rememberLazyListState()
    var isScrolling by remember { mutableStateOf(false) }
    var showScrollbar by remember { mutableStateOf(false) }
    var selectedNodeState by remember { mutableStateOf(selectedNodeId) }

    LaunchedEffect(selectedNodeId) {
        selectedNodeState = selectedNodeId
    }

    LaunchedEffect(listState) {
        snapshotFlow { listState.isScrollInProgress }
            .collect { isScrolling = it }
    }

    LaunchedEffect(isScrolling) {
        if (isScrolling) {
            showScrollbar = true
        } else {
            delay(500)
            showScrollbar = false
        }
    }
    val scrollbarOpacity by animateFloatAsState(
        targetValue = if (showScrollbar) 1f else 0f,
        animationSpec = tween(durationMillis = 300)
    )
    Box(
        modifier = Modifier.fillMaxSize()
            .background(MaterialTheme.colorScheme.background)
            .clip(RoundedCornerShape(16.dp))
            .padding(vertical = 15.dp)
    ) {

        LazyColumn(state = listState, modifier = Modifier.fillMaxSize()) {
            items(treeNodes) { node ->
                TreeNodeView(node, onClick, selectedNodeId, 16.dp) { nodeId ->
                    selectedNodeState = nodeId
                }
            }
        }

        Box(
            modifier = Modifier
                .align(Alignment.CenterEnd)
                .graphicsLayer(alpha = scrollbarOpacity)
        ) {
            VerticalScrollbar(
                adapter = rememberScrollbarAdapter(listState),
                modifier = Modifier.fillMaxHeight()
            )
        }
    }
}

@Composable
fun TreeNodeView(
    node: TreeNode,
    onClick: (String, String) -> Unit = { _, _ -> },
    selectedNodeId: String,
    padding: Dp,
    onSelect: (String) -> Unit = {}
) {
    var expanded by remember { mutableStateOf(true) }
    val backgroundColor = if (node.id == selectedNodeId) MaterialTheme.colorScheme.surfaceVariant else MaterialTheme.colorScheme.unselectedColor
    Column {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Start,
            modifier = Modifier
                .fillMaxWidth()
                .padding(vertical = 4.dp)
                .clickable {
                    expanded = !expanded
                    onClick(node.name, node.id)
                    onSelect(node.name)
                }
                .background(color = backgroundColor)
        ) {
            if (node.children.isNotEmpty()) {
                Spacer(modifier = Modifier.width(padding - 24.dp))
                Icon(
                    imageVector = if (expanded) Icons.Default.KeyboardArrowDown else Icons.AutoMirrored.Filled.KeyboardArrowRight,
                    contentDescription = null,
                    modifier = Modifier.size(24.dp).padding(top = 4.dp)
                )
            } else{
                Spacer(modifier = Modifier.width(padding))
            }
            Text(node.name)
        }
        if (expanded) {
            Column {
                node.children.forEach { child ->
                    TreeNodeView(child, onClick, selectedNodeId, padding + 24.dp, onSelect)
                }
            }
        }
    }
}

