package ru.novasoft.roads.compose_client.core.ui.table.utils

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.KeyboardArrowLeft
import androidx.compose.material.icons.automirrored.filled.KeyboardArrowRight
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp

const val DEFAULT_PER_PAGE = 1000
const val DEFAULT_MAX_PAGE_INDEX_IN_ROW = 5

data class PaginationProperties(
    val isEnabled: Boolean = false,
    val perPage: Int = DEFAULT_PER_PAGE,
    val maxPageIndexInRow: Int = DEFAULT_MAX_PAGE_INDEX_IN_ROW,
)

@Composable
fun PaginationBar (
    state: MutableState<TableState>,
    pageAmount: Int,
    maxPageIndexInRow: Int,
    modifier: Modifier = Modifier
) {
    Column(modifier = modifier, horizontalAlignment = Alignment.CenterHorizontally) {
        LazyRow(
            modifier = Modifier
                .fillMaxWidth()
                .background(Color.Transparent)
                .height(36.dp),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        ) {
            item {
                IconButton(
                    onClick = {
                        if (state.value.pageNumberState > 1) {
                            state.value = state.value.copy(pageNumberState = state.value.pageNumberState - 1)
                            if (state.value.offsetState > 0) {
                                state.value = state.value.copy(offsetState = state.value.offsetState - 1)
                            }
                        }
                    },
                    modifier = Modifier.height(36.dp)
                ) {
                    Icon(
                        Icons.AutoMirrored.Filled.KeyboardArrowLeft,
                        contentDescription = "Назад",
                        modifier = Modifier.size(24.dp)
                    )
                }
            }

            items(maxPageIndexInRow) { index ->
                val currentIndex = index + state.value.offsetState
                println(currentIndex)
                IndexButton(
                    modifier = Modifier
                        .width(40.dp)
                        .fillMaxHeight(),
                    index = currentIndex,
                    onClick = {
                        state.value = state.value.copy(pageNumberState = it)
                    },
                    isSelected = currentIndex == state.value.pageNumberState - 1
                )
            }

            item {
                IconButton(
                    onClick = {
                        if (state.value.pageNumberState < pageAmount) {
                            state.value = state.value.copy(pageNumberState = state.value.pageNumberState + 1)
                            if (state.value.offsetState + maxPageIndexInRow < pageAmount) {
                                state.value= state.value.copy(offsetState = state.value.offsetState + 1)
                            }
                        }
                    },
                    modifier = Modifier.height(36.dp)
                ) {
                    Icon(
                        Icons.AutoMirrored.Filled.KeyboardArrowRight,
                        contentDescription = "Далее",
                        modifier = Modifier.size(24.dp)
                    )
                }
            }
        }
        Text(
            text = "Страница ${state.value.pageNumberState}/$pageAmount",
            modifier = Modifier.padding(top = 8.dp, start = 16.dp, end = 16.dp)
        )
    }
}

@Composable
fun IndexButton(
    modifier: Modifier,
    index: Int,
    onClick: (Int) -> Unit,
    isSelected: Boolean
) {
    Box(
        modifier = modifier
            .clickable { onClick(index + 1) }
            .background(
                if (isSelected) MaterialTheme.colorScheme.surfaceVariant
                else MaterialTheme.colorScheme.background
            ),
        contentAlignment = Alignment.Center
    ) {
        Text(
            text = "${index + 1}",
            fontSize = 16.sp,
            textAlign = TextAlign.Center
        )
    }
}

fun Int.ceilDiv(other: Int): Int {
    return (this + other - 1) / other
}