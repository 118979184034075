package ru.novasoft.roads.compose_client.core.ui.chart.speedometer

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import novasoft.roads.util.FormatUtils.formatToAmountAbbr
import novasoft.roads.util.FormatUtils.getDividerForAmount
import ru.novasoft.roads.compose_client.core.designsystem.theme.*
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Spacing.defaultSpacing
import ru.novasoft.roads.compose_client.core.ui.chart.DiagonalColorBox
import kotlin.math.*

data class RRData(
    val planTotal: Double = 0.0,
    val planTD: Double = 0.0,
    val fact: Double = 0.0
)


@Composable
fun RunRateSpeedometer(
    rrData: RRData,
    modifier: Modifier = Modifier,
) {
    val doneRatio = calculateDoneRatio(rrData)
    val planTDRatio = calculatePlanTDRatio(rrData)
    val min = min(doneRatio, planTDRatio)
    val max = max(planTDRatio, doneRatio)

    val planLong = rrData.planTotal.roundToLong()
    val planTDLong = rrData.planTD.roundToLong()
    val factLong = rrData.fact.roundToLong()

    val sections = mutableListOf<SpeedometerSection>()

    if (doneRatio > Float.MIN_VALUE && planTDRatio > Float.MIN_VALUE) {
        sections.add(
            SpeedometerSection(
                startOffset = 0f,
                endOffset = min,
                color = MaterialTheme.colorScheme.doneBrightColor
            ),
        )
    }

    if (planTDRatio > doneRatio) {
        sections.add(
            SpeedometerSection(
                startOffset = min,
                endOffset = planTDRatio,
                color = MaterialTheme.colorScheme.uiBrightRedColor
            ),
        )
    }

    if (planTDRatio < doneRatio) {
        sections.add(
            SpeedometerSection(
                startOffset = min,
                endOffset = doneRatio,
                color = MaterialTheme.colorScheme.reportedColor
            ),
        )
    }

    if ((1f - max).absoluteValue > Float.MIN_VALUE) {
        sections.add(
            SpeedometerSection(
                startOffset = max,
                endOffset = 1f,
                color = MaterialTheme.colorScheme.surfaceDim
            ),
        )
    }

    Row(
        Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceAround
    ) {
        Speedometer(
            modifier = modifier,
            sections = sections,
            speed = doneRatio * 100,
            speed2 = planTDRatio * 100,
            indicator2 = {
                LineIndicator(
                    color = MaterialTheme.colorScheme.planBrightColor.copy(alpha = 0.75f),
                    pathEffect = PathEffect.dashPathEffect(floatArrayOf(10f, 10f), 0f),
                    width = 2.5.dp
                )
            },
            ticks = if (planTDRatio in 0.1..0.9) mutableListOf(planTDRatio) else mutableListOf(),
            unit = "%",
            tickRotate = false,
            tickPadding = 40.dp,
            tickLabel = @Composable { _, tickSpeed ->
                Text(
                    modifier = Modifier
                        .background(MaterialTheme.colorScheme.onPrimary.copy(alpha = 0.6f)),
                    text = tickSpeed.roundToInt().toString() + "%",
                    style = MaterialTheme.typography.bodyLarge,
                )
            }
        )
        Spacer(Modifier.width(defaultSpacing))

        val isForward = doneRatio >= planTDRatio
        val style = MaterialTheme.typography.bodySmall
        val maxDivider = getDividerForAmount(listOf(planLong, planTDLong, factLong,).max())
        Row(
            modifier = Modifier.fillMaxSize(),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            Column {
                Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.spacedBy(8.dp)) {
                    Box(modifier = Modifier.size(10.dp).background(MaterialTheme.colorScheme.surfaceDim))
                    Text(text = "План всего:", maxLines = 1, style = style)
                }
                Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.spacedBy(8.dp)) {
                    if (isForward) Box(modifier = Modifier.size(10.dp).background(MaterialTheme.colorScheme.doneBrightColor))
                    else DiagonalColorBox(MaterialTheme.colorScheme.doneBrightColor, MaterialTheme.colorScheme.uiBrightRedColor)
                    Text(text = "План TD:", maxLines = 1, style = style)
                }
                Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.spacedBy(8.dp)) {
                    if (isForward) DiagonalColorBox(MaterialTheme.colorScheme.doneBrightColor, MaterialTheme.colorScheme.reportedColor)
                    else Box(modifier = Modifier.size(10.dp).background(MaterialTheme.colorScheme.doneBrightColor))
                    Text(text = "Выполнено:", maxLines = 1, style = style)
                }
                Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.spacedBy(8.dp)) {
                    if (isForward) Box(modifier = Modifier.size(10.dp).background(MaterialTheme.colorScheme.reportedColor))
                    else Box(modifier = Modifier.size(10.dp).background(MaterialTheme.colorScheme.uiBrightRedColor))
                    if (isForward)
                        Text(
                            text = "Опережение:",
                            maxLines = 1,
                            color = MaterialTheme.colorScheme.reportedColor,
                            style = style,
                        )
                    else
                        Text(
                            text = "Отставание:",
                            maxLines = 1,
                            color = MaterialTheme.colorScheme.redColor,
                            style = style,
                        )
                }

            }

            Column(horizontalAlignment = Alignment.End) {
                Text(
                    text = formatToAmountAbbr(planLong, maxDivider = maxDivider),
                    maxLines = 1,
                    style = style,
                    textAlign = TextAlign.End
                )
                Text(
                    text = formatToAmountAbbr(planTDLong, maxDivider = maxDivider),
                    maxLines = 1,
                    style = style,
                    textAlign = TextAlign.End
                )
                Text(
                    text = formatToAmountAbbr(factLong, maxDivider = maxDivider),
                    maxLines = 1,
                    style = style,
                    textAlign = TextAlign.End
                )
                Text(
                    text = "${((doneRatio - planTDRatio).absoluteValue * 100).roundToInt()}%",
                    maxLines = 1,
                    style = style,
                    textAlign = TextAlign.End,
                    color = if (isForward) MaterialTheme.colorScheme.reportedColor else MaterialTheme.colorScheme.redColor,
                )
            }
        }

    }


}

private fun calculatePlanTDRatio(rrData: RRData): Float {
    return calculateRatio(rrData.planTD, rrData.planTotal)
}

private fun calculateDoneRatio(rrData: RRData): Float {
    return calculateRatio(rrData.fact, rrData.planTotal)
}

private fun calculateRatio(value: Double, planTotal: Double) =
    if (value == 0.0) 0f else (value / planTotal).coerceIn(0.0, 1.0).toFloat()
