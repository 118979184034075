package ru.novasoft.roads.compose_client.core.ui.widgets

import androidx.compose.foundation.layout.*
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.BarChart
import androidx.compose.material.icons.filled.TableRows
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.layout
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.times
import novasoft.roads.dto.meter_progress.PlanTDnFactDto
import novasoft.roads.util.DateUtils.MONTH_RUSSIAN
import novasoft.roads.util.FormatUtils.formatBitDepth
import ru.novasoft.roads.compose_client.core.model.model.AmountAndTotalCost
import ru.novasoft.roads.compose_client.core.model.model.table.ForecastStatusRow
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.defaultPaddingCard
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Spacing.defaultSpaceBetweenCards
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Spacing.defaultSpacing
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.cardSizeBase
import ru.novasoft.roads.compose_client.core.ui.bricks.DualSwitcher
import ru.novasoft.roads.compose_client.core.ui.chart.ForecastChartValues
import ru.novasoft.roads.compose_client.core.ui.chart.LineColumnChart
import ru.novasoft.roads.compose_client.core.ui.chart.PlanFactStackedBarChart
import ru.novasoft.roads.compose_client.core.ui.effects.LoadingEffect
import ru.novasoft.roads.compose_client.core.ui.table.TreeNode
import ru.novasoft.roads.compose_client.core.ui.table.TreeTableFactory
import ru.novasoft.roads.compose_client.core.ui.table.mappers.TableColumn
import ru.novasoft.roads.compose_client.core.ui.widgets.ConstStrings.SECOND_SWITCHER_A
import ru.novasoft.roads.compose_client.core.ui.widgets.ConstStrings.SECOND_SWITCHER_B
import kotlin.math.roundToInt

data class ForecastStatusCardData(
    val total: AmountAndTotalCost,
    /** ЕИ, в которой измеряется amount в поле total */
    val unit: String,
    /** Доли от 0 до 1 по месяцам */
    val monthRatios: Map<Int, PlanTDnFactDto>,
    val forecast: Int? = null
)

object ConstStrings {
    const val SECOND_SWITCHER_A = "₽"
    const val SECOND_SWITCHER_B = "ЕИ"
}

@Composable
fun ForecastCardContent(
    title: String?,
    cardData: ForecastStatusCardData?,
) {
    val calculatedCardData = remember { mutableStateOf<List<Pair<String, ForecastChartValues>>?>(null) }
    val forecastTableRows = remember(cardData) { mutableStateOf<List<TreeNode<ForecastStatusRow>>>(emptyList()) }
    val isUISetToTable = remember { mutableStateOf(false) }
    val isMUnitSetToMU = remember { mutableStateOf(false) }
    val tableColumns = remember(isMUnitSetToMU.value) {
        mutableStateOf<List<TableColumn<ForecastStatusRow>>>(getTableColumns(isMUnitSetToMU.value))
    }

    ElevatedCard(
        modifier = Modifier
            .size(width = 4 * cardSizeBase + defaultSpaceBetweenCards, height = 300.dp),
        colors = CardDefaults.cardColors(containerColor = MaterialTheme.colorScheme.onPrimary),
        elevation = CardDefaults.elevatedCardElevation()
    ) {
        if (title == null || cardData == null || calculatedCardData.value == null) {
            LoadingEffect()
            return@ElevatedCard
        }

        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(defaultPaddingCard),
            verticalArrangement = Arrangement.spacedBy(5.dp, alignment = Alignment.Top)
        ) {
            Row(
                Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
            ) {
                Text(
                    modifier = Modifier.weight(1f),
                    text = title,
                    style = MaterialTheme.typography.headlineSmall,
                    textAlign = TextAlign.Left,
                    overflow = TextOverflow.Ellipsis,
                    maxLines = 1,
                )

                Row(
                    horizontalArrangement = Arrangement.spacedBy(defaultSpacing),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    DualSwitcher(
                        isUISetToTable,
                        { Icon(Icons.Filled.BarChart, "График", tint = MaterialTheme.colorScheme.primary) },
                        { Icon(Icons.Filled.TableRows, "Таблица", tint = MaterialTheme.colorScheme.primary) }
                    )

                    DualSwitcher(
                        isMUnitSetToMU,
                        { Text(text = SECOND_SWITCHER_A, style = MaterialTheme.typography.labelLarge) },
                        { Text(text = SECOND_SWITCHER_B, style = MaterialTheme.typography.labelLarge) }
                    )
                }
            }

            //Место для подзаголовка
            Text(text = "", style = MaterialTheme.typography.bodySmall)

            Row(
                Modifier.fillMaxHeight(),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                if (!isUISetToTable.value) {
                    //Левый график
                    Box(Modifier.fillMaxSize().weight(1f)){
                        PlanFactStackedBarChart(
                            100.0,
                            (cardData.monthRatios.values.sumOf { it.planTD } * 100.0).coerceAtMost(100.0),
                            (cardData.monthRatios.values.sumOf { it.fact } * 100.0).coerceAtMost(100.0),
                            "%",
                        )
                    }

                    Spacer(Modifier.width(defaultSpacing))

                    //Правый график
                    LineColumnChart(
                        modifier = Modifier.fillMaxSize().weight(5f),
                        data = calculatedCardData.value!!.ifEmpty { forgery },
                        leftYAxisName = if (isMUnitSetToMU.value) cardData.unit else "руб."
                    )
                } else {
                    TreeTableFactory(
                        tableColumns.value,
                        forecastTableRows.value.toMutableList()
                    )
                }
            }
        }
    }

    LaunchedEffect(isMUnitSetToMU, cardData) {
        if (isMUnitSetToMU.value || cardData == null) return@LaunchedEffect
        val vol = if (isMUnitSetToMU.value) cardData.total.amount else cardData.total.totalCost.toDouble()
        require(cardData.monthRatios.count() <= 12) { "Должно быть не более 12 месяцев в данных" }

        calculatedCardData.value = cardData.monthRatios.map { (monthNum, pf) ->
            val label = MONTH_RUSSIAN[monthNum]!!.substring(startIndex = 0, endIndex = 3)
            val rate = calcMonthRunRate(monthNum, cardData.monthRatios)
            val chartVol = ForecastChartValues(
                plan = pf.planTD * vol,
                fact = pf.fact * vol,
                runRate = rate.coerceAtLeast(0.0) * 100.0
            )
            label to chartVol
        }
    }
    //Генерация строк для таблицы Forecast
    LaunchedEffect(cardData) {
        if (cardData == null) return@LaunchedEffect
        val totalAmount = cardData.total.amount
        val totalCost = cardData.total.totalCost
        forecastTableRows.value = cardData.monthRatios.map { (monthNum, ratios) ->
            TreeNode(
                ForecastStatusRow(
                    MONTH_RUSSIAN[monthNum]!!,
                    AmountAndTotalCost(totalAmount * ratios.planTD, (totalCost * ratios.planTD).toLong()),
                    AmountAndTotalCost(totalAmount * ratios.fact, (totalCost * ratios.fact).toLong()),
                    cardData.unit,
                    (calcMonthRunRate(monthNum, cardData.monthRatios) * 100.0).roundToInt()
                )
            )
        }
    }
}

/** Если пришли пустые данные, вставляем заглушку для корректной отрисовки графика */
private val forgery = MONTH_RUSSIAN.values.map {
    Pair(
        it.substring(startIndex = 0, endIndex = 3),
        ForecastChartValues(0.0, 0.0, 0.0)
    )
}

//TODO(Спросить у Семёна что это за константа и почему от неё зависят показания RunRate)
private const val CHART_MAX_RATIO_LIMIT: Double = 2.0

private fun calcMonthRunRate(monthNum: Int, monthRatios: Map<Int, PlanTDnFactDto>): Double {
    val prevMonths = monthRatios.filter { it.key <= monthNum }.values
    val factSummary = prevMonths.sumOf { it.fact }
    val planSummary = prevMonths.sumOf { it.planTD }
    return when {
        factSummary <= Double.MIN_VALUE && planSummary > 0.0 -> -0.01
        factSummary <= Double.MIN_VALUE -> 0.0
        planSummary <= Double.MIN_VALUE -> CHART_MAX_RATIO_LIMIT
        else -> (factSummary / planSummary).coerceAtMost(CHART_MAX_RATIO_LIMIT)
    }
}

fun Modifier.scaledSize(scale: Float): Modifier = this.then(
    Modifier.layout { measurable, constraints ->
        val placeable = measurable.measure(constraints)
        val width = (placeable.width * scale).toInt()
        val height = (placeable.height * scale).toInt()
        val finalWidth = width.coerceIn(constraints.minWidth, constraints.maxWidth)
        val finalHeight = height.coerceIn(constraints.minHeight, constraints.maxHeight)

        layout(finalWidth, finalHeight) {
            val offsetX = (finalWidth - placeable.width) / 2
            val offsetY = (finalHeight - placeable.height) / 2
            placeable.placeRelativeWithLayer(offsetX, offsetY) {
                scaleX = scale
                scaleY = scale
            }
        }
    }
)

private fun getTableColumns(isMUnitSetToMU: Boolean) = listOf(
    object : TableColumn<ForecastStatusRow> {
        override fun getCellComposable(): @Composable BoxScope.(dto: ForecastStatusRow) -> Unit = {
            Text(
                it.getMonth(),
                style = MaterialTheme.typography.bodyMedium,
                textAlign = TextAlign.Start,
                modifier = Modifier.fillMaxWidth()
            )
        }
        override fun getColumnName() = "Месяц"
    },
    object : TableColumn<ForecastStatusRow> {
        override fun getCellComposable(): @Composable BoxScope.(dto: ForecastStatusRow) -> Unit = {
            Text(
                formatBitDepth(it.getTotalPlan(!isMUnitSetToMU), 0),
                style = MaterialTheme.typography.bodyMedium,
                textAlign = TextAlign.End,
                modifier = Modifier.fillMaxWidth()
            )
        }
        override fun getColumnName() = "План"
    },
    object : TableColumn<ForecastStatusRow> {
        override fun getCellComposable(): @Composable BoxScope.(dto: ForecastStatusRow) -> Unit = {
            Text(
                formatBitDepth(it.getFact(!isMUnitSetToMU), 0),
                style = MaterialTheme.typography.bodyMedium,
                textAlign = TextAlign.End,
                modifier = Modifier.fillMaxWidth()
            )
        }
        override fun getColumnName() = "Факт"
    },
    object : TableColumn<ForecastStatusRow> {
        override fun getCellComposable(): @Composable BoxScope.(dto: ForecastStatusRow) -> Unit = {
            Text(
                it.getUnit(!isMUnitSetToMU),
                style = MaterialTheme.typography.bodyMedium,
                textAlign = TextAlign.Start,
                modifier = Modifier.fillMaxWidth()
            )
        }
        override fun getColumnName() = "ЕИ"
    },
    object : TableColumn<ForecastStatusRow> {
        override fun getCellComposable(): @Composable BoxScope.(dto: ForecastStatusRow) -> Unit = {
            Text(
                formatBitDepth(it.getRR(), 0),
                style = MaterialTheme.typography.bodyMedium,
                textAlign = TextAlign.End,
                modifier = Modifier.fillMaxWidth()
            )
        }
        override fun getColumnName() = "RR, %"
    }
)
