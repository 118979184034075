package ru.novasoft.roads.compose_client.core.ui.editors

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier

@Composable
fun  ValidationWrapper(
    initialValue: String?,
    validation: List<EditorValidation> = emptyList(),
    content: @Composable (errText: MutableState<String?>, isError: MutableState<Boolean>, validate: (String?) -> String?) -> Unit
) {
    val validate = { s: String? -> validation.validateAll(s) }
    val errorTextState = remember { mutableStateOf(validate(initialValue)) }
    val isErrorState = remember { mutableStateOf(errorTextState.value != null) }
    content(errorTextState, isErrorState, validate)
}

@Composable
fun SupportingText(state: MutableState<String?>) {
    if (state.value != null) {
        Column(
            modifier = Modifier.fillMaxWidth(),
            horizontalAlignment = Alignment.Start,
        ) {
            Text(
                text = state.value!!,
                color = MaterialTheme.colorScheme.error,
                style = MaterialTheme.typography.bodySmall,
            )
        }
    }

}
