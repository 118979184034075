package ru.novasoft.roads.compose_client.core.ui.editors

import novasoft.roads.util.fromEditorToDouble

fun Iterable<EditorValidation>.validateAll(string: String?): String? {
    for (validator in this) {
        val error = validator.validate(string)
        if (error != null)
            return error
    }
    return null
}

sealed interface EditorValidation {

    /**
     * @return null - если валидация пройдена успешно, иначе - причину ошибки
     */
    fun validate(string: String?): String? {
        string ?: return errorMessage()
        if (check(string)) return null
        return errorMessage()
    }

    fun errorMessage(): String
    fun check(string: String): Boolean

    data object EmptinessValidation : EditorValidation {
        override fun errorMessage(): String = "Поле не может быть пустым"
        override fun check(string: String) = string.isNotBlank()
    }

    data object FormattedNumberValidation : EditorValidation {
        override fun errorMessage(): String = "Невозможно преобразовать значение поля в число"
        override fun check(string: String) = string.fromEditorToDouble() != null
    }

    data object FirstIsNotWhitespaceValidation : EditorValidation {
        override fun errorMessage() = "Первый символ не может быть пробелом"
        override fun check(string: String) = string.firstOrNull()?.isWhitespace()?.not() ?: true
    }

    data object LastIsNotWhitespaceValidation : EditorValidation {
        override fun errorMessage() = "Последний символ не может быть пробелом"
        override fun check(string: String) = string.lastOrNull()?.isWhitespace()?.not() ?: true
    }

}