package ru.novasoft.roads.compose_client.core.model.dto

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import kotlinx.serialization.json.Json
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi


/**
 * Обертка для ответа с сервера.
 * Имеет следующий формат обработки: для успешного, для неудачного и по умолчанию.
 *
 * Пример:
 *
 * res.onSuccess {
 *
 * functionForSuccess(it)
 *
 * }.onFailure { code, message ->
 *
 * functionForFailure(code, message)
 *
 * }.onDefault { data, code, message ->
 *
 * functionForDefault(data, code, message)
 *
 * }
 *
 * @param nullableData сами данные. R тип должен быть помечен @Serializable
 * из пакета kotlinx.serialization.
 * Ответ с полем nullableData == null - это ошибка с сервера.
 *
 * @param data безопасный getter для data. В случае nullableData == null
 * кидается ошибка.
 *
 * @param code код ошибки. Успешный - это Code.OK.
 *
 * @param message используется для получения информации об ошибке.
 */
@Serializable
data class Response<R>(
    @SerialName("data")
    private val nullableData: R? = null,
    private val code: Code = Code.OK,
    private val message: String = "success",
) {
    /**
     * Крайне оптимальное решение, которое включает в себя
     * onDefault, onSuccess и onFailure. В данном случае
     * onDefault == onSuccess, а onFailure бросает NPE, что
     * обрабатывается в интерфейсе, вызовом метода back()
     */
    val data
         get() = nullableData!!

    companion object {
        fun <T> bad(path: String) = Response<T>(
            code = Code.BAD_REQUEST,
            message = "Неизвестная ошибка сервера: <$path>",
            nullableData = null
        )
    }

    fun onSuccess(action: (R) -> Unit): Response<R> {
        if (code == Code.OK) {
            action(nullableData!!)
        }

        return this
    }

    fun onFailure(action: (code: Code, message: String) -> Unit): Response<R> {
        if (code != Code.OK) {
            action(code, message)
        }

        return this
    }

    fun onDefault(action: (data: R?, code: Code, message: String) -> Unit): Response<R> {
        action(nullableData, code, message)

        return this
    }

    @Suppress("UNCHECKED_CAST")
    @OptIn(ExperimentalEncodingApi::class)
    fun <T> byteArray() = Response(
        nullableData?.let { Base64.decode(it as String) } as T,
        code,
        message
    )
}


/**
 * Функция-парсер строки, формата JSON в Response<T>.
 *
 * Для ByteArray сделана отдельная ветка, т.к. он передается в формате
 * Base64. Base64 был выбран от Kotlin, такой же есть в Java, но
 * Koltin наверное лучше, но нужно навесить @OptInt аннотацию.
 */
@Throws(SerializationException::class, IllegalArgumentException::class)
inline fun <reified T> response(body: String): Response<T> {
//    return if (T::class == ByteArray::class) {
//        Json.decodeFromString<Response<String>>(body).byteArray()
//    } else {
//        Json.decodeFromString<Response<T>>(body)
//    }
    return Json.decodeFromString<Response<T>>(body)
}