package ru.novasoft.roads.compose_client.core.ui.chart.speedometer

import androidx.compose.foundation.layout.BoxScope
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import kotlin.math.roundToInt

object SpeedometerDefaults {

    const val MinSpeed = 0f
    const val MaxSpeed = 100f
    const val StartDegree = 135
    const val EndDegree = 405
    const val Unit = "Km/h"
    val UnitSpeedSpace: Dp = 2.dp
    const val UnitUnderSpeed: Boolean = false
    val BackgroundCircleColor: Color = Color.Transparent

    val Indicator: @Composable BoxScope.() -> Unit
        get() = { LineIndicator() }

    val CenterContent: @Composable BoxScope.() -> Unit
        get() = { }

    val Sections: MutableList<SpeedometerSection> = mutableListOf(
        SpeedometerSection(0f, .6f, Color(0xFF00FF00.toInt())),
        SpeedometerSection(.6f, .87f, Color(0xFFFFFF00.toInt())),
        SpeedometerSection(.87f, 1f, Color(0xFFFF0000.toInt())),
    )

    const val marksCount: Int = 0
    val marksColor: Color = Color.White
    val marksPadding: Dp = 0.dp
    val marksWidth: Dp = 3.dp
    val marksHeight: Dp = 9.dp
    val marksCap: StrokeCap = StrokeCap.Butt

    val Ticks: MutableList<Float> = mutableListOf(0f, 1f)
    val TickPadding: Dp = 40.dp
    const val TickRotate: Boolean = true
    val TickLabel: @Composable BoxScope.(index: Int, tickSpeed: Float) -> Unit
        get() = { _, tickSpeed ->
            Text(
                text = tickSpeed.roundToInt().toString(),
                style = MaterialTheme.typography.bodyLarge,
            )
        }

    @Composable
    fun SpeedStyle() = MaterialTheme.typography.headlineMedium

    @Composable
    fun SpeedometerText(speed: Float) =
        @Composable { Text(text = speed.roundToInt().toString(), style = SpeedStyle()) }

    @Composable
    fun UnitText(unit: String) = @Composable { Text(text = unit, style = SpeedStyle()) }
}