package src.novasoft.roads.compose_client.feature.menu.companies

import androidx.compose.foundation.HorizontalScrollbar
import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.rememberScrollbarAdapter
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Check
import androidx.compose.material.icons.filled.Close
import androidx.compose.material3.Checkbox
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.runtime.*
import ru.novasoft.roads.compose_client.core.ui.TestDTO
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import ru.novasoft.roads.compose_client.core.ui.menu.MenuPageTemplate
import ru.novasoft.roads.compose_client.core.ui.menu.PageHeaderView
import ru.novasoft.roads.compose_client.core.ui.table.TableFactory
import ru.novasoft.roads.compose_client.core.ui.table.TreeNode
import ru.novasoft.roads.compose_client.core.ui.table.TreeTableFactory
import ru.novasoft.roads.compose_client.core.ui.table.mappers.Filter
import ru.novasoft.roads.compose_client.core.ui.table.mappers.FilterType
import ru.novasoft.roads.compose_client.core.ui.table.mappers.TableColumn
import ru.novasoft.roads.compose_client.core.ui.table.utils.PaginationProperties

@Composable
fun CompaniesContractsView() {
    MenuPageTemplate(
        pageName = "Компании",
        pageHeader = { PageHeaderView(it) } ,
        pageContent = { CompaniesContentView() }
    )
}

@Composable
fun CompaniesContentView() {
    val rows = listOf(
        TestDTO("Kirill", "Shadrin", true),
        TestDTO("Tsumugi", "Kotobuki", false),
        TestDTO("Tomoko", "Kuroki", false),
        TestDTO("Tsumugi", "Kotobuki", false),
        TestDTO("Tsumugi", "Kotobuki", false),
        TestDTO("Tsumugi", "Kotobuki", false),
        TestDTO("Tsumugi", "Kotobuki", false),
        TestDTO("Tsumugi", "Kotobuki", false),
        TestDTO("Tsumugi", "Kotobuki", false),
    )
    val rootNode = TreeNode(
        content = "Root Node",
        children = listOf(
            TreeNode(
                content = "Child 1",
                children = listOf(
                    TreeNode(content = "Grandchild 1"),
                    TreeNode(content = "Grandchild 2")
                )
            ),
            TreeNode(
                content = "Child 2",
                children = listOf(
                    TreeNode(content = "Grandchild 3"),
                    TreeNode(content = "Grandchild 4")
                )
            )
        )
    )

    val columns: List<TableColumn<String>> = listOf(
        object : TableColumn<String> {
            override fun getCellComposable(): @Composable BoxScope.(dto: String) -> Unit {
                return { dto ->
                    Text(dto)
                }
            }

            override fun getColumnName(): String {
                return "Имя"
            }

            override fun getGroupName(): String? {
                return "ФИ"
            }
        },
        object : TableColumn<String> {
            override fun getCellComposable(): @Composable BoxScope.(dto: String) -> Unit {
                return { dto ->
                    Text(dto, modifier = Modifier.align(Alignment.Center))
                }
            }

            override fun getColumnName(): String {
                return "Фамилия"
            }

            override fun getGroupName(): String? {
                return "ФИ"
            }

            override fun getColumnContent(): (String) -> Any? {
                return {testDTO ->  testDTO}
            }

            override fun getFilterType(): FilterType {
                return FilterType.TEXT_FIELD
            }
        },
        object : TableColumn<String> {
            override fun getCellComposable(): @Composable BoxScope.(dto: String) -> Unit {
                return { dto ->
                    Text(dto)
                }
            }

            override fun getColumnName(): String {
                return "Возраст"
            }
        },
        object : TableColumn<String> {
            override fun getCellComposable(): @Composable BoxScope.(dto: String) -> Unit {
                return { dto ->
                    Text(dto)
                }
            }

            override fun getColumnName(): String {
                return "Реален/реальна"
            }
        }
    )


//    val columns: List<TableColumn<TestDTO>> = listOf(
//        object : TableColumn<TestDTO> {
//            override fun getCellComposable(): @Composable BoxScope.(dto: TestDTO) -> Unit {
//                return { dto ->
//                    Text(dto.name)
//                }
//            }
//
//            override fun getColumnName(): String {
//                return "Имя"
//            }
//
//            override fun getGroupName(): String {
//                return "ФИ"
//            }
//        },
//        object : TableColumn<TestDTO> {
//            override fun getCellComposable(): @Composable BoxScope.(dto: TestDTO) -> Unit {
//                return { dto ->
//                    Text(dto.surname, modifier = Modifier.align(Alignment.Center))
//                }
//            }
//
//            override fun getColumnName(): String {
//                return "Фамилия"
//            }
//
//            override fun getGroupName(): String {
//                return "ФИ"
//            }
//
//            override fun getColumnContent(): (TestDTO) -> Any? {
//                return {testDTO ->  testDTO.surname}
//            }
//
//            override fun getFilterType(): FilterType {
//                return FilterType.TEXT_FIELD
//            }
//        },
//        object : TableColumn<TestDTO> {
//            override fun getCellComposable(): @Composable BoxScope.(dto: TestDTO) -> Unit {
//                return { dto ->
//                    val iconColor = if (dto.isReal) Color.Green else Color.Red
//                    val icon = if (dto.isReal) Icons.Default.Check else Icons.Default.Close
//
//                    Icon(
//                        imageVector = icon,
//                        contentDescription = if (dto.isReal) "Real" else "Not real",
//                        tint = iconColor
//                    )
//                }
//            }
//
//            override fun getColumnName(): String {
//                return "Реален/реальна"
//            }
//
//            override fun getColumnContent(): (TestDTO) -> Any? {
//                return {testDTO ->  testDTO.isReal}
//            }
//
//            override fun getFilterType(): FilterType {
//                return FilterType.CHECKBOX
//            }
//        }
//    )
//
//    val filters = listOf(
//        object : Filter<TestDTO> {
//            private var text by remember { mutableStateOf("") }
//            override fun getFilterComposable(): @Composable () -> Unit {
//                return {
//                    TextField(
//                        value = text,
//                        onValueChange = { newText -> text = newText },
//                        label = { Text("Фильтр по имени") }
//                    )
//                }
//            }
//
//            override fun getFilter(): (item: TestDTO) -> Boolean {
//                return { item -> item.name.contains(text, ignoreCase = true) }
//            }
//
//            override fun getFilterName(): String {
//                return "По имени"
//            }
//        },
//        object : Filter<TestDTO> {
//            private var checked by remember { mutableStateOf(false) }
//            override fun getFilterComposable(): @Composable () -> Unit {
//                return {
//                    Checkbox(
//                        checked = checked,
//                        onCheckedChange = { checked = !checked }
//                    )
//                }
//            }
//
//            override fun getFilter(): (item: TestDTO) -> Boolean {
//                return { item -> item.isReal == checked }
//            }
//
//            override fun getFilterName(): String {
//                return "По существовании"
//            }
//        }
//    )
//    TableFactory(columns, rows, filters = filters, paginationProperties = PaginationProperties(isEnabled = true))
    TreeTableFactory(columns, mutableListOf(rootNode), paginationProperties = PaginationProperties(isEnabled = true))
}
