import androidx.compose.foundation.layout.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import ru.novasoft.roads.compose_client.core.ui.menu.MenuLogo

data class MenuItem(
    /** Получение содержимого (картинки) для отображения в меню (имеет смысл для основных элементов, но не для дочерних) */
    val imageContent: @Composable (MutableState<Boolean>, Modifier) -> Unit,
    /** Что поместить в конце строки меню в развернутом меню */
    val rightContent: (@Composable (MutableState<Boolean>) -> Unit)? = null,
    /** Текст подписи */
    val label: String,
    /** Можно ли выбрать этот элемент (следует делать true для страниц и false для управляющих элементов) */
    val selectable: Boolean,
    /** Обозначение пути, чтоб находить элемент и сопоставлять с Menu */
    val navigationPath: String?,
    /** В ситуации, когда выбран дочерний элемент, и нажимается на этот:
     * нужно ли удалить всех детей и обработать выбор этого элемента (если нет, то такое нажатие игнорируется) */
    val reopenOnClickIfChildSelected: Boolean = false,
    /** Дочерние элементы в данный момент (отображаются иерархией при развернутом меню и в выпадающем списке при свернутом меню) */
    val children: MutableList<MenuItem> = mutableListOf(),
    /** Действие при выборе элемента (выполняется после обновления структуры с иерархией дочерних элементов) */
    val action: () -> Unit,
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as MenuItem

        if (imageContent != other.imageContent) return false
        if (label != other.label) return false
        if (selectable != other.selectable) return false
        if (navigationPath != other.navigationPath) return false
        if (reopenOnClickIfChildSelected != other.reopenOnClickIfChildSelected) return false
        if (children != other.children) return false
        if (action != other.action) return false

        return true
    }

    override fun hashCode(): Int {
        var result = imageContent.hashCode()
        result = 31 * result + label.hashCode()
        result = 31 * result + selectable.hashCode()
        result = 31 * result + (navigationPath?.hashCode() ?: 0)
        result = 31 * result + reopenOnClickIfChildSelected.hashCode()
        result = 31 * result + children.hashCode()
        result = 31 * result + action.hashCode()
        return result
    }
}

/**
 * Составление меню
 * @param topItemsWithLevels Управляющие элементы с дочерними и уровнем (сначала 0, потом +1)
 * @param bottomItems Управляющие элементы, располагаемые снизу (без учета иерархии)
 * @param expanded Состояние меню: свернуто/развернуто
 * @param mapper Как на основе элемента и его уровня в иерархии (начиная с 0 и +1 далее) отрисовать его
 */
@Composable
fun MenuItemsList(
    topItemsWithLevels: List<Pair<MenuItem, Int>>,
    bottomItems: List<MenuItem>,
    expanded: MutableState<Boolean>,
    mapper: @Composable (MenuItem, Int) -> Unit
) {
    Column(
        modifier = Modifier
            .fillMaxSize(),
        verticalArrangement = Arrangement.SpaceBetween,
    ) {
        Column(
            modifier = Modifier.fillMaxWidth(),
            horizontalAlignment = Alignment.Start,
        ) {
            MenuLogo(expanded)
            Spacer(Modifier.height((MENU_ITEM_HEIGHT * 3).dp))
            topItemsWithLevels.forEach { (item, level) ->
                mapper(item, level)
            }
        }

        Column(
            modifier = Modifier.fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            bottomItems.forEach { item ->
                mapper(item, 0)
            }
        }
    }
}