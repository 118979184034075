package ru.novasoft.roads.compose_client.core.ui.widgets

import androidx.compose.animation.core.Spring
import androidx.compose.animation.core.spring
import androidx.compose.foundation.layout.*
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.times
import novasoft.roads.dto.meter_progress.PicketProgressCharDataDto
import novasoft.roads.dto.svor.SvorEntryDto
import org.kodein.di.compose.rememberDI
import org.kodein.di.instance
import ru.novasoft.roads.compose_client.core.designsystem.theme.doneBrightColor
import ru.novasoft.roads.compose_client.core.designsystem.theme.doneColor
import ru.novasoft.roads.compose_client.core.designsystem.theme.planBrightColor
import ru.novasoft.roads.compose_client.core.designsystem.theme.reportedBrightColor
import ru.novasoft.roads.compose_client.core.network.api.progress.IProgressApi
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.XAxisPadding
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.defaultPaddingCard
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Spacing.defaultSpaceBetweenCards
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.cardSizeBase
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.maxBarWidth
import ru.novasoft.roads.compose_client.core.ui.chart.PicketStackedBarChart
import ru.novasoft.roads.compose_client.core.ui.chart.models.BarProperties
import ru.novasoft.roads.compose_client.core.ui.chart.models.Bars
import ru.novasoft.roads.compose_client.core.ui.chart.models.HorizontalIndicatorProperties
import ru.novasoft.roads.compose_client.core.ui.effects.LoadingEffect
import kotlin.math.roundToLong

enum class PicketCardContentType {
    PROGRESS,
    WORTH;

    fun getCardName(): String = when (this) {
        PROGRESS -> "Прогресс на пикетах"
        WORTH -> "Стоимость на пикетах"
    }

    fun getYLabel(): String = when (this) {
        PROGRESS -> "%"
        WORTH -> "руб"
    }

    fun getCategoriesNames(): List<String> = when (this) {
        PROGRESS -> listOf("Сдано", "Факт", "План")
        WORTH -> listOf("Объем")
    }

    @Composable
    fun getCategoriesColors(): List<Brush> = when (this) {
        PROGRESS -> listOf(
            SolidColor(MaterialTheme.colorScheme.reportedBrightColor),
            SolidColor(MaterialTheme.colorScheme.doneBrightColor),
            SolidColor(MaterialTheme.colorScheme.planBrightColor)
        )

        WORTH -> listOf(SolidColor(MaterialTheme.colorScheme.doneColor))
    }
}

@ExperimentalMaterial3Api
@Composable
fun PicketCardContent(
    modifier: Modifier = Modifier,
    targetEntries: Map<Long, SvorEntryDto>?,
    picketData: PicketProgressCharDataDto?,
    type: PicketCardContentType,
    contractId: Int,
    isDataLoaded: Boolean
) {
    val categoryColors = type.getCategoriesColors()

    var picketProgress: List<Bars>? by remember { mutableStateOf(null) }

    ElevatedCard(
        modifier = Modifier
            .size(width = 4 * cardSizeBase + defaultSpaceBetweenCards, height = 300.dp),
        colors = CardDefaults.cardColors(containerColor = MaterialTheme.colorScheme.onPrimary),
        elevation = CardDefaults.elevatedCardElevation()
    ) {
        if (!isDataLoaded || picketProgress == null) {
            LoadingEffect()
            return@ElevatedCard
        }

        Column(
            modifier = modifier
                .fillMaxSize()
                .padding(defaultPaddingCard),
            verticalArrangement = Arrangement.spacedBy(5.dp, alignment = Alignment.Top)
        ) {
            Text(
                text = type.getCardName(),
                style = MaterialTheme.typography.headlineSmall,
                textAlign = TextAlign.Left,
            )
            Text(text = "", style = MaterialTheme.typography.bodySmall)

            PicketStackedBarChart(
                modifier = Modifier.fillMaxSize(),
                data = picketProgress!!,
                barProperties = BarProperties(
                    cornerRadius = Bars.Data.Radius.None,
                    thickness = maxBarWidth,
                    spacing = 2.dp
                ),
                indicatorProperties = HorizontalIndicatorProperties(
                    textStyle = MaterialTheme.typography.bodySmall,
                    padding = XAxisPadding,
                    count = 3,
                    contentBuilder = { it.roundToLong().toString() }
                ),
                animationSpec = spring(
                    dampingRatio = Spring.DampingRatioMediumBouncy,
                    stiffness = Spring.StiffnessLow
                ),
            )

        }
    }


    val client: IProgressApi by rememberDI { instance() }
    LaunchedEffect(targetEntries, picketData, type) {

        picketProgress = try {
            if (targetEntries == null)
                picketData?.getProgressChartData(type, categoryColors)
            else
                client.getPicketProgressForSvors(
                    contractId,
                    targetEntries.keys
                ).data.getProgressChartData(type, categoryColors)
        } catch (e: Exception) {
            return@LaunchedEffect
        }
    }
}


private fun PicketProgressCharDataDto.getProgressChartData(type: PicketCardContentType, categoryColors: List<Brush>): List<Bars> {
    return this.progressChartData.map { (picketNum, amounts) ->
        Bars(
            label = picketNum.toString(),
            values = amounts.mapIndexed { index, amount ->
                Bars.Data(
                    label = type.getCategoriesNames()[index],
                    value = amount.toDouble(),
                    color = categoryColors[index]
                )
            }
        )
    }
}