package ru.novasoft.roads.compose_client.core.ui.table.utils

import ru.novasoft.roads.compose_client.core.ui.table.mappers.TableColumn

/**
 * Enum-класс вида заголовка:
 * - **SINGLE**: Самостоятельный заголовок без родителя
 * - **GROUP**: Группа заголовков с одним родителем
 */
enum class HeaderType {
    SINGLE,
    GROUP
}

data class HeaderData(
    val type: HeaderType,
    val name: String? = null,
    val subs: MutableList<Int> = mutableListOf(),
    val id: Int = -1
) {
    init {
        when (type) {
            HeaderType.SINGLE -> {
                require(name == null) { "Name must not be provided for SINGLE type." }
                require(subs.isEmpty()) { "Subs must not be provided for SINGLE type." }
                require(id != -1) { "ID must be provided for SINGLE type" }
            }

            HeaderType.GROUP -> {
                requireNotNull(name) { "Name must be provided for GROUP type." }
                require(subs.isNotEmpty()) { "Subs must be provided for GROUP type." }
                require(id == -1) { "ID must not be provided for SINGLE type" }
                require(isSequential(subs.map { it })) { "IDs in subs must be in sequential order." }
            }
        }
    }
}

private fun isSequential(ids: List<Int>): Boolean {
    if (ids.isEmpty()) return true
    return ids.zipWithNext().all { (a, b) -> b == a + 1 }
}

fun <T> mapTableLabelsToTableData(tableColumn: List<TableColumn<T>>): List<HeaderData> {
    val tableDataList = mutableListOf<HeaderData>()
    val groupMap = mutableMapOf<String, HeaderData>()

    for (labelId in tableColumn.indices) {
        val column = tableColumn[labelId]
        val parent = column.getGroupName()
        if (parent == null) {
            val tableData = HeaderData(
                type = HeaderType.SINGLE,
                id = labelId
            )
            tableDataList.add(tableData)
        } else {
            val existingGroup = groupMap[parent]
            if (existingGroup != null) existingGroup.subs.add(labelId)
            else {
                val newGroup = HeaderData(
                    type = HeaderType.GROUP,
                    name = parent,
                    subs = mutableListOf(labelId)
                )
                tableDataList.add(newGroup)
                groupMap[parent] = newGroup
            }
        }
    }

    return tableDataList
}