package ru.novasoft.roads.compose_client.core.ui.table.components

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material.icons.filled.MoreVert
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.DpOffset
import androidx.compose.ui.unit.dp
import ru.novasoft.roads.compose_client.core.ui.table.utils.TableState
import ru.novasoft.roads.compose_client.core.ui.table.mappers.Filter
import ru.novasoft.roads.compose_client.core.ui.table.mappers.TableColumn

@Composable
fun <T> TableDropDownMenu(
    state: MutableState<TableState>,
    tableColumns: List<TableColumn<T>>,
    filters : List<Filter<T>>,
    paginationIsEnabled: Boolean
) {
    var expandedMenu by remember { mutableStateOf(false) }
    var selectColumnsSubMenuExpanded by remember { mutableStateOf(false) }
    var selectFiltersSubMenuExpanded by remember { mutableStateOf(false) }
    Box {
        IconButton(onClick = { expandedMenu = true }, modifier = Modifier.align(Alignment.CenterEnd)) {
            Icon(Icons.Default.MoreVert, contentDescription = "Show menu")
        }
        DropdownMenu(
            expanded = expandedMenu,
            onDismissRequest = { expandedMenu = false },
            modifier = Modifier.width(150.dp)
        ) {
            DropdownMenuItem(
                onClick = { selectColumnsSubMenuExpanded = !selectColumnsSubMenuExpanded },
                text = {
                    Text(
                        "Столбцы [${state.value.labelVisibility.count { it }}|${tableColumns.size}]",
                        style = MaterialTheme.typography.bodySmall
                    )
                },
                trailingIcon = {
                    Icon(
                        imageVector = Icons.Default.ArrowDropDown,
                        contentDescription = null
                    )
                },
            )
            if (selectColumnsSubMenuExpanded) {
                Box {
                    DropdownMenu(
                        expanded = selectColumnsSubMenuExpanded,
                        onDismissRequest = { selectColumnsSubMenuExpanded = false },
                        offset = DpOffset(170.dp, 0.dp)
                    ) {
                        DropdownMenuItem(onClick = {
                            val updatedVisibility = state.value.labelVisibility.toMutableList()
                            updatedVisibility.fill(state.value.labelVisibility.all { it }.not())
                            state.value = state.value.copy(labelVisibility = updatedVisibility)
                        },
                            text = {
                                Row(verticalAlignment = Alignment.CenterVertically) {
                                    Checkbox(
                                        checked = state.value.labelVisibility.all { it },
                                        onCheckedChange = null,
                                        modifier = Modifier,
                                        enabled = true,
                                        colors = CheckboxDefaults.colors(),
                                        interactionSource = null
                                    )
                                    Spacer(modifier = Modifier.width(2.dp))
                                    Text("Выбрать все", style = MaterialTheme.typography.bodySmall)
                                }
                            }
                        )
                        HorizontalDivider()
                        LazyColumn(modifier = Modifier.size(150.dp)) {
                            items(tableColumns.size) { labelID ->
                                val column = tableColumns[labelID]
                                DropdownMenuItem(onClick = {
                                    val updatedVisibility = state.value.labelVisibility.toMutableList()
                                    updatedVisibility[labelID] = !updatedVisibility[labelID]
                                    state.value = state.value.copy(labelVisibility = updatedVisibility)
                                }, text = {
                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                        Checkbox(
                                            checked = state.value.labelVisibility[labelID],
                                            onCheckedChange = null,
                                            modifier = Modifier,
                                            enabled = true,
                                            colors = CheckboxDefaults.colors(),
                                            interactionSource = null
                                        )
                                        Spacer(modifier = Modifier.width(2.dp))
                                        Text(
                                            column.getColumnName(),
                                            style = MaterialTheme.typography.bodySmall
                                        )
                                    }
                                })
                            }
                        }
                    }
                }
            }
            if (paginationIsEnabled) {
                DropdownMenuItem(
                    onClick = {
                        state.value = state.value.copy(showPages = !state.value.showPages)
                        expandedMenu = false
                    },
                    text = {
                        if (!state.value.showPages) {
                            Text("Показать страницы")
                        } else {
                            Text("Убрать страницы")
                        }
                    }
                )
            }
            DropdownMenuItem(
                onClick = {
                    selectFiltersSubMenuExpanded = !selectFiltersSubMenuExpanded
                },
                text = {
                    Text(
                        "Фильтры",
                        style = MaterialTheme.typography.bodySmall
                    )
                },
                trailingIcon = {
                    Icon(
                        imageVector = Icons.Default.ArrowDropDown,
                        contentDescription = null
                    )
                }
            )
            if(selectFiltersSubMenuExpanded){
                Box{
                    DropdownMenu(
                        expanded = selectFiltersSubMenuExpanded,
                        onDismissRequest = { selectFiltersSubMenuExpanded = false },
                        offset = DpOffset(170.dp, 0.dp)
                    ) {
                        DropdownMenuItem(onClick = {
                            val updatedVisibility = state.value.filterVisibility.toMutableList()
                            if (state.value.filterVisibility.all { it }) {
                                updatedVisibility.fill(false)
                            } else {
                                updatedVisibility.fill(true)
                            }
                            state.value = state.value.copy(filterVisibility = updatedVisibility)
                        },
                            text = {
                                Row(verticalAlignment = Alignment.CenterVertically) {
                                    Checkbox(
                                        checked = state.value.filterVisibility.all { it },
                                        onCheckedChange = null,
                                        modifier = Modifier,
                                        enabled = true,
                                        colors = CheckboxDefaults.colors(),
                                        interactionSource = null
                                    )
                                    Spacer(modifier = Modifier.width(2.dp))
                                    Text("Выбрать все", style = MaterialTheme.typography.bodySmall)
                                }
                            }
                        )
                        LazyColumn(modifier = Modifier.size(150.dp)) {
                            items(filters.size) { filterID ->
                                val column = filters[filterID]
                                DropdownMenuItem(onClick = {
                                    val updatedVisibility = state.value.filterVisibility.toMutableList()
                                    updatedVisibility[filterID] = !updatedVisibility[filterID]
                                    state.value = state.value.copy(filterVisibility = updatedVisibility)
                                }, text = {
                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                        Checkbox(
                                            checked = state.value.filterVisibility[filterID],
                                            onCheckedChange = null,
                                            modifier = Modifier,
                                            enabled = true,
                                            colors = CheckboxDefaults.colors(),
                                            interactionSource = null
                                        )
                                        Spacer(modifier = Modifier.width(2.dp))
                                        Text(
                                            column.getFilterName(),
                                            style = MaterialTheme.typography.bodySmall
                                        )
                                    }
                                })
                            }
                        }
                    }
                }
            }
        }
    }
}