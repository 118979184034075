package ru.novasoft.roads.compose_client.core.data.remote.impl

import kotlinx.coroutines.flow.MutableStateFlow
import novasoft.roads.dto.entities_bundle.MeasuringUnitDto
import ru.novasoft.roads.compose_client.core.data.remote.IMeasuringUnitsHandler
import ru.novasoft.roads.compose_client.core.network.api.folders.IFoldersApi


class MeasuringUnitsHandler(private val folderApi: IFoldersApi) : IMeasuringUnitsHandler {
    private val _units = MutableStateFlow<Map<Int, MeasuringUnitDto>>(emptyMap())

    override suspend fun update() {
        val fetchedUnits = folderApi.units().await().associateBy { it.id }
        _units.emit(fetchedUnits)
    }

    override fun getUnitById(id: Int): MeasuringUnitDto? {
        return _units.value[id]
    }

    override suspend fun get(): Collection<MeasuringUnitDto> {
        if (_units.value.isEmpty()) {
            update()
        }
        return _units.value.values
    }
}