package src.novasoft.roads.compose_client.feature.menu.help

import androidx.compose.foundation.*
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.unit.dp
import org.kodein.di.compose.localDI
import org.kodein.di.compose.rememberDI
import org.kodein.di.compose.subDI
import org.kodein.di.instance
import ru.novasoft.roads.compose_client.core.designsystem.theme.hyperlinkColor
import ru.novasoft.roads.compose_client.core.ui.menu.MenuPageTemplate
import ru.novasoft.roads.compose_client.core.ui.menu.PageHeaderView
import ru.novasoft.roads.compose_client.core.ui.widgets.FileSaveWidget
import src.novasoft.roads.compose_client.feature.menu.help.treeView.TreeView
import src.novasoft.roads.compose_client.feature.menu.help.utils.FormatterColors
import src.novasoft.roads.compose_client.feature.menu.help.utils.TextFormatter

@Composable
fun HelpView() {
    subDI(
        parentDI = localDI(),
        diBuilder = { import(helpDIModule()) }
    ) {
        val helpViewModel: HelpViewModel by rememberDI { instance() }
        MenuPageTemplate(
            pageName = "Помощь",
            pageHeader = { PageHeaderView(it) },
            pageContent = { helpContentView(helpViewModel) }
        )
    }
}

@Composable
private fun helpContentView(helpViewModel: HelpViewModel) {
    val horizontalScroll = rememberScrollState(0)
    val verticalScroll = rememberScrollState(0)
    val linkColor = MaterialTheme.colorScheme.hyperlinkColor
    val importantColor = MaterialTheme.typography.headlineSmall.color
    val colors = object : FormatterColors {
        override fun getHyperLinkColor() = linkColor
        override fun getImportantColor() = importantColor
    }
    val formatter = TextFormatter(colors, helpViewModel.state.helpTexts) {
        tag -> helpViewModel.onEvent(HelpUiEvent.LinkClicked(tag))
    }
    formatter.format(helpViewModel.state.mainText)
    val formattedString = formatter.mainText
    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(50.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(8.dp)
    ) {
        Row(modifier = Modifier.fillMaxSize(), horizontalArrangement = Arrangement.SpaceBetween) {
            Surface(
                modifier = Modifier
                    .weight(1f)
                    .clip(RoundedCornerShape(16.dp))
                    .background(MaterialTheme.colorScheme.background)
            ) {
                TreeView(helpViewModel.state.helpTexts, selectedNodeId = helpViewModel.state.selectedNodeId) { nodeName, nodeId ->
                    helpViewModel.onEvent(
                        HelpUiEvent.NodeClicked(nodeName, nodeId)
                    )
                }
            }
            Spacer(modifier = Modifier.width(100.dp))
            Box(modifier = Modifier.fillMaxSize().weight(2f)) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Spacer(modifier = Modifier.height(8.dp))
                    Text(
                        helpViewModel.state.path,
                        modifier = Modifier.align(Alignment.Start)
                    )
                    Spacer(modifier = Modifier.height(56.dp))
                    Text(
                        helpViewModel.state.pageTitle,
                        modifier = Modifier.align(Alignment.Start),
                        style = MaterialTheme.typography.headlineSmall
                    )
                    Box(modifier = Modifier
                        .fillMaxSize()
                        .weight(5f)) {
                        Text(
                            text = formattedString,
                            modifier = Modifier
                                .horizontalScroll(horizontalScroll)
                                .verticalScroll(verticalScroll)
                                .padding(16.dp)
                        )
                        HorizontalScrollbar(
                            adapter = rememberScrollbarAdapter(horizontalScroll),
                            modifier = Modifier.align(Alignment.BottomStart)
                        )
                        VerticalScrollbar(
                            adapter = rememberScrollbarAdapter(verticalScroll),
                            modifier = Modifier.align(Alignment.CenterEnd)
                        )
                    }
                    Spacer(modifier = Modifier.weight(1f))
                }
                Row(
                    modifier = Modifier
                        .align(Alignment.BottomCenter)
                        .fillMaxWidth()
                        .padding(top = 32.dp)
                ) {
                    Button(
                        modifier = Modifier.weight(1f).height(48.dp),
                        onClick = {
                            helpViewModel.onEvent(HelpUiEvent.ArrowClicked(ArrowType.LEFT))
                        },
                        enabled = helpViewModel.state.leftEnabled,
                        colors = ButtonDefaults.buttonColors(
                            containerColor = MaterialTheme.colorScheme.background,
                            contentColor = MaterialTheme.colorScheme.onSurface,
                            disabledContainerColor = MaterialTheme.colorScheme.surfaceVariant,
                            disabledContentColor = MaterialTheme.colorScheme.onPrimary
                        )
                    ) {
                        Text("<")
                    }
                    Spacer(modifier = Modifier.weight(10f)) // Space between buttons
                    Button(
                        modifier = Modifier.weight(1f).height(48.dp),
                        onClick = {
                            helpViewModel.onEvent(HelpUiEvent.ArrowClicked(ArrowType.RIGHT))
                        },
                        enabled = helpViewModel.state.rightEnabled,
                        colors = ButtonDefaults.buttonColors(
                            containerColor = MaterialTheme.colorScheme.background,
                            contentColor = MaterialTheme.colorScheme.onSurface,
                            disabledContainerColor = MaterialTheme.colorScheme.surfaceVariant,
                            disabledContentColor = MaterialTheme.colorScheme.onPrimary
                        )
                    ) {
                        Text(">")
                    }
                }
            }
        }
    }
}