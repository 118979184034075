package ru.novasoft.roads.compose_client.core.ui.table.utils

import androidx.compose.runtime.Immutable
import androidx.compose.ui.unit.Dp

@Immutable
data class TableState(
    val labelVisibility: List<Boolean> = listOf(),
    val expandedStateMap: Map<String, Boolean> = mapOf(),
    val showCheck: Boolean = false,
    val showPages: Boolean = false,
    val pageNumberState: Int = 1,
    val offsetState: Int = 0,
    val showFilter: Boolean = false,
    val filterVisibility: List<Boolean> = listOf(),
    val labelWidths: List<Dp> = listOf()
)