package ru.novasoft.roads.compose_client.core.ui.chart.components

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.text.BasicText
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import ru.novasoft.roads.compose_client.core.ui.chart.models.Bars

enum class LabelShape {
    CIRCLE, SQUARE;

    internal fun getShape() = when (this) {
        CIRCLE -> CircleShape
        SQUARE -> RectangleShape
    }
}

@Composable
fun LabelHelper(
    data: List<Triple<String, Brush, LabelShape>>,
    textStyle: TextStyle = TextStyle.Default.copy(fontSize = 13.sp)
) {
    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(15.dp)
        ) {
            data.forEach { (label, color, shape) ->
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.spacedBy(8.dp)
                ) {
                    Box(
                        modifier = Modifier
                            .size(10.dp)
                            .clip(shape.getShape())
                            .background(color)
                    )
                    BasicText(text = label, style = textStyle)
                }
            }
        }
    }
}

@Composable
fun RCChartLabelHelper(
    data: List<Bars>,
    textStyle: TextStyle = TextStyle.Default.copy(fontSize = 13.sp)
) {
    val labels = data.map { it.values.map { it.label } }.flatten().toSet().toList()
    val colors = labels.map { label -> data.map { it.values.find { it.label == label }?.color }.firstOrNull() }
    LabelHelper(
        data = labels.mapIndexed { index, label ->
            Triple(label.orEmpty(), (colors[index] ?: SolidColor(Color.Unspecified)), LabelShape.SQUARE)
        },
        textStyle = textStyle
    )
}

@Composable
fun RCCPicketLabelHelper(
    data: List<Bars>,
    textStyle: TextStyle = TextStyle.Default.copy(fontSize = 13.sp),
    modifier: Modifier = Modifier.fillMaxWidth()
) {
    val labels = data.map { it.values.map { it.label } }.flatten().toSet().toList()
    val colors = labels.map { label -> data.map { it.values.find { it.label == label }?.color }.firstOrNull() }
    Box(
        modifier = modifier,
        contentAlignment = Alignment.Center
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(15.dp)
        ) {
            labels.mapIndexed { i, l ->
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.spacedBy(8.dp)
                ) {
                    Box(
                        modifier = Modifier
                            .size(10.dp)
                            .background(colors[i] ?: SolidColor(Color.Unspecified))
                    )
                    BasicText(text = l.orEmpty(), style = textStyle)
                }
            }
        }
    }
}
